import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCheck, faTimes, faUnlock } from '@fortawesome/free-solid-svg-icons'

import { Portal } from '@/components'
import { ModalFormProps } from '@/components/Modal/types'
import { useAppContext } from '@/hooks'
import { splitProps } from '@/utils/collections'

import Button from '../Button/Button'
import { Form, Submit } from '../UberForm'
import { Modal } from './Modal'

export const ModalForm = <T extends object>(props: ModalFormProps<T>) => {
	const { t } = useAppContext()

	const {
		saveTitle,
		saveIcon,
		saveSchema,
		cancelTitle,
		cancelIcon,
		cancelSchema,
		children,
		footer,
		footerBefore,
		hasSubmitButton = true,
		hasLockButton,
		hasPushButton,
		onLock,
		onUnlock,
		template,
		saveHandler,
	} = props

	let iconSave: IconProp = faCheck

	if (saveIcon) {
		iconSave = saveIcon
	}

	let iconCancel: IconProp = faTimes

	if (cancelIcon) {
		iconCancel = cancelIcon
	}

	const formProps = { ...props }

	const modalProps = splitProps(formProps, [
		'children',
		'contentStyle',
		'bodyStyle',
		'header',
		'footer',
		'open',
		'onClose',
		'disablePortal',
		'headerStyle',
		'resizable',
		'maximizeButtonVisible',
		'maximized',
		'height',
	])

	const isTemplateLocked = template?.lockedBy

	return (
		<Portal>
			<Form<T> ref={props.formRef} {...formProps}>
				<Modal
					{...modalProps}
					disablePortal
					footer={(close) =>
						footer || (
							<>
								{footerBefore}
								<div className="flex w-full justify-between">
									<div>
										{hasLockButton && (
											<Button
												onClick={isTemplateLocked ? onUnlock : onLock}
												icon={faUnlock}
												schema="warn"
											>
												{isTemplateLocked ? t('UNLOCK') : t('LOCK')}
											</Button>
										)}
									</div>
									<div className="flex">
										{hasPushButton && (
											<Button
												className="mr-2"
												schema={saveSchema || 'success'}
												icon={iconSave}
												onClick={saveHandler}
											>
												{saveTitle || t('PUSH')}
											</Button>
										)}
										{hasSubmitButton && (
											<Submit schema={saveSchema || 'primary'} icon={iconSave}>
												{saveTitle || t('SAVE')}
											</Submit>
										)}

										<Button
											onClick={close}
											schema={cancelSchema || 'transparent'}
											icon={iconCancel}
										>
											{cancelTitle || t('CANCEL')}
										</Button>
									</div>
								</div>
							</>
						)
					}
				>
					{children}
				</Modal>
			</Form>
		</Portal>
	)
}
