import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Loader } from '@/components'
import { StructureObjectDto } from '@/endpoints/models'
import { TableMode } from '@/enums'
import { useAppDispatch, useAppStore } from '@/hooks'
import { findSystemNodeId } from '@/store/modules/node/helpers'

import { useBatchEditContext } from './context/BatchEditContext'
import { ActionTypeEnum } from './context/types'
import { useTypeData } from './hooks/useTypeData'
import { getGroupedData } from './utils'

export const BatchEditSwitch = () => {
	const dispatch = useAppDispatch()

	const { nodes } = useAppStore((state) => state.node)
	const [loadedForms, setLoadedForms] = useState(false)

	const {
		state: { selectedStructures, formChanged, selectedNodes, type },
		dispatch: dispatchContext,
	} = useBatchEditContext()

	const { formData, initData, FormComponent } = useTypeData(type)

	const systemNodeId = findSystemNodeId(
		nodes[selectedNodes[0]] as StructureObjectDto,
		nodes,
	)

	const loadForms = useCallback(async () => {
		const loadingForms = selectedNodes.map((selectedNode) =>
			dispatch(initData({ nodeId: selectedNode, editMode: true })),
		)

		await Promise.allSettled(loadingForms)
		setLoadedForms(true)
	}, [dispatch, selectedNodes, initData])

	useEffect(() => {
		loadForms()
	}, [dispatch, loadForms])

	const onChange = useCallback(
		(values: any) => {
			dispatchContext({
				type: ActionTypeEnum.FORM_CHANGED_UPDATE,
				formChanged: values,
			})
		},
		[dispatchContext],
	)

	const data = useMemo<any | null>(() => {
		if (!loadedForms) {
			return null
		}

		const forms = selectedNodes.map((selectedNode) => ({
			...(formData[selectedNode]?.form ?? {}),
		}))

		const originals = selectedNodes.map((selectedNode) => ({
			...(formData[selectedNode]?.original ?? {}),
		}))

		const form = getGroupedData(forms)
		const original = getGroupedData(originals)

		return {
			form: {
				...form,
				...formChanged,
			},
			original,
			dirty: false,
			tab: 'Overview' as any,
			parsedEditMode: true,
		}
	}, [formData, loadedForms, selectedNodes, formChanged])

	const props = useMemo(() => {
		if (!data) {
			return null
		}

		return {
			data,
			systemNodeId,
			onChange,
			readOnly: false,
			selectedNodes: selectedStructures,
			editMode: true,
			/** Table specific props */
			unsyncedFields: [],
			onSync: () => null,
			mode: TableMode.TABLE,
			modeForm: data.form,
			modeFormTable: data.form,
			/** Mapping specific props */
			defaultMapping: type === StructureObjectDto.TypeEnum.MAPPING_DEFAULT,
		}
	}, [data, systemNodeId, onChange, selectedStructures, type])

	return (
		<>
			{props ? (
				<>
					<FormComponent {...props} />
				</>
			) : (
				<Loader loaded={false} />
			)}
		</>
	)
}
