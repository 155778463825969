import React, {
	forwardRef,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import ReactAce, { IAceEditorProps } from 'react-ace'
import { Ace } from 'ace-builds'
import './AceEditorConfig'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/mode-sql'
import 'ace-builds/src-noconflict/theme-sqlserver'
import 'ace-builds/src-noconflict/ext-searchbox'
import langTools, {
	snippetCompleter,
	keyWordCompleter,
} from 'ace-builds/src-noconflict/ext-language_tools'
import { FormFieldContext, withFormField } from '../UberForm/FormFieldContext'
import { useAppContext, useAppStore, useDebounce } from '@/hooks'
import { AutocompleteConfig, Search } from './types'
import { useAutocomplete } from './hooks/useAutocomplete'
import { isTokenColumn } from './utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faWindowMaximize } from '@fortawesome/free-solid-svg-icons'
import { mainColors } from '@/styles'
import { Modal } from '../Modal/Modal'
import AutoSizer from 'react-virtualized-auto-sizer'
import Button from '../Button/Button'
import { Maximizer, Wrapper } from './styles'

const ROW_HEIGHT = 14

export type AceEditorProps = Omit<IAceEditorProps, 'height'> &
	FormFieldContext & {
		autoFocus?: boolean
		autocompleteConfig?: AutocompleteConfig
		height?: number | string
		isInModal?: boolean
	}

export const AceEditorWithoutForm = forwardRef(
	(props: AceEditorProps, refForwarded) => {
		const {
			name,
			value,
			onChange,
			disabled,
			height,
			mode,
			autoFocus,
			onBlur,
			isInModal,
			autocompleteConfig,
			onKeyPress,
			...propsRest
		} = props

		const { t } = useAppContext()
		const refLocal = useRef<ReactAce | null>(null)

		const ref = useMemo(
			() =>
				(refForwarded ?? refLocal) as React.MutableRefObject<ReactAce | null>,
			[refForwarded],
		)

		const { autocompleteKeywords, autocompleteSnippets, autocompleteElements } =
			useAppStore((state) => state.settings.userInterface.scriptEditor)

		const [maximized, setMaximized] = useState(false)
		const [heightEditor, setHeightEditor] = useState(height)
		const [search, setSearch] = useState<Search | undefined>(undefined)
		const searchDebounced = useDebounce(search, 150)

		const { tables, columns } = useAutocomplete(
			searchDebounced,
			autocompleteConfig,
		)

		useEffect(() => {
			setHeightEditor(height)
		}, [height])

		useEffect(() => {
			if (autoFocus) {
				ref.current?.editor.focus()
			}
		}, [autoFocus, ref])

		useEffect(() => {
			const autocompleters = [
				autocompleteElements
					? {
							getCompletions: (
								editor: Ace.Editor,
								session: Ace.EditSession,
								pos: Ace.Point,
								prefix: string,
								callback: Ace.CompleterCallback,
							): void => {
								callback(null, [
									...columns.map(
										(column) =>
											({
												caption: `${column.code} (${column.name})`,
												value: column.code,
												score: Number.MAX_VALUE,
												meta: t('COLUMN'),
											}) as Ace.Completion,
									),
									...(search?.column === undefined
										? tables.map(
												(table) =>
													({
														caption: `${table.code} (${table.name})`,
														value: table.code,
														score: Number.MAX_VALUE,
														meta: t('TABLE'),
													}) as Ace.Completion,
											)
										: []),
								])
							},
						}
					: undefined,
				autocompleteSnippets ? snippetCompleter : undefined,
				autocompleteKeywords ? keyWordCompleter : undefined,
			].filter((autocompleter) => autocompleter !== undefined)

			langTools.setCompleters(autocompleters)

			const editor = ref.current?.editor

			if (editor) {
				editor.completer?.showPopup?.(editor)
			}
		}, [
			autocompleteElements,
			autocompleteKeywords,
			autocompleteSnippets,
			columns,
			ref,
			search,
			t,
			tables,
		])

		useEffect(() => {
			const editor = ref.current?.editor

			if (height || !editor) {
				return
			}

			const rows = editor.session.getLength()

			if (!value || rows <= 3) {
				setHeightEditor(ROW_HEIGHT * 3)

				return
			}

			setHeightEditor(Math.min(ROW_HEIGHT * 20, ROW_HEIGHT * rows))
		}, [value, height, ref])

		const onChangeHandler = useCallback(
			(value: string) => {
				const editor = ref.current?.editor
				onChange?.(value)

				if (!editor) {
					return
				}

				try {
					const { row, column } = editor.getCursorPosition()

					const tokens = editor.session.getTokens(row)
					const token = editor.session.getTokenAt(row, column + 1)

					let tokenIndex = tokens.findIndex(
						(tokenItem) =>
							tokenItem.value === token?.value &&
							tokenItem.start === token.start &&
							tokenItem.index === token.index,
					)
					tokenIndex = tokenIndex === -1 ? tokens.length - 1 : tokenIndex

					const tokenPrevious = tokens[tokenIndex - 1]
					const tokenTable = tokens[tokenIndex - 2]
					const previousValue = tokenPrevious?.value ?? ''

					if (isTokenColumn(token?.value)) {
						setSearch({ table: previousValue, column: '' })
					} else if (isTokenColumn(previousValue)) {
						setSearch({ table: tokenTable.value, column: token?.value })
					} else {
						setSearch({ table: token?.value, column: undefined })
					}
				} catch (e) {
					console.error(e)
				}
			},
			[onChange, ref],
		)

		const onMaximizeToggle = useCallback(() => {
			setMaximized((prev) => !prev)
		}, [])

		const onBlurHandler = useCallback(
			(event: any, editor?: Ace.Editor) => {
				if (maximized) {
					return
				}

				onBlur?.(event, editor)
			},
			[maximized, onBlur],
		)

		return (
			<>
				{maximized ? (
					<Modal
						resizable
						onClose={onMaximizeToggle}
						maximized
						open
						header={<></>}
						footer={
							<Button
								onClick={onMaximizeToggle}
								schema="transparent"
								icon={faTimes}
								style={{ marginLeft: 'auto' }}
							>
								{t('MINIMIZE')}
							</Button>
						}
					>
						<AutoSizer>
							{({ height, width }: { height: number; width: number }) => (
								<AceEditorWithoutForm
									ref={ref}
									{...props}
									height={height}
									width={`${width}px`}
									onBlur={onBlurHandler}
									autoFocus
									isInModal
								/>
							)}
						</AutoSizer>
					</Modal>
				) : (
					// handle keypress here as ReactAce does not support it
					<Wrapper disabled={disabled} onKeyPress={onKeyPress}>
						{!isInModal && (
							<Maximizer onMouseDown={onMaximizeToggle} title={t('MAXIMIZE')}>
								<FontAwesomeIcon
									icon={faWindowMaximize}
									color={mainColors.primary}
								/>
							</Maximizer>
						)}
						<ReactAce
							ref={(ace) => (ref.current = ace)}
							mode={mode ?? 'sql'}
							theme={'sqlserver'}
							onChange={onChangeHandler}
							name={name}
							editorProps={{
								$blockScrolling: Infinity,
							}}
							setOptions={{
								enableBasicAutocompletion: true,
								enableLiveAutocompletion: true,
								enableSnippets: true,
								printMargin: false,
								readOnly: disabled,
							}}
							value={value ?? ''}
							onLoad={(editorInstance) => {
								editorInstance.container.style.resize = 'both'
								editorInstance.container.style.border = '1px solid #ccc'

								window.addEventListener('mouseup', () =>
									editorInstance.resize(),
								)
							}}
							width="100%"
							height={
								heightEditor
									? typeof heightEditor === 'number'
										? `${heightEditor}px`
										: heightEditor
									: '60px'
							}
							readOnly={disabled}
							onBlur={onBlurHandler}
							fontSize="12px"
							{...propsRest}
						/>
					</Wrapper>
				)}
			</>
		)
	},
)

export const AceEditor = withFormField(AceEditorWithoutForm)
