import { StructureDto, UserDto } from '@/endpoints/models'

export const isLocked = (node: StructureDto) => !!node.lockUserId

export const isLockedByMe = (node: StructureDto, user: UserDto | null) =>
	!!user && user.id === node.lockUserId

export const isLockedByOther = (node: StructureDto, user: UserDto | null) =>
	isLocked(node) && !isLockedByMe(node, user)

export const isEditable = (node: StructureDto, user: UserDto | null) =>
	node.canEdit && (!node.lockUserId || isLockedByMe(node, user))
