import { useMemo, useState } from 'react'

import { FormWrap } from '@/components'
import { AceEditorField } from '@/components/AceEditor/AceEditorField'
import {
	SelectFormField,
	TextAreaFormField,
	TextFormField,
} from '@/components/UberForm'
import validators from '@/components/UberForm/Validators'
import { GeneratedSqlDto, StereotypeDto } from '@/endpoints/models'
import { useAppContext } from '@/hooks'
import { HeaderContainer } from '@/pages/User/pages/Home/pages/SystemDetail/pages/DeploymentPackages/styles'
import { TemplateFormProps } from '@/pages/User/pages/Settings/pages/Templates/components/types'
import {
	ELEMENTS_WITH_OBJECT_TYPE,
	noSpacesRegexPattern,
} from '@/pages/User/pages/Settings/pages/Templates/components/utils'
import {
	ObjectSettingsStructure,
	useGetObjectSettingsQuery,
	useGetStereotypesForTemplatesQuery,
	useGetStereoTypesQuery,
	useGetTechnologiesQuery,
} from '@/rtkQueries'

export const TemplateForm = ({
	formData,
	template,
	textAreaHeight,
}: TemplateFormProps) => {
	const { t } = useAppContext()
	const { data: stereoTypes, isLoading: isStereoTypeLoading } =
		useGetStereoTypesQuery()

	const [selectedElement, setSelectedElement] = useState(
		template?.structureType as StereotypeDto.TypeEnum,
	)

	const {
		data: stereoTypesForTemplates,
		isLoading: isStereoTypesForTemplatesLoading,
	} = useGetStereotypesForTemplatesQuery({ type: selectedElement })

	const { data: objectSettings, isLoading: isObjectSettingsLoading } =
		useGetObjectSettingsQuery(selectedElement as ObjectSettingsStructure)

	const { data: technologies, isLoading: isTechnologiesLoading } =
		useGetTechnologiesQuery()

	const formatTypes = useMemo(
		() => [
			{ name: t('PYTHON'), code: GeneratedSqlDto.FormatTypeEnum.PYTHON },
			{ name: t('SQL'), code: GeneratedSqlDto.FormatTypeEnum.SQL },
			{
				name: t('PLAIN_TEXT'),
				code: GeneratedSqlDto.FormatTypeEnum.PLAIN_TEXT,
			},
		],
		[t],
	)

	return (
		<FormWrap>
			<section className="py-4">
				<HeaderContainer>
					<h1>Fields</h1>
				</HeaderContainer>
				<div className="grid grid-cols-2 gap-4">
					<SelectFormField
						title={t('TECHNOLOGY')}
						name="technologyId"
						options={technologies || []}
						fieldIsLoading={isTechnologiesLoading}
						valueKey="id"
						labelKey="name"
						required
						isNumeric
					/>
					<SelectFormField
						title={t('TEMPLATE_ELEMENT')}
						name="structureType"
						options={stereoTypes}
						fieldIsLoading={isStereoTypeLoading}
						valueKey="code"
						labelKey="code"
						isNumeric
						clearable
						allowEmpty
						onChange={(value) => {
							setSelectedElement(value as StereotypeDto.TypeEnum)
						}}
					/>
					<SelectFormField
						title={t('STEREOTYPE')}
						name="stereotypeId"
						options={stereoTypesForTemplates}
						fieldIsLoading={isStereoTypesForTemplatesLoading}
						valueKey="id"
						labelKey="name"
						isNumeric
						clearable
						allowEmpty
						disabled={!selectedElement || !stereoTypes?.length}
						required
					/>
					<SelectFormField
						title={t('OBJECT_TYPE')}
						name="objectSettingsId"
						options={objectSettings || []}
						fieldIsLoading={isObjectSettingsLoading}
						valueKey="id"
						labelKey="name"
						isNumeric
						clearable
						required={
							selectedElement &&
							ELEMENTS_WITH_OBJECT_TYPE.includes(selectedElement)
						}
						allowEmpty
						disabled={
							!selectedElement ||
							!ELEMENTS_WITH_OBJECT_TYPE.includes(selectedElement)
						}
					/>
					<TextFormField
						title={t('FILE_EXTENSION')}
						name="fileExtension"
						validators={[
							validators.maxLength(10),
							validators.customRegex(noSpacesRegexPattern),
						]}
					/>

					<SelectFormField
						title={t('OUTPUT_FORMAT')}
						name="formatType"
						options={formatTypes}
						valueKey="code"
						labelKey="name"
						clearable
						allowEmpty
					/>
				</div>
			</section>
			<section>
				<TextAreaFormField
					textAreaHeight={textAreaHeight}
					title={t('TEMPLATE_COMMENT')}
					name="comment"
				/>

				<TextAreaFormField
					textAreaHeight="80px"
					title={t('TEMPLATE_DESCRIPTION')}
					name="description"
				/>
			</section>

			<section className="py-4">
				<HeaderContainer>
					<h1>Script</h1>
				</HeaderContainer>
				<AceEditorField
					name="data"
					title={t('TEMPLATE_DATA') + ' *'}
					initialValue={formData?.data}
					required
					height={100}
				/>
			</section>
		</FormWrap>
	)
}
