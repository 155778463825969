import React, { Suspense } from 'react'

import { Loader } from '@/components'
import { StructureDto } from '@/endpoints/models'
import { IdCodeName } from '@/endpoints/schemas/general'
import { useAppContext, useAppDispatch } from '@/hooks'
import { VersionsTab } from '@/pages/User/pages/Home/components/VersionsTab/VersionsTab'
import { selectWorkflowTab } from '@/store/modules/workflow/actions'
import {
	OpenedWorkflowData,
	WorkflowDataForm,
	WorkflowTab,
} from '@/store/modules/workflow/types'
import { UpdateDeepPartial } from '@/store/utils'
import { GrowTabs } from '@/styles'

import {
	Dependencies,
	Deployments,
	Preview,
	Validation,
} from '../../../components'
import { Overview } from '../pages/Overview/Overview'

const Graph = React.lazy(
	() => import(/* webpackChunkName: "workflow" */ '../pages/Graph/Graph'),
)

type WorkflowTabsComponentProps = {
	data: OpenedWorkflowData
	editMode: boolean
	node: StructureDto
	objectTypes?: IdCodeName[]
	onChange: (v: UpdateDeepPartial<WorkflowDataForm>) => void
	systemNodeId: number
}

const WorkflowTabsComponent = ({
	node,
	data,
	editMode,
	objectTypes,
	onChange,
	systemNodeId,
}: WorkflowTabsComponentProps) => {
	const { t } = useAppContext()

	const dispatch = useAppDispatch()

	return (
		<GrowTabs
			onChange={(tab: any) =>
				dispatch(selectWorkflowTab(node, tab.id as WorkflowTab))
			}
			selectedTabId={data.tab}
			tabs={[
				{
					id: WorkflowTab.Overview,
					title: t('TAB_OVERVIEW'),
					content: (
						<Overview
							node={node}
							key={WorkflowTab.Overview}
							data={data}
							editMode={editMode}
							onChange={onChange}
							systemNodeId={systemNodeId}
						/>
					),
				},
				{
					id: WorkflowTab.Graph,
					title: t('TAB_GRAPH'),
					content: (
						<Suspense
							fallback={<Loader loaded={false} key={WorkflowTab.Overview} />}
						>
							<Graph
								node={node}
								data={data}
								onChange={onChange}
								systemNodeId={systemNodeId}
							/>
						</Suspense>
					),
				},
				{
					id: WorkflowTab.Preview,
					title: t('TAB_PREVIEW'),
					content: (
						<Preview
							key={WorkflowTab.Preview}
							node={node}
							showObjectTypes
							objectTypes={objectTypes}
						/>
					),
				},
				{
					id: WorkflowTab.Deployments,
					title: t('TAB_DEPLOYMENTS'),
					content: (
						<Deployments
							node={node}
							key={WorkflowTab.Deployments}
							editMode={editMode}
						/>
					),
				},
				{
					id: WorkflowTab.Validation,
					title: t('TAB_VALIDATION'),
					content: <Validation key={WorkflowTab.Validation} node={node} />,
				},
				{
					id: WorkflowTab.Dependencies,
					title: t('DEPENDENCIES'),
					content: (
						<Dependencies
							key={WorkflowTab.Dependencies}
							node={node}
							editMode={editMode}
						/>
					),
				},
				{
					id: WorkflowTab.Versions,
					title: t('VERSIONS'),
					content: <VersionsTab />,
				},
			]}
		/>
	)
}

export const WorkflowTabs = React.memo(WorkflowTabsComponent)
