import {
	faArrowRight,
	faCopy,
	faDownload,
	faEdit,
	faFileArchive,
	faFileExport,
	faFolderPlus,
	faLayerGroup,
	faLock,
	faProjectDiagram,
	faRetweet,
	faTable,
	faTasks,
	faTrash,
	faUnlock,
	faWifi,
	faWindowMaximize,
} from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react'

import { DialogWrapper } from '@/components'
import {
	ContextMenuItem,
	ContextMenuItems,
} from '@/components/ContextMenu/ContextMenu'
import { StructureDto, StructureObjectDto } from '@/endpoints/models'
import {
	useAppContext,
	useAppDispatch,
	useAppStore,
	useAuthContext,
} from '@/hooks'
import { lockNode } from '@/store/modules/node/actions'
import { loadNode } from '@/store/modules/node/general-actions'
import { openTab } from '@/store/modules/tab/actions'
import { isLocked, isLockedByOther } from '@/utils/locking'
import {
	isDublicableNodeType,
	isEditableNodeType,
	isImportableNodeType,
} from '@/utils/nodes'

import { AddNodeItem } from './AddNodeItem'
import { ExportStructure } from './ExportStructure'
import {
	batchEditDialog,
	deleteNodeDialog,
	deleteNodesModalDialog,
	duplicateNodeModalDialog,
	importStructureModalDialog,
	massSqlExportModalDialog,
	unlockNodeModalDialog,
} from './ItemMenuDialogs'

type Props = {
	node: StructureDto
}

export const ItemMenu = ({ node }: Props) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const auth = useAuthContext()
	const user = useAppStore((state) => state.auth.user)

	const { nodes, selectedNodes, selectMode } = useAppStore(
		(state) => state.node,
	)

	const open = () => dispatch(openTab(node, false))
	const lock = () => dispatch(lockNode(node))

	const [download, setDownload] = useState(false)

	const canDuplicate = node.canEdit && isDublicableNodeType(node.type)

	const canDelete =
		node.type === StructureObjectDto.TypeEnum.SYSTEM
			? auth.canDeleteSystem() && !isLockedByOther(node, user)
			: node.canEdit &&
				!isLockedByOther(node, user) &&
				node.type !== StructureObjectDto.TypeEnum.ROOT &&
				node.type !== StructureObjectDto.TypeEnum.FOLDER_WORKFLOW

	const canImport = node.canEdit && isImportableNodeType(node.type)

	useEffect(() => {
		const loadNodes = async () => {
			for (const nodeId of selectedNodes) {
				if (!nodes[nodeId]) {
					try {
						await dispatch(loadNode(nodeId))
					} catch (e) {
						console.error(e)
					}
				}
			}
		}

		loadNodes()
	}, [dispatch, nodes, selectedNodes])

	if (!selectMode) {
		return (
			<ContextMenuItems>
				<ContextMenuItem
					onClick={open}
					icon={faArrowRight}
					role="menuitem"
					title={t('CONTEXT_OPEN_TAB')}
				/>

				{node.canEdit &&
					(node.type === StructureObjectDto.TypeEnum.FOLDER ||
						node.type === StructureObjectDto.TypeEnum.SYSTEM) && (
						<AddNodeItem
							node={node}
							type={StructureObjectDto.TypeEnum.FOLDER}
							label={t('CONTEXT_ADD_FOLDER')}
							icon={faFolderPlus}
						/>
					)}

				{node.canEdit && node.type === StructureObjectDto.TypeEnum.FOLDER && (
					<>
						<AddNodeItem
							node={node}
							type={StructureObjectDto.TypeEnum.TABLE}
							label={t('CONTEXT_ADD_TABLE')}
							icon={faTable}
						/>

						<AddNodeItem
							node={node}
							type="DIAGRAM"
							label={t('ADD_DIAGRAM')}
							icon={faProjectDiagram}
						/>

						<AddNodeItem
							node={node}
							type={StructureObjectDto.TypeEnum.VIEW}
							label={t('CONTEXT_ADD_VIEW')}
							icon={faWindowMaximize}
						/>

						<AddNodeItem
							node={node}
							type={StructureObjectDto.TypeEnum.API_NODE}
							label={t('CONTEXT_ADD_API_NODE')}
							icon={faWifi}
						/>

						<AddNodeItem
							node={node}
							type={StructureObjectDto.TypeEnum.QUEUE}
							label={t('CONTEXT_ADD_QUEUE')}
							icon={faTasks}
						/>
					</>
				)}

				{node.canEdit && node.type === StructureObjectDto.TypeEnum.API_NODE && (
					<>
						<AddNodeItem
							node={node}
							type={StructureObjectDto.TypeEnum.API_COLLECTION}
							label={t('CONTEXT_ADD_API_ROWSET')}
							icon={faLayerGroup}
						/>

						<AddNodeItem
							node={node}
							type={StructureObjectDto.TypeEnum.MAPPING_READ}
							label={t('CONTEXT_ADD_MAPPING_READ')}
							icon={faRetweet}
						/>

						<AddNodeItem
							node={node}
							type={StructureObjectDto.TypeEnum.MAPPING_WRITE}
							label={t('CONTEXT_ADD_MAPPING_WRITE')}
							icon={faRetweet}
						/>
					</>
				)}

				{node.canEdit && node.type === StructureObjectDto.TypeEnum.TABLE && (
					<AddNodeItem
						node={node}
						type={StructureObjectDto.TypeEnum.MAPPING}
						label={t('CONTEXT_ADD_MAPPING')}
						icon={faRetweet}
					/>
				)}

				{node.canEdit &&
					node.type === StructureObjectDto.TypeEnum.FOLDER_WORKFLOW && (
						<AddNodeItem
							node={node}
							type={StructureObjectDto.TypeEnum.WORKFLOW}
							label={t('CONTEXT_ADD_WORKFLOW')}
							icon={faProjectDiagram}
						/>
					)}

				{node.canEdit && isEditableNodeType(node.type) && (
					<>
						{!isLocked(node) && (
							<ContextMenuItem
								onClick={lock}
								role="menuitem"
								icon={faLock}
								title={t('CONTEXT_LOCK')}
							/>
						)}

						{isLocked(node) && (
							<DialogWrapper
								dialog={unlockNodeModalDialog}
								dispatch={dispatch}
								node={node}
							>
								{(onClick) => (
									<ContextMenuItem
										onClick={onClick}
										role="menuitem"
										icon={faUnlock}
										title={t('CONTEXT_UNLOCK')}
									/>
								)}
							</DialogWrapper>
						)}
					</>
				)}

				{canDuplicate && (
					<DialogWrapper dialog={duplicateNodeModalDialog} node={node}>
						{(onClick) => (
							<ContextMenuItem
								onClick={onClick}
								role="menuitem"
								icon={faCopy}
								title={t('DUPLICATE')}
							/>
						)}
					</DialogWrapper>
				)}

				{canDelete && (
					<DialogWrapper
						dialog={(opened, onClose, node) =>
							deleteNodeDialog(opened, onClose, node)
						}
						node={node}
					>
						{(onClick) => (
							<ContextMenuItem
								onClick={onClick}
								role="menuitem"
								icon={faTrash}
								title={t('CONTEXT_DELETE')}
							/>
						)}
					</DialogWrapper>
				)}

				<ContextMenuItem
					onClick={() => setDownload(true)}
					icon={faDownload}
					role="menuitem"
					title={t('EXPORT')}
				/>

				{download && (
					<ExportStructure
						nodeId={node.id}
						onDownloadFinish={() => setDownload(false)}
					/>
				)}

				{canImport && (
					<DialogWrapper dialog={importStructureModalDialog} node={node}>
						{(onClick) => (
							<ContextMenuItem
								onClick={onClick}
								icon={faFileExport}
								role="menuitem"
								title={t('IMPORT')}
							/>
						)}
					</DialogWrapper>
				)}

				{canImport && (
					<DialogWrapper
						dialog={massSqlExportModalDialog}
						node={node}
						selectedNodes={selectedNodes}
					>
						{(onClick) => (
							<ContextMenuItem
								onClick={onClick}
								icon={faFileArchive}
								role="menuitem"
								title={t('BATCH_EXPORT')}
							/>
						)}
					</DialogWrapper>
				)}
			</ContextMenuItems>
		)
	} else {
		return (
			<ContextMenuItems>
				{canImport && (
					<DialogWrapper
						dialog={massSqlExportModalDialog}
						node={node}
						selectedNodes={selectedNodes}
					>
						{(onClick) => (
							<ContextMenuItem
								onClick={onClick}
								icon={faFileArchive}
								role="menuitem"
								title={t('BATCH_EXPORT')}
							/>
						)}
					</DialogWrapper>
				)}
				{selectedNodes.length > 0 && (
					<DialogWrapper dialog={deleteNodesModalDialog}>
						{(onClick) => (
							<ContextMenuItem
								onClick={onClick}
								icon={faTrash}
								role="menuitem"
								title={t('BATCH_DELETE')}
							/>
						)}
					</DialogWrapper>
				)}
				{selectedNodes.length > 1 && (
					<DialogWrapper dialog={batchEditDialog} node={node}>
						{(onClick) => (
							<ContextMenuItem
								onClick={onClick}
								icon={faEdit}
								role="menuitem"
								title={t('BATCH_EDIT')}
							/>
						)}
					</DialogWrapper>
				)}
			</ContextMenuItems>
		)
	}
}
