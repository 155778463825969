import { createApi } from '@reduxjs/toolkit/query/react'

import { baseQueryWithAuth } from '@/rtkQueries/utils'

export const diagramApiSlice = createApi({
	reducerPath: 'diagramApiSlice',
	baseQuery: baseQueryWithAuth, // Adjust the base URL based on your needs
	endpoints: (build) => ({
		getDeepDiagram: build.query<any, number>({
			query: (structureId) => `structure/${structureId}/diagram/deep`,
		}),
	}),
})

export const { useGetDeepDiagramQuery } = diagramApiSlice
