import { adjustTableName, checkUniqueName as checkName } from '@/endpoints'
import {
	AdjustResultDto,
	CheckResultDto,
	StructureDto,
} from '@/endpoints/models'
import { RootState } from '@/store/index'
import { CustomTabProps } from '@/store/modules/tab/types'
import { NativeMap } from '@/utils/collections'

interface ReduxAction {
	metadata?: unknown
	payload: unknown
	type: string
}

type ApiCallActionCallback<I extends ReduxAction> = (
	token: string | null,
) => Promise<I['payload']>

type Returned<T> = T extends (...args: any[]) => any ? ReturnType<T> : T

export type AppDispatch = <T>(action: T) => Returned<T>

export type AppThunkAction<R> = (
	dispatch: AppDispatch,
	getState: () => RootState,
) => R

export const thunkAction = <R>(cb: AppThunkAction<R>): AppThunkAction<R> => {
	return (dispatch: AppDispatch, getState: () => RootState) => {
		return cb(dispatch, getState)
	}
}

export const apiCallAction =
	<I extends ReduxAction>(
		call: (getState: () => RootState) => ApiCallActionCallback<I>,
		success?: I['type'],
		metadata?: I['metadata'],
	) =>
	async (
		dispatch: AppDispatch,
		getState: () => RootState,
	): Promise<I['payload']> => {
		const data = await call(getState)(getState().auth.token)

		if (success) {
			dispatch({
				type: success,
				payload: data,
				metadata,
			})
		}

		return data
	}

export const saveState = <T extends object>(
	key: string,
	version: string,
	keys: readonly (keyof T)[],
	state: T,
) => {
	localStorage[key] = JSON.stringify(
		Object.keys(state)
			.filter((k) => k !== '__version' && keys.includes(k as keyof T))
			.reduce(
				(acc, k) => {
					acc[k] = state[k as keyof T]

					return acc
				},
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				{ __version: version } as any,
			),
	)

	return state
}

export const updateTabData = <T>(
	nodes: NativeMap<T>,
	nodeId: number,
	newData: UpdateDeepPartial<T> | ((node: T) => UpdateDeepPartial<T>),
	replace = false,
): NativeMap<T> => {
	const original = nodes[nodeId]

	if (original === undefined) {
		console.warn('Trying to update unitialized node', nodeId)

		return nodes
	}

	const extended = deepExtend(
		!replace
			? {
					...original,
				}
			: {},
		typeof newData === 'function' ? newData(original) : newData,
	)

	return {
		...nodes,
		[nodeId]: extended,
	}
}

/**
 * Deep partial, where Arrays can also be objects with numeric indexes (updating only specific array key)
 */
export type UpdateDeepPartial<T> = {
	[K in keyof T]?: T[K] extends (infer U)[]
		? { [key: number]: UpdateDeepPartial<U> } | U[]
		: T[K] extends object
			? UpdateDeepPartial<T[K]>
			: T[K] extends (infer U)[]
				? { [key: string]: U }
				: T[K]
}

/**
 * Extends specified object with specified object, the extension is deep,
 * which means you can update only part of object in extended object.
 *
 * The extension is inplace.
 *
 * Note about extending arrays:
 *  - when source value is array and target value is also array, it's replaced with target value and that's it
 *  - when source value is array and target is object, it's expected that object only has numeric properties and only
 *      values that correspond to the index (which is numeric prop of target object) are extended with object values
 *
 * @param source object to be extended
 * @param extending extending object
 * @returns the source (same reference that was in the source param)
 */
export const deepExtend = (source: any, extending: any) => {
	Object.entries(extending).forEach(([key, value]: [string | number, any]) => {
		let sourceValue = source[key]

		if (typeof value === 'object') {
			if (value === null) {
				source[key] = null
			} else {
				if (sourceValue === undefined) {
					source[key] = sourceValue = Array.isArray(value) ? [] : {}
				} else {
					// eslint-disable-next-line padding-line-between-statements
					source[key] = sourceValue = Array.isArray(sourceValue)
						? [...sourceValue]
						: { ...sourceValue }
				}

				if (Array.isArray(value)) {
					source[key] = [...value]
				} else {
					deepExtend(sourceValue, value)
				}
			}
		} else {
			source[key] = value
		}
	})

	return source
}

export const getNodeId = (
	releaseTabProps: CustomTabProps | undefined,
	node: StructureDto,
) => (releaseTabProps?.customTabName ? releaseTabProps?.id : node.id)

export const generateCode = (name: string): Promise<AdjustResultDto> =>
	(!name || name.length === 0
		? () => Promise.resolve({ result: '' })
		: apiCallAction(() =>
				adjustTableName({ name }),
			)) as unknown as Promise<AdjustResultDto>

export const checkUniqueName = (
	name?: string,
	systemFolderId?: number,
): Promise<CheckResultDto> =>
	(!name || name.length === 0
		? () => Promise.resolve({ result: true })
		: apiCallAction(() =>
				checkName({ name, systemFolderId }),
			)) as unknown as Promise<CheckResultDto>
