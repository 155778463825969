import { createApi } from '@reduxjs/toolkit/query/react'

import { TemplateDto } from '@/endpoints/models'
import { baseQueryWithAuth } from '@/rtkQueries/utils'

interface TemplateApiSlice {
	id: number
}

const getUrl = (id: number) => `templates/${id}`

export const templateApiSlice = createApi({
	reducerPath: 'templateApiSlice',
	baseQuery: baseQueryWithAuth,
	endpoints: (builder) => ({
		getTemplate: builder.query<TemplateDto, TemplateApiSlice>({
			query: ({ id }) => ({
				url: getUrl(id),
				params: {
					id,
				},
			}),
		}),
	}),
})

export const { useGetTemplateQuery } = templateApiSlice
