import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { has } from 'lodash'
import React, { useCallback, useMemo } from 'react'

import {
	Button,
	DialogWrapper,
	TableAndProperties,
	TabsActionsBar,
} from '@/components'
import { useCanGenerateNaming } from '@/components/UberForm/Input/Naming/useCanGenerateNaming'
import { getStereotypes } from '@/endpoints'
import { useApi } from '@/endpoints/hooks'
import { StereotypeDto } from '@/endpoints/models'
import { TableMode } from '@/enums'
import { useAppContext, useAppDispatch } from '@/hooks'
import { ColumnsComponentProps } from '@/pages/User/pages/Home/pages/TableDetail/pages/types'
import { updateSequenceCode } from '@/store/modules/table/actions'
import { createEmptyColumn } from '@/store/modules/table/helpers'
import {
	OpenedTableData,
	TableColumnFlat,
	TableDataForm,
} from '@/store/modules/table/types'
import { UpdateDeepPartial } from '@/store/utils'
import { NamingDtoTypeEnum, NamingTypeEnum } from '@/types'
import { useDomainTechnology } from '@/utils/domain'

import { ImportColumnsCsv } from '../../../../components/ImportColumnsCsv/ImportColumnsCsv'
import { useColumnProperties } from './useColumnProperties'
import { duplication } from './validation'

const TAB_KEY = 'column'

const ColumnsComponent = ({
	node,
	data,
	systemNodeId,
	editMode,
	onChange,
	technicalColumns,
	domains,
	reloadTableData,
}: ColumnsComponentProps) => {
	const { t } = useAppContext()

	const columnStereotypes = useApi(
		getStereotypes({ type: StereotypeDto.TypeEnum.COLUMN }),
	)

	const dispatch = useAppDispatch()

	const { getDomainData } = useDomainTechnology(systemNodeId)

	const errors = useMemo(
		() => duplication(data.form.columns, technicalColumns, t),
		[data.form.columns, t, technicalColumns],
	)

	const canGenerateNaming = useCanGenerateNaming(
		node.id,
		NamingTypeEnum.GENERATE_TABLE_COLUMN_CODE,
	)

	const properties = useColumnProperties(
		domains,
		data.form.constraints,
		columnStereotypes,
		canGenerateNaming,
	)

	const dialog = (opened: boolean, onClose: () => void) =>
		opened && (
			<ImportColumnsCsv
				node={node}
				onClose={() => {
					onClose()
					reloadTableData()
				}}
			/>
		)

	const handleChange = useCallback(
		(v: UpdateDeepPartial<TableDataForm>) => {
			// sync sequenceColumnCode when removing column
			if (data.form.sequenceColumnCode && Array.isArray(v.columns)) {
				const isSequenceColumnRemoved = Object.values(v.columns).some(
					(c) => !(c.code === data.form.sequenceColumnCode),
				)

				if (isSequenceColumnRemoved) {
					v.sequenceColumnCode = undefined
					v.sequenceCode = undefined
				}
			}

			if (v.columns) {
				Object.values(v.columns).forEach((c) => {
					// sync sequenceColumnCode when renaming column
					if (data.form.sequenceColumnCode) {
						const previousCode = data.form.columns.find(
							(col) => col.id === c.id,
						)?.code

						if (data.form.sequenceColumnCode === previousCode && c.code) {
							dispatch(
								updateSequenceCode(
									node,
									{ sequenceColumnCode: c.code },
									TableMode.TABLE,
									[
										NamingDtoTypeEnum.SEQUENCE_NAME,
										node.id!,
										{
											sequence_column: c.code,
										},
									],
								),
							)
						}
					}

					if (has(c, 'domainId')) {
						const d = domains.find((d) => d.id === c.domainId)
						const domainData = getDomainData(d)
						const colData = data.form.columns.find((col) => col.id === c.id)

						// don't change domain data, when domain is same
						if (colData && colData.domainId === c.domainId) {
							return
						}

						if (d) {
							c.domainId = d.id
							c.domainName = d.name
							c.domainCode = d.code
							c.notNullFlag = d.notNullFlag

							if (!d.custom) {
								c.dataType = domainData?.dataType
								c.defaultValue = domainData?.defaultValue
							}
						} else {
							c.domainId = undefined
							c.domainName = undefined
							c.domainCode = undefined
							c.notNullFlag = undefined
							c.dataType = undefined
							c.defaultValue = undefined
						}
					}

					if (c.stereotypeId) {
						const colStereotype = columnStereotypes.data?.find(
							(col) => col.id === c.stereotypeId,
						)

						c.stereotypeName = colStereotype?.name
						c.stereotypeCode = colStereotype?.code
					}
				})
			}

			onChange(v)
		},
		[
			data.form.sequenceColumnCode,
			data.form.columns,
			onChange,
			node,
			domains,
			getDomainData,
			columnStereotypes.data,
		],
	)

	return (
		<>
			{editMode && (
				<TabsActionsBar>
					<DialogWrapper dialog={dialog}>
						{(onClick) => (
							<Button
								icon={faDownload}
								onClick={onClick}
								coloredIcon={true}
								schema="primary"
							>
								{t('IMPORT_COLUMNS')}
							</Button>
						)}
					</DialogWrapper>
				</TabsActionsBar>
			)}

			<TableAndProperties<OpenedTableData['form'], TableColumnFlat>
				node={node}
				data={data.form}
				isRowOrderable
				createEmpty={createEmptyColumn}
				itemsKey="columns"
				idCounterKey="columnsLastId"
				onChange={handleChange}
				properties={properties}
				tabKey={TAB_KEY}
				errors={errors}
				technicalColumns={technicalColumns}
			/>
		</>
	)
}

export const Columns = React.memo(ColumnsComponent)
