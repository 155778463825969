import { Loader } from '@/components'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { initDiagram } from '@/store/slices/thunks'

import { useDetailTabContext } from '../../components/DetailTab/context/DetailTabContext'
import { EditableNodeActions } from '../../components/EditableNodeActions/EditableNodeActions'
import { Title } from '../../components/Title/Title'
import { TitleLeftContent } from '../../components/TitleLeftContent'
import { useNodeInit } from '../../hooks/useNodeInit'
import { DiagramTabs } from './components/DiagramTabs'

export const DiagramDetail = () => {
	const dispatch = useAppDispatch()
	const { diagrams } = useAppSelector((state) => state.diagram)

	const {
		state: { node, editMode },
	} = useDetailTabContext()

	const data = diagrams?.[node.id]

	useNodeInit()

	const handleEdit = async () => {
		await dispatch(initDiagram({ nodeId: node.id, editMode: true }))
	}

	const handleCancel = async () => {
		await dispatch(initDiagram({ nodeId: node.id, editMode: false }))
	}

	if (!data) {
		return <Loader loaded={false} />
	}

	return (
		<>
			<Title
				type={node.type}
				title={node.name}
				editMode={editMode}
				leftContent={<TitleLeftContent node={node} />}
				rightContent={
					<EditableNodeActions
						node={node}
						editMode={editMode}
						dirty={data.dirty}
						onEdit={handleEdit}
						onCancel={handleCancel}
					/>
				}
			/>
			<DiagramTabs />
		</>
	)
}
