import { ReactNode } from 'react'

import {
	ContentsProps,
	DiagramPropertiesContentProps,
} from '@/components/Diagram/types'
import { ValueLabel } from '@/components/Properties/styles'
import { TranslationHelper } from '@/context/Locale'
import { useAppContext } from '@/hooks'

import { PropertiesHeader, ValueRow } from '../styles'

export enum PropertyType {
	Constraint = 'Constraint',
	Display = 'DisplayOptions',
	Grid = 'Grid',
	Info = 'Info',
	Table = 'Table',
}
export interface RenderInputProps {
	contentValue: ReactNode
	id: number
	label: string
	propertiesWidth: number
}
export const renderInput = ({
	id,
	contentValue,
	label,
	propertiesWidth,
}: RenderInputProps) => {
	return (
		<ValueRow key={id} $propertiesWidth={propertiesWidth}>
			<ValueLabel>{label}</ValueLabel>

			<section>{contentValue}</section>
		</ValueRow>
	)
}

const renderInputSection = (
	contents: ContentsProps[],
	contentType: string,
	propertiesWidth: number,
	t: TranslationHelper,
	hasCustomStyles: boolean,
) => {
	return (
		contents.some(
			(content) => content.type === contentType && content.contentValue,
		) && (
			<div>
				<PropertiesHeader>{t(contentType)}</PropertiesHeader>

				<div className={hasCustomStyles ? 'flex flex-row' : ''}>
					{contents.map((content) => {
						if (
							content.type === contentType &&
							content.contentValue &&
							content.isGrouped
						) {
							return (
								<div key={content.id}>
									{renderInput({
										id: content.id,
										contentValue: content.contentValue,
										label: content.label,
										propertiesWidth: propertiesWidth,
									})}
								</div>
							)
						}

						return <></>
					})}
				</div>

				{contents.map((content) => {
					if (
						content.type === contentType &&
						content.contentValue &&
						!content.isGrouped
					) {
						return (
							<div key={content.id}>
								{renderInput({
									id: content.id,
									contentValue: content.contentValue,
									label: content.label,
									propertiesWidth: propertiesWidth,
								})}
							</div>
						)
					}
				})}
			</div>
		)
	)
}

export const DiagramPropertiesContent = ({
	contents,
	propertiesWidth,
}: DiagramPropertiesContentProps) => {
	const { t } = useAppContext()

	return (
		<div className="max-h-[79vh] w-[15.625rem] overflow-y-auto">
			<>
				{renderInputSection(
					contents,
					PropertyType.Info,
					propertiesWidth,
					t,
					true,
				)}
				{renderInputSection(
					contents,
					PropertyType.Table,
					propertiesWidth,
					t,
					false,
				)}
				{renderInputSection(
					contents,
					PropertyType.Display,
					propertiesWidth,
					t,
					false,
				)}
				{renderInputSection(
					contents,
					PropertyType.Grid,
					propertiesWidth,
					t,
					true,
				)}
				{renderInputSection(
					contents,
					PropertyType.Constraint,
					propertiesWidth,
					t,
					false,
				)}
			</>
		</div>
	)
}
