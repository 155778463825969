import { faFolderPlus } from '@fortawesome/free-solid-svg-icons'
import debounce from 'debounce'
import { useCallback } from 'react'

import { Button, DialogWrapper, Loader } from '@/components'
import { useTabContext } from '@/context/TabContext/TabContext'
import { StructureObjectDto } from '@/endpoints/models'
import { SystemData } from '@/endpoints/schemas'
import { useAppContext, useAppDispatch, useAppStore } from '@/hooks'
import {
	initSystem,
	saveSystem,
	updateSystem,
} from '@/store/modules/system/actions'
import { UpdateDeepPartial } from '@/store/utils'
import { useDeletedDomains } from '@/utils/domain'

import {
	AddModal,
	EditableNodeActions,
	Title,
	TitleLeftContent,
} from '../../components'
import { useDetailTabContext } from '../../components/DetailTab'
import { useNodeInit } from '../../hooks/useNodeInit'
import { SystemTabs } from './components/Tabs'

export const SystemDetail = () => {
	const dispatch = useAppDispatch()
	const { t } = useAppContext()
	const { onSaveError } = useTabContext()
	const { systems } = useAppStore((state) => state.system)

	const {
		state: { node, systemNodeId, editMode },
	} = useDetailTabContext()

	const data = systems[node.id]

	const domains = useDeletedDomains(systemNodeId, data)

	useNodeInit()

	const handleEdit = async () => {
		await dispatch(initSystem({ nodeId: node.id, editMode: true }))
	}

	const handleCancel = async () => {
		await dispatch(initSystem({ nodeId: node.id, editMode: false }))
	}

	const saveDebounced = useCallback(
		debounce(async () => {
			try {
				await dispatch(saveSystem(node))
			} catch (e) {
				onSaveError(e)
			}
		}, 1000),
		[node],
	)

	const handleChange = useCallback(
		(v: UpdateDeepPartial<SystemData>) => {
			if (editMode) {
				dispatch(updateSystem(node, v))
				saveDebounced()
			}
		},
		[editMode, dispatch, node, saveDebounced],
	)

	if (!data) {
		return <Loader loaded={false} />
	}

	const dialog = (opened: boolean, onClose: () => void) =>
		opened && (
			<AddModal
				node={node}
				type={StructureObjectDto.TypeEnum.FOLDER}
				onClose={onClose}
			/>
		)

	return (
		<>
			<Title
				type={node.type as StructureObjectDto.TypeEnum}
				title={node.name}
				editMode={editMode}
				leftContent={<TitleLeftContent node={node} />}
				rightContent={
					<EditableNodeActions
						node={node}
						editMode={editMode}
						onEdit={handleEdit}
						onCancel={handleCancel}
						dirty={data.dirty}
						moreButtons={
							node.canEdit && (
								<>
									<DialogWrapper dialog={dialog}>
										{(onClick) => (
											<Button
												icon={faFolderPlus}
												onClick={onClick}
												schema="success"
												coloredIcon={true}
											>
												{t('ADD_FOLDER')}
											</Button>
										)}
									</DialogWrapper>
								</>
							)
						}
					/>
				}
			/>
			<SystemTabs
				data={data}
				node={node}
				editMode={editMode}
				onChange={handleChange}
				domains={domains || []}
				systemNodeId={systemNodeId}
			/>
		</>
	)
}
