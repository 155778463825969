import React, { useMemo } from 'react'

import { AceEditorField } from '@/components/AceEditor/AceEditorField'
import {
	CheckBoxFormField,
	SelectFormField,
	TextFormField,
	Validators,
} from '@/components/UberForm'
import ArrayFormField from '@/components/UberForm/arrayField/ArrayFormField'
import { InputTypeEnum } from '@/components/UberForm/arrayField/types'
import NumberFormField from '@/components/UberForm/Helpers/NumberFormField'
import { CustomAttributeComboDto, TableColumn } from '@/endpoints/models'
import { useAppContext } from '@/hooks'
import {
	CustomAttributesSwitcherProps,
	PageType,
} from '@/pages/User/pages/Home/components/CustomAttributes/types'
import { getCustomAttributeName } from '@/pages/User/pages/Home/components/CustomAttributes/utils'

import { Wrapper } from './styles'

export const CustomAttributesSwitcher = <T extends TableColumn>({
	customAttribute,
	item,
	$pageType,
}: CustomAttributesSwitcherProps<T>) => {
	const { t } = useAppContext()

	const sharedProps = useMemo(
		() => ({
			name: getCustomAttributeName($pageType, customAttribute, item),
			title: `${customAttribute.name}`,
		}),
		[customAttribute, item, $pageType],
	)

	switch (customAttribute.type) {
		case CustomAttributeComboDto.TypeEnum.BOOLEAN: {
			return $pageType === PageType.OVERVIEW_PAGE ? (
				<Wrapper>
					<CheckBoxFormField {...sharedProps} />
				</Wrapper>
			) : (
				<CheckBoxFormField {...sharedProps} />
			)
		}

		case CustomAttributeComboDto.TypeEnum.NUMBER: {
			return <NumberFormField {...sharedProps} />
		}

		case CustomAttributeComboDto.TypeEnum.SCRIPT: {
			return <AceEditorField {...sharedProps} />
		}

		case CustomAttributeComboDto.TypeEnum.ARRAY: {
			return (
				<ArrayFormField
					{...sharedProps}
					fields={[
						{
							name: 'value',
							inputType: InputTypeEnum.TEXT,
							placeholder: t('VALUE'),
						},
					]}
				/>
			)
		}

		case CustomAttributeComboDto.TypeEnum.LOV: {
			return (
				<SelectFormField {...sharedProps} options={customAttribute.lovValues} />
			)
		}

		case CustomAttributeComboDto.TypeEnum.STRING: {
			return (
				<TextFormField
					{...sharedProps}
					validators={
						customAttribute.regex
							? [Validators.customRegex(customAttribute.regex)]
							: undefined
					}
				/>
			)
		}

		default: {
			console.error('Unknown custom attribute type')

			return <>Unknown custom attribute type</>
		}
	}
}
