import { ReactNode, useMemo } from 'react'

import { DiagramContext } from '@/components/Diagram/context/DiagramContext'
import { StructureDto } from '@/endpoints/models'

import { useReactDiagram } from '../hooks'

interface DiagramProviderProps {
	children: ReactNode
	isEditMode: boolean
	node: StructureDto
}

// Provider component
export const DiagramProvider = ({
	children,
	isEditMode,
	node,
}: DiagramProviderProps) => {
	const diagram = useReactDiagram({ isEditMode })

	const value = useMemo(
		() => ({
			diagram,
			isEditMode,
			node,
		}),
		[diagram, isEditMode, node],
	)

	return (
		<DiagramContext.Provider value={value}>{children}</DiagramContext.Provider>
	)
}
