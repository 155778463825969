import { DiagramData } from '@/endpoints/schemas/diagram'
import { updateTabData } from '@/store/utils'
import { NativeMap } from '@/utils/collections'

import { ensureValidData } from '../helpers'
import {
	ADD_NEW_DIAGRAM,
	ADD_NEW_DIAGRAM_NODE,
	DIAGRAM_LINK_PROP_UPDATE,
	DIAGRAM_NODE_PROP_UPDATE,
	DIAGRAM_PROP_UPDATE,
	DIAGRAM_UPDATE,
	FOLDER_INIT,
	FOLDER_SAVE,
	FOLDER_UPDATE,
	REMOVE_DIAGRAM,
	REMOVE_DIAGRAM_NODE,
} from './constants'
import {
	defaultFolderData,
	getFolderDataForm,
	getInitialFolderData,
} from './helpers'
import { Action, FolderData, OpenedFolderData, OpenedFolderTab } from './types'

type State = Readonly<typeof initialState>

const initialState = {
	folders: {} as NativeMap<OpenedFolderData>,
}

export default (state = initialState, action: Action): State => {
	switch (action.type) {
		case FOLDER_INIT: {
			const { node, editMode, force } = action

			const previous = state.folders[node.id]

			if (previous && editMode && previous.parsedEditMode && !force) {
				return state
			}

			const serializedData = editMode
				? node.workingData || node.data
				: node.data

			// TODO: we should validate data against the JSON schema
			const parsed = JSON.parse(serializedData || '{}')

			const data: FolderData = Object.keys(parsed).length
				? ensureValidData(parsed, defaultFolderData)
				: getInitialFolderData(node.name)

			const folderDataForm = getFolderDataForm(data)

			return {
				...state,
				folders: {
					...state.folders,
					[node.id]: {
						form: folderDataForm,
						dirty: false,
						parsedEditMode: editMode,
						tab: previous ? previous.tab : OpenedFolderTab.Overview,
					},
				},
			}
		}

		case FOLDER_UPDATE: {
			const { node, payload } = action

			return {
				...state,
				folders: updateTabData(state.folders, node.id, (node) => ({
					...node,
					form: {
						...node.form,
						...payload,
						diagram: {
							...node.form.diagram,
							...payload.diagram,
						} as DiagramData,
					},
					dirty: true,
				})),
			}
		}

		case ADD_NEW_DIAGRAM: {
			const { nodeId, payload } = action

			return {
				...state,
				folders: updateTabData(state.folders, nodeId, (node) => {
					// Determine the next id based on the existing diagrams
					const currentDiagrams = state.folders[nodeId]?.form.diagrams || []
					// Calculate the next id
					let nextId =
						currentDiagrams.length > 0
							? currentDiagrams?.[currentDiagrams?.length - 1]?.id + 1
							: 1

					// If nextId is NaN, reset it to 1
					nextId = isNaN(nextId) ? 1 : nextId

					// Update the id property for the new diagram
					const updatedDiagram = {
						...payload,
						id: nextId,
					}

					return {
						...node,
						form: {
							...node.form,
							diagrams: [...currentDiagrams, { ...updatedDiagram }],
						},
						dirty: true,
					}
				}),
			}
		}

		case REMOVE_DIAGRAM: {
			const { node, update } = action

			return {
				...state,
				folders: updateTabData(state.folders, node.id, (node) => ({
					...node,
					form: {
						...node.form,
						diagrams: [...update],
					},
					dirty: true,
				})),
			}
		}

		case DIAGRAM_UPDATE: {
			const { node, payload } = action

			const diagramIndex = state.folders[node.id]?.form?.diagrams?.findIndex(
				(diagram) => diagram.id === payload.selectedTabDetailId,
			)

			// If diagram with the given id is found, update it
			if (diagramIndex !== -1) {
				const updatedDiagrams = state.folders[node.id]?.form?.diagrams?.map(
					(diagram, index) =>
						index === diagramIndex
							? { ...diagram, ...payload.modifiedDiagramModel }
							: diagram,
				)

				return {
					...state,
					folders: updateTabData(state.folders, node.id, (node) => {
						return {
							...node,
							form: {
								...node.form,
								diagrams: updatedDiagrams,
							},
							dirty: true,
						}
					}),
				}
			}
			return state
		}

		case DIAGRAM_PROP_UPDATE: {
			const { node, payload } = action

			const diagramIndex = state.folders[node.id]?.form?.diagrams?.findIndex(
				(diagram) => diagram.id === payload.selectedTabDetailId,
			)

			// If diagram with the given id is found, update it
			if (diagramIndex !== -1) {
				const updatedProperties = state.folders[node.id]?.form?.diagrams?.map(
					(diagram, index) => {
						if (index === diagramIndex) {
							return {
								...diagram,
								properties: {
									...diagram.properties,
									...payload.panelProperties,
								},
							}
						}

						return diagram
					},
				)

				return {
					...state,
					folders: updateTabData(state.folders, node.id, (node) => {
						return {
							...node,
							form: {
								...node.form,
								diagrams: updatedProperties,
							},
							dirty: true,
						}
					}),
				}
			}
			return state
		}

		case DIAGRAM_NODE_PROP_UPDATE: {
			const { node, payload } = action

			const diagramIndex = state.folders[node.id]?.form?.diagrams?.findIndex(
				(diagram) => diagram.id === payload.selectedTabDetailId,
			)

			// If diagram with the given id is found, update it
			if (diagramIndex !== -1) {
				const updatedProperties = state.folders[node.id]?.form?.diagrams?.map(
					(diagram, index) => {
						if (index === diagramIndex) {
							const updatedNodeDataArray = diagram.nodeDataArray.map((node) => {
								if (node.key === payload.modifiedNodeData.key) {
									return payload.modifiedNodeData
								}

								return node
							})

							return {
								...diagram,
								nodeDataArray: updatedNodeDataArray,
							}
						}

						return diagram
					},
				)

				return {
					...state,
					folders: updateTabData(state.folders, node.id, (node) => {
						return {
							...node,
							form: {
								...node.form,
								diagrams: updatedProperties,
							},
							dirty: true,
						}
					}),
				}
			}

			return state
		}

		case DIAGRAM_LINK_PROP_UPDATE: {
			const { payload } = action
			const { selectedTabDetailId, data, nodeId } = payload

			const diagramIndex = state.folders[nodeId]?.form?.diagrams?.findIndex(
				(diagram) => diagram.id === selectedTabDetailId,
			)

			// If diagram with the given id is found, update it
			if (diagramIndex !== -1) {
				const updatedDiagrams = state?.folders?.[nodeId]?.form?.diagrams?.map(
					(diagram, index) => {
						if (index === diagramIndex) {
							const updatedLinkDataArray = diagram.linkDataArray.map((link) => {
								if (link?.key === data?.key) {
									return data
								}
								return link
							})

							return {
								...diagram,
								linkDataArray: updatedLinkDataArray,
							}
						}
						return diagram
					},
				)

				return {
					...state,
					folders: {
						...state.folders,
						[nodeId]: {
							...state.folders[nodeId],
							form: {
								...state?.folders?.[nodeId]?.form,
								diagrams: updatedDiagrams,
							},
							dirty: true,
						},
					},
				}
			}

			return state
		}

		case FOLDER_SAVE: {
			const {
				metadata: { nodeId },
			} = action

			return {
				...state,
				folders: updateTabData(state.folders, nodeId, (node) => ({
					...node,
					dirty: false,
				})),
			}
		}

		case ADD_NEW_DIAGRAM_NODE: {
			const { nodeId, payload } = action

			const diagramIndex = state.folders[nodeId]?.form?.diagrams?.findIndex(
				(diagram) => diagram.id === payload.selectedTabDetailId,
			)

			if (diagramIndex === -1) {
				return state // If the diagram is not found, return the current state
			}

			const updatedDiagrams = state?.folders?.[nodeId]?.form?.diagrams?.map(
				(diagram, index) => {
					if (index === diagramIndex) {
						return {
							...diagram,
							nodeDataArray: [...diagram.nodeDataArray, payload],
						}
					}
					return diagram
				},
			)

			return {
				...state,
				folders: {
					...state.folders,
					[nodeId]: {
						...state.folders[nodeId],
						form: {
							...state?.folders?.[nodeId]?.form,
							diagrams: updatedDiagrams,
						},
						dirty: true,
					},
				},
			}
		}

		case REMOVE_DIAGRAM_NODE: {
			// New case for removing a diagram node
			const { nodeId, selectedTabDetailId, removedNodeId } = action

			const diagramIndex = state.folders[nodeId]?.form?.diagrams?.findIndex(
				(diagram) => diagram.id === selectedTabDetailId,
			)

			if (diagramIndex === -1) {
				return state // If the diagram is not found, return the current state
			}

			const updatedDiagrams = state?.folders?.[nodeId]?.form?.diagrams?.map(
				(diagram, index) => {
					if (index === diagramIndex) {
						return {
							...diagram,
							nodeDataArray: diagram.nodeDataArray.filter(
								(node) => node.key !== removedNodeId,
							),
						}
					}
					return diagram
				},
			)

			return {
				...state,
				folders: {
					...state.folders,
					[nodeId]: {
						...state.folders[nodeId],
						form: {
							...state?.folders?.[nodeId]?.form,
							diagrams: updatedDiagrams,
						},
						dirty: true,
					},
				},
			}
		}

		default: {
			return state
		}
	}
}
