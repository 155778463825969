import { appConfig } from '@/config'

export const lockTemplate = async (id: number, token: string | null) => {
	try {
		const response = await fetch(`${appConfig.apiUrl}/templates/${id}/lock`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})

		return await response.json()
	} catch (error) {
		console.error(error)
	}
}
export const unlockTemplate = async (id: number, token: string | null) => {
	try {
		const response = await fetch(`${appConfig.apiUrl}/templates/${id}/unlock`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})

		return await response.json()
	} catch (error) {
		console.error(error)
	}
}
export const pushTemplate = async (id: number, token: string | null) => {
	try {
		const response = await fetch(
			`${appConfig.apiUrl}/templates/${id}/push?unlock=true`,
			{
				method: 'POST',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			},
		)

		return await response.json()
	} catch (error) {
		console.error(error)
	}
}
