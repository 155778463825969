import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { DialogWrapper } from '@/components'
import { ContextMenuItem } from '@/components/ContextMenu/ContextMenu'
import { StructureDto, StructureObjectDto } from '@/endpoints/models'

import { AddModal } from '../../../components/AddModal'

type Props = {
	color?: string
	icon: IconProp
	label: string
	node: StructureDto
	type: StructureObjectDto.TypeEnum
}

const renderDialog = (
	opened: boolean,
	onClose: () => void,
	node: StructureDto,
	type?: StructureObjectDto.TypeEnum,
) =>
	opened && (
		<AddModal
			node={node}
			type={type as StructureObjectDto.TypeEnum}
			onClose={onClose}
		/>
	)

export const AddNodeItem = ({ node, type, label, icon }: Props) => (
	<DialogWrapper
		dialog={(opened, onClose) => renderDialog(opened, onClose, node, type)}
	>
		{(onClick) => (
			<ContextMenuItem
				onClick={onClick}
				role="menuitem"
				icon={icon}
				color="gray"
				title={label}
			/>
		)}
	</DialogWrapper>
)
