import * as go from 'gojs'

import { getNodePath } from '@/components/Diagram/utils'

export const createTableNodeTooltip = () =>
	new go.Adornment('Auto', { background: 'grey' }).add(
		new go.TextBlock()
			.set({ margin: 4, width: 140 })
			.bind(
				new go.Binding(
					'text',
					'',
					(data) =>
						`${data.text}:\n\n${getNodePath(treeNodes, data.key) || ''}`,
				),
			),
	)
