import styled from 'styled-components'

import { Tabs } from '@/components'
import { useAppContext } from '@/hooks'
import { VersionsTab } from '@/pages/User/pages/Home/components/VersionsTab/VersionsTab'
import { MappingTabsProps } from '@/pages/User/pages/Home/types'
import { MappingTab } from '@/store/modules/mapping/types'

import {
	Dependencies,
	Deployments,
	Preview,
	Validation,
} from '../../../components'
import { Columns, Others, Overview, Permissions, Sources } from '../pages'

export const MappingTabs = ({
	data,
	node,
	editMode,
	defaultMapping,
	systemNodeId,
	onTabChange,
	onChange,
	objectTypes,
}: MappingTabsProps) => {
	const { t } = useAppContext()

	return (
		<GrowTabs
			onChange={onTabChange}
			selectedTabId={data.tab}
			tabs={[
				{
					id: MappingTab.Overview,
					title: t('TAB_OVERVIEW'),
					content: (
						<Overview
							node={node}
							defaultMapping={defaultMapping}
							key={MappingTab.Overview}
							onChange={onChange}
							data={data}
							editMode={editMode}
							systemNodeId={systemNodeId}
						/>
					),
				},
				...(!defaultMapping
					? [
							{
								id: MappingTab.Columns,
								title: t('TAB_COLUMN_MAPPINGS'),
								content: (
									<Columns
										key={MappingTab.Columns}
										node={node}
										data={data}
										editMode={editMode}
										onChange={onChange}
									/>
								),
							},
							{
								id: MappingTab.Sources,
								title: t('TAB_SOURCES'),
								content: (
									<Sources
										key={MappingTab.Sources}
										node={node}
										data={data}
										systemNodeId={systemNodeId}
										editMode={editMode}
										onChange={onChange}
									/>
								),
							},
							{
								id: MappingTab.Permissions,
								title: t('PERMISSIONS'),
								content: (
									<Permissions
										key={MappingTab.Permissions}
										node={node}
										data={data}
										systemNodeId={systemNodeId}
										editMode={editMode}
										onChange={onChange}
									/>
								),
							},
							{
								id: MappingTab.Others,
								title: t('TAB_OTHERS'),
								content: (
									<Others
										key={MappingTab.Others}
										data={data}
										editMode={editMode}
										onChange={onChange}
									/>
								),
							},
						]
					: [
							{
								id: MappingTab.Permissions,
								title: t('PERMISSIONS'),
								content: (
									<Permissions
										key={MappingTab.Permissions}
										node={node}
										data={data}
										systemNodeId={systemNodeId}
										editMode={editMode}
										onChange={onChange}
									/>
								),
							},
						]),
				{
					id: MappingTab.Preview,
					title: t('TAB_PREVIEW'),
					content: (
						<Preview
							key={MappingTab.Preview}
							node={node}
							showObjectTypes
							objectTypes={objectTypes}
						/>
					),
				},
				{
					id: MappingTab.Deployments,
					title: t('TAB_DEPLOYMENTS'),
					content: (
						<Deployments
							node={node}
							key={MappingTab.Deployments}
							editMode={editMode}
						/>
					),
				},
				{
					id: MappingTab.Validation,
					title: t('TAB_VALIDATION'),
					content: <Validation key={MappingTab.Validation} node={node} />,
				},
				{
					id: MappingTab.Dependencies,
					title: t('DEPENDENCIES'),
					content: (
						<Dependencies
							key={MappingTab.Dependencies}
							node={node}
							editMode={editMode}
						/>
					),
				},
				{
					id: MappingTab.Versions,
					title: t('VERSIONS'),
					content: <VersionsTab />,
				},
			]}
		/>
	)
}

const GrowTabs = styled(Tabs)`
	flex-grow: 1;
	overflow: auto;
	min-height: 0;
`
