import { ColumnsPickerValue } from '@/components/ColumnsPicker/types'
import {
	FormValue,
	Item,
	PropertiesErrorProps,
	TableColumnProps,
} from '@/types'

import { EditingAddress, MoveDir } from '../types'

export type TablePropertiesContextProps = {
	children?: React.ReactNode
	columnWidthsParent?: Record<string, number>
	errors?: PropertiesErrorProps
	isDeletable?: (item: Item, itemIndex: number) => boolean
	isLastRowOrderable?: boolean
	isReadonly?: (item: Item) => boolean
	isRowOrderable?: boolean
	items: Item[]
	onChange?: (
		item: Item,
		key: string,
		value: FormValue | ColumnsPickerValue,
	) => void
	onDelete?: (item: Item) => void
	onRowOrderChanged?: (oldIndex: number, newIndex: number) => void
	onSelect?: (index: number) => void
	properties: TableColumnProps<Item>[]
	propertiesHidden?: boolean
	readonly: boolean
	selectedItemIndex?: number
}

export type RowOrderable = { down?: boolean; enabled: boolean; up?: boolean }

export type TablePropertiesContextState = {
	columnWidths: Record<string, number>
	dirLast: MoveDir | null
	dragOverRowIndex: number | null
	draggingRowIndex: number | null
	//** React-window hides rows based on scroll */
	firstRenderedItemIndex?: number
	focused: EditingAddress | null
	handleFocus: (rowUpdate: number, fieldUpdate: number) => void
	handleMove: (dir: MoveDir) => void
	handleRowDrop: (dropIndex: number) => void
	isReadOnly?: boolean
	onDragEnter: (rowIndex: number) => void
	refContainer: React.RefObject<HTMLDivElement>
	resolveIsRowOrderable: (itemIndex: number) => RowOrderable
	scrollbarWidth: number | null
	tableProps: TableColumnProps<Item>[]
} & Omit<TablePropertiesContextProps, 'children' | 'columnWidthsParent'>

export interface TablePropertiesContextType {
	dispatch: React.Dispatch<Actions>
	state: TablePropertiesContextState
}

export enum ActionTypeEnum {
	FOCUSED_SET,
	DIR_LAST_SET,
	COLUMNS_WIDTHS_SET,
	DRAG_OVER_INDEX_SET,
	DRAGGING_ROW_INDEX_SET,
	SCROLLBAR_WIDTH_SET,
	SYNC_CONTEXT_AND_PROPS,
}

type ActionFocusedSet = {
	payload: {
		focused: EditingAddress | null
	}
	type: ActionTypeEnum.FOCUSED_SET
}

type ActionDirLastSet = {
	payload: {
		dirLast: MoveDir | null
	}
	type: ActionTypeEnum.DIR_LAST_SET
}

type ActionColumnsWidthsSet = {
	payload: {
		columnWidths: Record<string, number>
	}
	type: ActionTypeEnum.COLUMNS_WIDTHS_SET
}

type ActionDragOverRowIndex = {
	payload: {
		dragOverRowIndex: number | null
	}
	type: ActionTypeEnum.DRAG_OVER_INDEX_SET
}

type ActionDraggingRowIndex = {
	payload: {
		draggingRowIndex: number | null
	}
	type: ActionTypeEnum.DRAGGING_ROW_INDEX_SET
}

type ActionScrollbarWidthSet = {
	payload: {
		scrollbarWidth: number | null
	}
	type: ActionTypeEnum.SCROLLBAR_WIDTH_SET
}

type ActionSyncContextAndProps = {
	payload: Partial<TablePropertiesContextState>
	type: ActionTypeEnum.SYNC_CONTEXT_AND_PROPS
}

export type Actions =
	| ActionFocusedSet
	| ActionDirLastSet
	| ActionColumnsWidthsSet
	| ActionDragOverRowIndex
	| ActionDraggingRowIndex
	| ActionScrollbarWidthSet
	| ActionSyncContextAndProps
