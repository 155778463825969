import styled, { css } from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: max(400px, 100%);
`

export const Controls = styled.div`
	display: flex;
	gap: 0.5rem;
	padding: 0.5rem;
`
export const PropertiesHeader = styled.div`
	border-bottom: 1px solid black;
	font-weight: bold;
	margin: 0 30px 0 30px;
	display: flex;
	flex-direction: row;
	justify-content: center;
`

export const DiagramContainer = styled.div`
	flex: 1;
	position: relative;
	box-sizing: border-box;
	border: 1px solid;
	display: flex;
	flex-wrap: wrap;

	.palette {
		flex: 0 0 200px;
		background-color: white;
		border-right: 1px solid;
	}

	.diagram {
		flex: 1 1 auto;
	}
`

export const Title = styled.div`
	padding: 8px 12px;
	border-bottom: 1px solid #ddd;
	background: #f0f0f0;
	text-transform: uppercase;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	cursor: pointer;

	svg {
		margin-right: 10px;
		cursor: pointer;
	}
`

export const Values = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-grow: 1;
	overflow: auto;
	margin: 0;
	padding: 0 10px;
	align-content: flex-start;
`

export const ValueRow = styled.div<{
	$propertiesWidth: number
	$valueWidth?: string
}>`
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
	padding: 0 10px;
	width: 100%;
	box-sizing: border-box;
	min-height: 30px;
	${(props) =>
		props.$propertiesWidth > 450 &&
		css`
			width: ${props.$valueWidth ? props.$valueWidth : '100%'};
		`}
	${(props) =>
		props.$valueWidth &&
		css`
			min-width: auto;
		`}
`

export const OpenButton = styled.div`
	position: absolute;
	top: 2.5rem;
	right: 0;
	cursor: pointer;
	transform: rotate(-90deg) translate(0, 2.5rem);
	padding: 7px;
	display: flex;
	background: #eee;
	border: 1px solid #ddd;
	transition: 0.1s color;
	z-index: 10;
	color: #777;
	:hover {
		color: #333;
		cursor: pointer;
	}
`
export const ToggleButton = styled.div`
	opacity: 0.7;
	transition: 0.2s opacity;
	:hover {
		opacity: 1;
	}
`

export const OpenButtonIcon = styled.span`
	flex: 0;
	margin: 1px 4px;
`

export const OpenButtonText = styled.span`
	text-transform: uppercase;
	flex: 1;
	margin: 0 4px;
`

export const DiagramPanel = styled.div`
	display: flex;
	flex-direction: column;
	height: max(25rem, 100%);
	border: 0.03rem solid #d3d3d3;
`
export const RoutingTypeContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
	margin-left: 0.5rem;
`
export const RoutingTypeLabel = styled.label`
	margin-bottom: 0.5rem;
`
export const RadioGroup = styled.div`
	display: flex;
	align-items: center;
`
export const RadioLabel = styled.label`
	margin-right: 1rem;
	display: flex;
	align-items: center;
`
