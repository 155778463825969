import { Column, Table } from '@tanstack/react-table'
import { useEffect, useState } from 'react'

function DebouncedInput({
	value: initialValue,
	onChange,
	debounce = 500,
	...props
}: {
	debounce?: number
	onChange: (value: string | number) => void
	value: string | number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
	const [value, setValue] = useState(initialValue)

	useEffect(() => {
		setValue(initialValue)
	}, [initialValue])

	useEffect(() => {
		const timeout = setTimeout(() => {
			onChange(value)
		}, debounce)

		return () => clearTimeout(timeout)
	}, [value])

	return (
		<input
			style={{ padding: 0 }}
			{...props}
			value={value}
			onChange={(e) => setValue(e.target.value)}
		/>
	)
}

export const ColumnFilter = ({
	column,
}: {
	column: Column<any, unknown>
	table: Table<any>
}) => {
	const columnFilterValue = column.getFilterValue()

	return (
		<DebouncedInput
			type="text"
			value={(columnFilterValue ?? '') as string}
			onChange={(value) => column.setFilterValue(value)}
			placeholder={`Search... (${column?.getFacetedUniqueValues()?.size})`}
			className="w-36 border shadow rounded"
			list={column.id + 'list'}
		/>
	)
}
