import styled from 'styled-components'

import { TabProps, Tabs } from '@/components/Tabs/Tabs'
import { StructureDto } from '@/endpoints/models'
import { useAppContext } from '@/hooks'
import { VersionsTab } from '@/pages/User/pages/Home/components/VersionsTab/VersionsTab'
import {
	OpenedWriteMappingData,
	WriteMappingDataForm,
	WriteMappingTab,
} from '@/store/modules/writeMapping/types'
import { UpdateDeepPartial } from '@/store/utils'

import {
	Dependencies,
	Deployments,
	Preview,
	Validation,
} from '../../../components'
import { Columns, Others, Overview, Sources } from '../pages'

type WriteMappingTabsProps = {
	data: OpenedWriteMappingData
	editMode: boolean
	node: StructureDto
	onChange: (v: UpdateDeepPartial<WriteMappingDataForm>) => void
	onTabChange: (tab: TabProps) => void
	systemNodeId: number
}

export const WriteMappingTabs = ({
	data,
	node,
	editMode,
	systemNodeId,
	onTabChange,
	onChange,
}: WriteMappingTabsProps) => {
	const { t } = useAppContext()

	return (
		<GrowTabs
			onChange={onTabChange}
			selectedTabId={data.tab}
			tabs={[
				{
					id: WriteMappingTab.Overview,
					title: t('TAB_OVERVIEW'),
					content: (
						<Overview
							key={WriteMappingTab.Overview}
							onChange={onChange}
							data={data}
							editMode={editMode}
							systemNodeId={systemNodeId}
							node={node}
						/>
					),
				},
				{
					id: WriteMappingTab.Columns,
					title: t('TAB_COLUMN_MAPPINGS'),
					content: (
						<Columns
							key={WriteMappingTab.Columns}
							node={node}
							data={data}
							editMode={editMode}
							onChange={onChange}
						/>
					),
				},
				{
					id: WriteMappingTab.Sources,
					title: t('TAB_SOURCES'),
					content: (
						<Sources
							key={WriteMappingTab.Sources}
							data={data}
							editMode={editMode}
							onChange={onChange}
						/>
					),
				},

				{
					id: WriteMappingTab.Others,
					title: t('TAB_OTHERS'),
					content: (
						<Others
							key={WriteMappingTab.Others}
							data={data}
							editMode={editMode}
							onChange={onChange}
						/>
					),
				},
				{
					id: WriteMappingTab.Preview,
					title: t('TAB_PREVIEW'),
					content: <Preview key={WriteMappingTab.Preview} node={node} />,
				},
				{
					id: WriteMappingTab.Deployments,
					title: t('TAB_DEPLOYMENTS'),
					content: (
						<Deployments
							node={node}
							key={WriteMappingTab.Deployments}
							editMode={editMode}
						/>
					),
				},
				{
					id: WriteMappingTab.Validation,
					title: t('TAB_VALIDATION'),
					content: <Validation key={WriteMappingTab.Validation} node={node} />,
				},
				{
					id: WriteMappingTab.Dependencies,
					title: t('DEPENDENCIES'),
					content: (
						<Dependencies
							key={WriteMappingTab.Dependencies}
							node={node}
							editMode={editMode}
						/>
					),
				},
				{
					id: WriteMappingTab.Versions,
					title: t('VERSIONS'),
					content: <VersionsTab />,
				},
			]}
		/>
	)
}

const GrowTabs = styled(Tabs)`
	flex-grow: 1;
	overflow: auto;
	min-height: 0;
`
