import debounce from 'debounce'
import go from 'gojs'
import { useCallback, useEffect, useRef } from 'react'

import { DiagramWrapper } from '@/components'
import { DiagramProvider } from '@/components/Diagram/context/DiagramProvider'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { useNodeInit } from '@/pages/User/pages/Home/hooks/useNodeInit'
import { useGetDeepDiagramQuery } from '@/rtkQueries'
import {
	changeInspected,
	diagramPropUpdated,
	linksParsed,
	nodeModified,
	skipSet,
} from '@/store'
import { saveDiagram } from '@/store/slices/thunks'
import { DiagramProperties, OnModelChange } from '@/types'

export const DiagramGraph = () => {
	const dispatch = useAppDispatch()
	const {
		state: { node, editMode },
	} = useDetailTabContext()

	useNodeInit()

	const thisDiagram = useAppSelector(
		(state) => state.diagram.diagrams[node.id].form.graph,
	)

	const { data: deepDiagram } = useGetDeepDiagramQuery(
		node.parentStructureId as number,
	)

	const saveDebounced = debounce(() => dispatch(saveDiagram(node)), 1000)

	const nodeDataArray = thisDiagram.nodeDataArray
	const linkDataArray = thisDiagram.linkDataArray
	const modelData = thisDiagram.modelData
	const skipsDiagramUpdate = thisDiagram.skipsDiagramUpdate

	// const selectedData = useAppStore((state) => state.diagram.selectedData)
	const mapNodeKeyIdxRef = useRef<Map<go.Key, number>>(new Map())
	const mapLinkKeyIdxRef = useRef<Map<go.Key, number>>(new Map())

	/**
	 * Update map of node keys to their index in the array.
	 */
	const refreshNodeIndex = (nodeArr: Array<go.ObjectData>) => {
		mapNodeKeyIdxRef.current.clear()
		nodeArr.forEach((n: go.ObjectData, idx: number) => {
			mapNodeKeyIdxRef.current.set(n?.key, idx)
		})
	}

	/**
	 * Update map of link keys to their index in the array.
	 */
	const refreshLinkIndex = (linkArr: Array<go.ObjectData>) => {
		mapLinkKeyIdxRef.current.clear()
		linkArr.forEach((l: go.ObjectData, idx: number) => {
			mapLinkKeyIdxRef.current.set(l?.key, idx)
		})
	}

	/**
	 * Handle any relevant DiagramEvents, in this case just selection changes.
	 * On ChangedSelection, find the corresponding data and set the selectedData state.
	 * @param e a GoJS DiagramEvent
	 */
	const handleDiagramChange = (e: go.DiagramEvent) => {
		const name = e.name

		switch (name) {
			case 'ChangedSelection': {
				const sel = e.subject.first()
				if (sel) {
					if (sel instanceof go.Node) {
						const idx = mapNodeKeyIdxRef.current.get(sel.key)
						if (idx !== undefined && idx >= 0) {
							const nd = nodeDataArray[idx]
							dispatch(
								changeInspected({ data: nd, nodeId: node.id, type: 'node' }),
							)
						}
					} else if (sel instanceof go.Link) {
						const idx = mapLinkKeyIdxRef.current.get(sel.key)
						if (idx !== undefined && idx >= 0) {
							const ld = linkDataArray[idx]
							dispatch(
								changeInspected({ data: ld, nodeId: node.id, type: 'link' }),
							)
						}
					}
				} else {
					dispatch(
						changeInspected({ data: null, nodeId: node.id, type: 'diagram' }),
					)
				}
				break
			}
			default:
				break
		}
	}

	/**
	 * Handle GoJS model changes, which output an object of data changes via Model.toIncrementalData.
	 * This method iterates over those changes and updates state to keep in sync with the GoJS model.
	 * @param diagramModifiedValues a JSON-formatted string
	 */
	const handleModelChange = useCallback(
		(
			diagramModifiedValues: go.IncrementalData,
			panelProperties: DiagramProperties,
		) => {
			const { modifiedNodeData, insertedLinkKeys, modifiedLinkData } =
				diagramModifiedValues || {}

			console.log('diagramModifiedValues', diagramModifiedValues)

			const modifiedNodeMap = new Map<go.Key, go.ObjectData>()
			const modifiedLinkMap = new Map<go.Key, go.ObjectData>()

			// Handle modified nodes
			if (modifiedNodeData) {
				console.log('modifiedNodeData', modifiedNodeData)
				modifiedNodeData.forEach((modifiedNode: go.ObjectData) => {
					modifiedNodeMap.set(modifiedNode.key, modifiedNode)
					const nodeIndex = mapNodeKeyIdxRef.current.get(modifiedNode.key)
					if (nodeIndex !== undefined && nodeIndex >= 0) {
						dispatch(
							nodeModified({ data: modifiedNode, nodeId: node.id, nodeIndex }),
						)
						saveDebounced()
						// if (selectedData && selectedData.key === nd.key) {
						// 	dispatch(changeInspected(nd))
						// }
					}
				})
			}

			if (panelProperties) {
				console.log('useDiagramChangeHandler: panelProperties', panelProperties)
				dispatch(diagramPropUpdated({ nodeId: node.id, data: panelProperties }))
				// saveDebounced()
			}

			// Handle modified links
			if (modifiedLinkData) {
				modifiedLinkData.forEach((ld: go.ObjectData) => {
					modifiedLinkMap.set(ld.key, ld)
					const idx = mapLinkKeyIdxRef.current.get(ld.key)
					if (idx !== undefined && idx >= 0) {
						// dispatch(modifyLink(idx, ld))
						// if (selectedData && selectedData.key === ld.key) {
						// 	dispatch(changeInspected(ld))
						// }
					}
				})
			}

			// Handle inserted links
			if (insertedLinkKeys) {
				insertedLinkKeys.forEach((key: go.Key) => {
					const ld = modifiedLinkMap.get(key)
					const idx = mapLinkKeyIdxRef.current.get(key)
					if (ld && idx === undefined) {
						mapLinkKeyIdxRef.current.set(ld.key, linkDataArray.length)
						// dispatch(insertLink(ld))
					}
				})
			}

			dispatch(skipSet({ nodeId: node.id, data: true })) // the GoJS model already knows about these updates
		},
		[dispatch, linkDataArray.length, node.id, saveDebounced],
	)

	// useEffect to run the refresh functions when nodeDataArray or linkDataArray changes
	useEffect(() => {
		refreshNodeIndex(nodeDataArray)
	}, [nodeDataArray])

	useEffect(() => {
		refreshLinkIndex(linkDataArray)
	}, [linkDataArray])

	useEffect(() => {
		dispatch(linksParsed({ nodeId: node.id, data: deepDiagram }))
	}, [deepDiagram, dispatch, node.id])

	return (
		<DiagramProvider isEditMode={editMode} node={node}>
			<DiagramWrapper
				nodeDataArray={nodeDataArray}
				linkDataArray={linkDataArray}
				modelData={modelData}
				skipsDiagramUpdate={skipsDiagramUpdate}
				onDiagramEvent={handleDiagramChange}
				onModelChange={handleModelChange as OnModelChange}
			/>
		</DiagramProvider>
	)
}
