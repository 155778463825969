import { useEffect, useMemo, useRef, useState } from 'react'
import { createTemplate, getTemplate, updateTemplate } from 'src/endpoints'

import { Modal, Tabs } from '@/components'
import Button from '@/components/Button/Button'
import { ModalForm } from '@/components/Modal/ModalForm'
import { TabProps } from '@/components/Tabs'
import { Form } from '@/components/UberForm'
import { useApi, useApiRequest } from '@/endpoints/hooks'
import { TemplateDto } from '@/endpoints/models'
import { TemplateTab } from '@/enums'
import { useAppContext, useAppSelector } from '@/hooks'

import { lockTemplate, pushTemplate, unlockTemplate } from '../api.ts'
import { TemplateForm } from './TemplateForm'
import { TemplateModalProps } from './types'

export const TemplateModal = ({
	template,
	onSave,
	onClose,
	textAreaHeight,
}: TemplateModalProps) => {
	const { t } = useAppContext()
	const request = useApiRequest()
	const refForm = useRef<Form<TemplateDto>>(null)
	const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false)
	const { token, user } = useAppSelector((state) => state.auth)
	const templateDetail = useApi(getTemplate(template?.id as number))

	useEffect(() => {
		const handleEsc = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				setIsConfirmModalVisible(true)
			}
		}
		document.addEventListener('keypress', handleEsc)

		return () => {
			document.removeEventListener('keypress', handleEsc)
		}
	}, [])

	const handleClose = () => {
		setIsConfirmModalVisible(true)
	}

	const handleConfirmClose = (confirm: boolean) => {
		setIsConfirmModalVisible(false)
		if (confirm) {
			onClose()
		}
	}

	const handleSubmit = async (form: Partial<TemplateDto>) => {
		const {
			data,
			description,
			technologyId,
			comment,
			stereotypeId,
			objectSettingsId,
			structureType,
			formatType,
			fileExtension,
		} = form

		const payload = {
			data,
			description,
			technology: {
				id: technologyId,
			},
			stereotype: {
				id: stereotypeId,
			},
			objectSettings: objectSettingsId ? { id: objectSettingsId } : null,
			comment,
			structureType,
			formatType,
			fileExtension,
		}

		const action =
			template && template.id
				? updateTemplate(template.id, payload)
				: createTemplate(payload)

		const result = await request(action)

		if (result) {
			templateDetail.invalidate()
			onSave()
			onClose()
		}
	}

	const saveHandler = async () => {
		if (!template?.id) return

		await pushTemplate(template?.id, token)
		templateDetail.invalidate()
		onSave()
		onClose()
	}

	const handleLock = async () => {
		await lockTemplate(template?.id as number, token)
		templateDetail.invalidate()
		onSave()
		onClose()
	}
	const handleUnlock = async () => {
		await unlockTemplate(template?.id as number, token)
		templateDetail.invalidate()
		onSave()
		onClose()
	}

	const formData = useMemo(
		() => templateDetail?.data || ({} as TemplateDto),
		[templateDetail],
	)

	const initialValues = useMemo(
		() => ({
			technologyId: formData.technology?.id,
			stereotypeId: formData.stereotype?.id,
			structureType: formData.structureType,
			comment: formData.comment,
			description: formData.description,
			fileExtension: formData.fileExtension,
			data: formData.data,
			formatType: formData.formatType,
			objectSettingsId: formData.objectSettings?.id,
			version: formData.version,
		}),
		[formData],
	)

	const tabs = useMemo((): TabProps[] => {
		return [
			{
				id: TemplateTab.Form,
				title: 'Form',
				content: (
					<TemplateForm
						formData={formData}
						template={template}
						textAreaHeight={textAreaHeight}
					/>
				),
			},
		]
	}, [])

	const modalHeader = () => (
		<>{template ? t('TEMPLATE_UPDATE') : t('TEMPLATE_NEW')}</>
	)

	const isLockedByCurrentUser =
		!template?.lockedBy || user?.id === template?.lockedBy?.id

	useEffect(() => {
		refForm.current?.setValues(initialValues)
	}, [initialValues])

	return (
		<>
			<ModalForm<TemplateDto>
				open
				onClose={handleClose}
				contentStyle={{
					width: 1000,
					minWidth: 1000,
				}}
				bodyStyle={{
					display: 'flex',
					flexDirection: 'column',
				}}
				resizable
				initialValues={initialValues}
				hasLockButton={isLockedByCurrentUser}
				hasPushButton
				formRef={refForm}
				header={modalHeader}
				onSubmit={handleSubmit}
				onLock={handleLock}
				onUnlock={handleUnlock}
				template={template}
				saveHandler={saveHandler}
			>
				<Tabs tabs={tabs} />
			</ModalForm>
			{isConfirmModalVisible && (
				<Modal
					bodyStyle={{ alignItems: 'center', display: 'flex' }}
					contentStyle={{ height: '230px' }}
					header={<h1 className="text-center">Confirm close</h1>}
					open={isConfirmModalVisible}
					footer={() => (
						<section className="w-full flex gap-4 justify-end">
							<Button schema="danger" onClick={() => handleConfirmClose(true)}>
								{'CLOSE'}
							</Button>
							<Button
								schema="default"
								onClick={() => handleConfirmClose(false)}
							>
								{t('CANCEL')}
							</Button>
						</section>
					)}
				>
					<p>{t('CONFIRM_CLOSE_MESSAGE')}</p>
				</Modal>
			)}
		</>
	)
}
