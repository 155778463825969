import { faExchangeAlt } from '@fortawesome/free-solid-svg-icons'
import { uniqBy } from 'lodash'
import React, { useCallback, useMemo, useRef } from 'react'

import { Button, Loader } from '@/components'
import { TabProps } from '@/components/Tabs/Tabs'
import { Form } from '@/components/UberForm'
import { useTabContext } from '@/context/TabContext/TabContext'
import { HistoryTableData, TableData } from '@/endpoints/schemas'
import { TableMode, TableTab } from '@/enums'
import {
	useAppContext,
	useAppDispatch,
	useAppStore,
	useDebounceCallback,
} from '@/hooks'
import { TableDetailTabs } from '@/pages/User/pages/Home/pages/TableDetail/TableDetailTabs'
import {
	initTable,
	saveTable,
	selectTableTab,
	syncTableField,
	updateTable,
} from '@/store/modules/table/actions'
import { UpdateDeepPartial } from '@/store/utils'
import { useDeletedDomains } from '@/utils/domain'

import { useDetailTabContext } from '../../components/DetailTab/context/DetailTabContext'
import { EditableNodeActions } from '../../components/EditableNodeActions/EditableNodeActions'
import { Title } from '../../components/Title/Title'
import { TitleLeftContent } from '../../components/TitleLeftContent'
import { useNodeInit } from '../../hooks/useNodeInit'
import { useHandleModeSwitch } from './hooks/useHandleModeSwitch'
import { useUpdateNaming } from './hooks/useUpdateNaming'
import { RightButtons, Switcher } from './styles'
import {
	getModeForm,
	getTableMode,
	getTableName,
	getUnsyncedFields,
} from './utils'

const TableDetailComponent = () => {
	const { t } = useAppContext()
	const { onSaveError } = useTabContext()
	const dispatch = useAppDispatch()

	//FIXME: first time editmode is false
	const {
		state: { node, systemNodeId, editMode },
	} = useDetailTabContext()

	const tables = useAppStore((state) => state.table.tables)
	const table = tables[node.id]
	const mode = table ? table.mode : TableMode.TABLE
	const objectTypes = table?.form.objectSettings
	const domains = useDeletedDomains(systemNodeId, table)

	const refForm = useRef<Form<TableData>>(null)

	/** Update data in client from BE */
	const reloadTableData = useCallback(() => {
		dispatch(initTable({ nodeId: node.id, editMode, mode, force: true }))
	}, [dispatch, editMode, mode, node.id])

	useNodeInit(mode)

	//To stereotypeColumns add history columns (flattened) (if TableMode.HISTORY) and sort all together
	const technicalColumns = useMemo(
		() =>
			uniqBy(
				(table?.stereotypeColumns || []).concat(
					table?.historyColumns && mode === TableMode.HISTORY
						? table.historyColumns
						: [],
				),
				(column) => column.code,
			).sort((a, b) => a.order - b.order),
		[table, mode],
	)

	const [unsyncedFields, modeForm] = useMemo(() => {
		const unsyncedFields = getUnsyncedFields(mode, table?.form)
		const modeForm = getModeForm(mode, table?.form, unsyncedFields)

		return [unsyncedFields, modeForm]
	}, [mode, table])

	const handleSave = useDebounceCallback(async () => {
		if (!table || !editMode) {
			return
		}

		try {
			await dispatch(saveTable(node))
		} catch (e) {
			onSaveError(e)
		}
	}, 1000)

	const handleEdit = async () => {
		await dispatch(initTable({ nodeId: node.id, editMode: true, mode }))
	}

	const handleCancel = async () => {
		await dispatch(initTable({ nodeId: node.id, editMode: false, mode }))
	}

	const handleChange = useCallback(
		(data: UpdateDeepPartial<TableData>) => {
			if (data.hasHistoryTable !== undefined) {
				if (!data.historyTable) {
					data.historyTable = {}
				}
			}

			dispatch(updateTable(node, data, mode))
			handleSave()
		},
		[editMode, dispatch, node, mode, handleSave],
	)

	const handleTabChange = (tab: TabProps) => {
		dispatch(selectTableTab(node, tab.id as TableTab))
	}

	const handleModeSwitch = useHandleModeSwitch(mode, node, editMode, table)
	const updateNaming = useUpdateNaming(node, table)

	const beforePush = useCallback(async () => {
		if (table?.form.hasHistoryTable) {
			await updateNaming(TableMode.HISTORY)
		}

		if (table?.form.hasReferenceTable) {
			await updateNaming(TableMode.REFERENCE)
		}

		try {
			await dispatch(saveTable(node))
		} catch (e) {
			onSaveError(e)
		}
	}, [table, updateNaming, dispatch, node, onSaveError])

	const handleSync = useCallback(
		(field: keyof HistoryTableData) => {
			dispatch(syncTableField(node, field, mode))

			refForm.current?.setValues({
				[field]: table?.form[field],
			})

			handleChange({})
		},
		[dispatch, handleChange, mode, node],
	)

	if (!table) {
		return <Loader loaded={false} />
	}

	return (
		<>
			<Title
				type={node.type}
				title={getTableName(mode, table)}
				editMode={editMode}
				leftContent={<TitleLeftContent node={node} />}
				rightContent={
					<RightButtons>
						<EditableNodeActions
							node={node}
							editMode={editMode}
							dirty={table.dirty}
							onEdit={handleEdit}
							onCancel={handleCancel}
							beforePush={beforePush}
							afterPush={reloadTableData}
							contentCustom={
								<>
									{(table.form.hasHistoryTable ||
										table.form.hasReferenceTable) && (
										<Switcher>
											<Button icon={faExchangeAlt} onClick={handleModeSwitch}>
												{getTableMode(mode, table.form.hasHistoryTable, t)}
											</Button>
										</Switcher>
									)}
								</>
							}
						/>
					</RightButtons>
				}
			/>

			<TableDetailTabs
				domains={domains}
				editMode={editMode}
				handleChange={handleChange}
				handleSync={handleSync}
				handleTabChange={handleTabChange}
				mode={mode}
				modeForm={modeForm}
				node={node}
				objectTypes={objectTypes}
				refForm={refForm}
				reloadTableData={reloadTableData}
				systemNodeId={systemNodeId}
				table={table}
				technicalColumns={technicalColumns}
				unsyncedFields={unsyncedFields}
			/>
		</>
	)
}

export const TableDetail = React.memo(TableDetailComponent)
