export const downloadImage = (image: HTMLImageElement, fileName: string) => {
	const link = document.createElement('a')
	link.href = image.src
	link.download = fileName
	link.dispatchEvent(new MouseEvent('click'))
}

export const downloadSvg = (svg: SVGElement, fileName: string) => {
	const link = document.createElement('a')
	const svgData = new XMLSerializer().serializeToString(svg)
	const blob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' })
	const url = URL.createObjectURL(blob)
	link.href = url
	link.download = fileName
	link.dispatchEvent(new MouseEvent('click'))
}
