import {
	faChevronLeft,
	faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

import { useAppContext, useAppSelector } from '@/hooks'

import {
	DiagramPanel,
	OpenButton,
	OpenButtonIcon,
	OpenButtonText,
	Title,
	ToggleButton,
} from '../styles'
import { DiagramPropertiesPanelProps } from '../types'
import { DiagramProperties } from './DiagramProperties'
import { LinkProperties } from './LinkProperties'
import { NodeProperties } from './NodeProperties'

export const DiagramPropertiesPanel = ({
	diagramRef,
	saveProperties,
	nodeId,
}: DiagramPropertiesPanelProps) => {
	const { t } = useAppContext()
	const [isHidden, setIsHidden] = useState(true)
	const [propertiesTitle, setPropertiesTitle] = useState(
		t('DIAGRAM_PROPERTIES'),
	)

	const { inspector } = useAppSelector(
		(state) => state.diagram.diagrams[nodeId as number].form.graph,
	)

	const propertiesWidth = 300

	const toggleVisibility = () => {
		setIsHidden((prev) => !prev)
	}

	const handlePropertiesTitle = (title: string) => {
		setPropertiesTitle(title)
	}

	const renderPropertyPanel = () => {
		switch (inspector?.type) {
			case 'node':
				return (
					<NodeProperties
						diagramRef={diagramRef}
						propertiesWidth={propertiesWidth}
						handlePropertiesTitle={handlePropertiesTitle}
						nodeId={nodeId as number}
					/>
				)

			case 'link':
				return (
					<LinkProperties
						diagramRef={diagramRef}
						propertiesWidth={propertiesWidth}
						handlePropertiesTitle={handlePropertiesTitle}
						nodeId={nodeId as number}
					/>
				)

			default:
				return (
					<DiagramProperties
						handlePropertiesTitle={(title: string) =>
							handlePropertiesTitle(title)
						}
						diagramRef={diagramRef}
						saveProperties={(values) => saveProperties(values)}
						nodeId={nodeId as number}
					/>
				)
		}
	}

	return (
		<>
			{isHidden && (
				<OpenButton as="button" onClick={toggleVisibility} role="button">
					<OpenButtonText>Properties</OpenButtonText>
					<OpenButtonIcon>
						<FontAwesomeIcon icon={faChevronLeft} color="#888" />
					</OpenButtonIcon>
				</OpenButton>
			)}
			{!isHidden && (
				<DiagramPanel>
					<Title onClick={toggleVisibility}>
						<ToggleButton>
							<FontAwesomeIcon icon={faChevronRight} color="#888" />
						</ToggleButton>
						{propertiesTitle}
					</Title>
					{renderPropertyPanel()}
				</DiagramPanel>
			)}
		</>
	)
}
