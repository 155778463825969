import { Cell } from '@tanstack/react-table'
import { useMemo } from 'react'

import { ItemPropertyType } from '@/enums'
import { useAppContext } from '@/hooks'
import { Item, TableColumnProps } from '@/types'

import { RowExpander } from './RowExpander'

export const useDpStructureTableColumns = (
	envCode: string | undefined,
): TableColumnProps<Item>[] => {
	const { t } = useAppContext()

	return useMemo(() => {
		return [
			{
				field: 'expander',
				label: 'Expand',
				cell: ({ row }: Cell<any, any>) => {
					return <RowExpander row={row} envCode={envCode} />
				},
				type: ItemPropertyType.CUSTOM_JSX,
				width: 50,
			},
			{
				field: 'name',
				label: t('TABLE_COLUMNS_NAME'),
				type: ItemPropertyType.TEXT,
				autoWidth: true,
				disabled: true,
			},
			{
				field: 'code',
				label: t('TABLE_COLUMNS_CODE'),
				type: ItemPropertyType.GENERATED_CODE,
				autoWidth: true,
				disabled: true,
				canGenerateCodeFieldName: true,
			},
			{
				field: 'type',
				label: 'Type',
				type: ItemPropertyType.TEXT,
				width: 100,
				disabled: true,
			},
			{
				field: 'fullPath',
				label: 'Path',
				type: ItemPropertyType.TEXT,
				width: 250,
				disabled: true,
			},
		]
	}, [envCode, t])
}
