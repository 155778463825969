import { useCallback, useMemo } from 'react'

import { StructureObjectDto } from '@/endpoints/models'
import { TableMode } from '@/enums'
import { useAppDispatch } from '@/hooks'
import { initTable } from '@/store/modules/table/actions'
import { OpenedTableData } from '@/store/modules/table/types'

import { useUpdateNaming } from './useUpdateNaming'

export const useHandleModeSwitch = (
	mode: TableMode,
	node: StructureObjectDto,
	editMode: boolean,
	table?: OpenedTableData,
) => {
	const dispatch = useAppDispatch()

	const modeUpdate = useMemo(() => {
		const target =
			table && table.form.hasHistoryTable
				? TableMode.HISTORY
				: TableMode.REFERENCE

		const modeUpdate = mode === target ? TableMode.TABLE : target

		return modeUpdate
	}, [mode, table])

	const updateNaming = useUpdateNaming(node, table)

	const handleModeSwitch = useCallback(async () => {
		await dispatch(initTable({ nodeId: node.id, editMode, mode: modeUpdate }))

		if (editMode) {
			updateNaming(modeUpdate)
		}
	}, [modeUpdate, node, editMode, dispatch, updateNaming])

	return handleModeSwitch
}
