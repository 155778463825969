import { LinkRoutingType } from '@/enums'

export const getInitialDiagramData = (name: string, code = '') => ({
	name,
	code,
	graph: {
		nodeDataArray: [],
		linkDataArray: [],
		modelData: {},
		skipsDiagramUpdate: false,
		properties: {
			grid: {
				isVisible: true,
				cellSize: 26,
				lineColors: {
					intervalHorizontal: '#2e3838',
					intervalVertical: '#332e2e',
					vertical: '#f7e9e9',
					horizontal: '#d5c8df',
				},
			},
			displayOptions: {
				tableName: true,
				tableCode: false,
				tableColumns: true,
				pkColumnsName: true,
				pkColumnsCode: true,
				columnsName: true,
				columnsCode: true,
				comments: true,
				description: true,
			},
			node: {
				header: {
					color: '#7e99a0',
				},
				body: {
					color: '#feffd1',
				},
			},
			links: {
				color: '',
				routingType: LinkRoutingType.Orthogonal,
			},
		},
	},
})
