import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { HTMLProps, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { Portal } from '@/components'
import { nextFrame } from '@/utils/async'

type ContextMenuProps = {
	children: React.ReactNode
	onClose?: () => void
	opened: boolean
	x: number
	y: number
}

const positionHide = -9999999

export const ContextMenu = ({
	opened,
	x,
	y,
	onClose,
	children,
}: ContextMenuProps) => {
	const [show, setShow] = useState(false)

	const [position, setPosition] = useState({
		x,
		y,
	})

	const refMenu = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (onClose) {
			document.addEventListener('click', onClose, false)
			document.addEventListener('contextmenu', onClose, true)

			return () => {
				document.removeEventListener('click', onClose)
				document.removeEventListener('contextmenu', onClose)
			}
		}
	}, [onClose])

	// calculate position to be inside window (no overflow)
	useEffect(() => {
		setShow(false)

		const call = async () => {
			await nextFrame()

			if (refMenu.current && opened) {
				const differenceY =
					y +
					refMenu.current.clientHeight -
					(window.innerHeight + window.scrollY)

				const differenceX =
					x + refMenu.current.clientWidth - (window.innerWidth + window.scrollX)

				setPosition({
					x: differenceX > 0 ? x - differenceX : x,
					y: differenceY > 0 ? y - differenceY : y,
				})

				setShow(true)
			}
		}

		call()
	}, [
		opened,
		refMenu,
		y,
		x,
		window.innerHeight,
		window.scrollY,
		window.scrollX,
		window.innerWidth,
	])

	return (
		<Portal>
			<Menu
				ref={refMenu}
				role="menu"
				$opened={opened}
				$mouseX={show ? position.x : positionHide}
				$mouseY={show ? position.y : positionHide}
			>
				{children}
			</Menu>
		</Portal>
	)
}

const Menu = styled.div<{ $mouseX: number; $mouseY: number; $opened: boolean }>`
	position: absolute;
	display: ${(props) => (props.$opened ? 'initial' : 'none')};
	top: ${(props) => `${props.$mouseY}px`};
	left: ${(props) => `${props.$mouseX}px`};
`

export const ContextMenuItems = styled.div<{ $minWidth?: number }>`
	background: #fff;
	box-shadow: 1px 1px 5px #999;
	${(props) =>
		props.$minWidth &&
		css`
			min-width: ${props.$minWidth}px;
		`}
`

export const ContextMenuItem = ({
	children,
	icon,
	color = 'gray',
	title,
	...htmlProps
}: {
	children?: React.ReactNode
	color?: string
	icon?: IconProp
	title?: string
} & Omit<HTMLProps<HTMLDivElement>, 'ref' | 'as'>) => {
	return (
		<ContextMenuItemCtn
			{...htmlProps}
			onClick={!htmlProps.disabled ? htmlProps.onClick : undefined}
		>
			{icon && (
				<ContextMenuIcon>
					<FontAwesomeIcon icon={icon} color={color} />
				</ContextMenuIcon>
			)}
			{title ? <span>{title}</span> : children}
		</ContextMenuItemCtn>
	)
}

export const ContextMenuItemCtn = styled.div<{ disabled?: boolean }>`
	padding: 8px 10px;
	user-select: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	:hover {
		background: ${(props) =>
			props.disabled ? 'initial' : props.theme.colors.layout.borderColor};
	}

	${(props) => css`
		opacity: ${props.disabled ? 0.5 : 1};
		cursor: initial;
	`}
`

export const ContextMenuIcon = styled.div`
	width: 24px;
`
