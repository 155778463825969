import { StructureDto, StructureObjectDto } from '@/endpoints/models'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { AddModal } from '@/pages/User/pages/Home/components/AddModal'
import { DeleteModal } from '@/pages/User/pages/Home/components/DeleteModal'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { openOrCloseAddModal, openOrCloseDeleteModal } from '@/store'

export const DiagramModals = () => {
	const {
		state: { node },
	} = useDetailTabContext()

	const dispatch = useAppDispatch()

	const { isAddModalOpen, isDeleteModalOpen } = useAppSelector(
		(state) => state.diagram,
	)

	const parentNode = useAppSelector(
		(state) => state.node.nodes[node.parentStructureId as number],
	)

	return (
		<>
			<DeleteModal
				node={node}
				onClose={() => dispatch(openOrCloseDeleteModal(false))}
				isDiagramAddModal
				isOpen={isDeleteModalOpen}
			/>

			<AddModal
				node={node}
				type={StructureObjectDto.TypeEnum.TABLE}
				onClose={() => dispatch(openOrCloseAddModal(false))}
				isDiagramAddModal
				parentNode={parentNode as StructureDto}
				isOpen={isAddModalOpen}
			/>
		</>
	)
}
