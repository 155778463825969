import { WriteMappingData } from '@/endpoints/schemas'

export interface OpenedWriteMappingData {
	dirty: boolean
	form: WriteMappingDataForm
	original: WriteMappingDataForm
	parsedEditMode: boolean
	stereotypeId: number
	tab: WriteMappingTab
}

export type WriteMappingDataForm = Partial<WriteMappingData>

export enum WriteMappingTab {
	Columns = 'Columns',
	Dependencies = 'Dependencies',
	Deployments = 'Deployments',
	Others = 'Others',
	Overview = 'Overview',
	Preview = 'Preview',
	Sources = 'Sources',
	Validation = 'Validation',
	Versions = 'Versions',
}
