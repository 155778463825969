import styled from 'styled-components'

import { TabProps, Tabs } from '@/components/Tabs/Tabs'
import { StructureDto } from '@/endpoints/models'
import { useAppContext } from '@/hooks'
import { VersionsTab } from '@/pages/User/pages/Home/components/VersionsTab/VersionsTab'
import {
	OpenedReadMappingData,
	ReadMappingDataForm,
	ReadMappingTab,
} from '@/store/modules/readMapping/types'
import { UpdateDeepPartial } from '@/store/utils'

import {
	Dependencies,
	Deployments,
	Preview,
	Validation,
} from '../../../components'
import { Columns, Others, Overview, Sources } from '../pages'

type ReadMappingTabsProps = {
	data: OpenedReadMappingData
	editMode: boolean
	node: StructureDto
	onChange: (v: UpdateDeepPartial<ReadMappingDataForm>) => void
	onTabChange: (tab: TabProps) => void
	systemNodeId: number
}

export const ReadMappingTabs = ({
	data,
	node,
	editMode,
	systemNodeId,
	onTabChange,
	onChange,
}: ReadMappingTabsProps) => {
	const { t } = useAppContext()

	return (
		<GrowTabs
			onChange={onTabChange}
			selectedTabId={data.tab}
			tabs={[
				{
					id: ReadMappingTab.Overview,
					title: t('TAB_OVERVIEW'),
					content: (
						<Overview
							key={ReadMappingTab.Overview}
							onChange={onChange}
							data={data}
							editMode={editMode}
							systemNodeId={systemNodeId}
							node={node}
						/>
					),
				},
				{
					id: ReadMappingTab.Columns,
					title: t('TAB_COLUMN_MAPPINGS'),
					content: (
						<Columns
							key={ReadMappingTab.Columns}
							node={node}
							data={data}
							editMode={editMode}
							onChange={onChange}
						/>
					),
				},
				{
					id: ReadMappingTab.Sources,
					title: t('TAB_SOURCES'),
					content: (
						<Sources
							key={ReadMappingTab.Sources}
							data={data}
							editMode={editMode}
							onChange={onChange}
						/>
					),
				},

				{
					id: ReadMappingTab.Others,
					title: t('TAB_OTHERS'),
					content: (
						<Others
							key={ReadMappingTab.Others}
							data={data}
							editMode={editMode}
							onChange={onChange}
						/>
					),
				},

				{
					id: ReadMappingTab.Preview,
					title: t('TAB_PREVIEW'),
					content: <Preview key={ReadMappingTab.Preview} node={node} />,
				},
				{
					id: ReadMappingTab.Deployments,
					title: t('TAB_DEPLOYMENTS'),
					content: (
						<Deployments
							node={node}
							key={ReadMappingTab.Deployments}
							editMode={editMode}
						/>
					),
				},
				{
					id: ReadMappingTab.Validation,
					title: t('TAB_VALIDATION'),
					content: <Validation key={ReadMappingTab.Validation} node={node} />,
				},
				{
					id: ReadMappingTab.Dependencies,
					title: t('DEPENDENCIES'),
					content: (
						<Dependencies
							key={ReadMappingTab.Dependencies}
							node={node}
							editMode={editMode}
						/>
					),
				},
				{
					id: ReadMappingTab.Versions,
					title: t('VERSIONS'),
					content: <VersionsTab />,
				},
			]}
		/>
	)
}

const GrowTabs = styled(Tabs)`
	flex-grow: 1;
	overflow: auto;
	min-height: 0;
`
