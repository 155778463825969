import { faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { TemplateListDto } from '@/endpoints/models'
import { useAppContext, useAppStore } from '@/hooks'

interface TemplateLockStatusProps {
	template: TemplateListDto
}

export const TemplateLockIcon = ({ template }: TemplateLockStatusProps) => {
	console.log(template, 'templateIcon')
	const { user } = useAppStore((state) => state.auth)

	const { t } = useAppContext()
	const isLockedBy = template?.lockedBy?.id
	const currentUserId = user?.id
	const isLockedByCurrentUser = isLockedBy === currentUserId

	if (!isLockedBy) {
		return null
	}

	return (
		<div key={template.id} className="w-full flex justify-center">
			<FontAwesomeIcon
				title={t(isLockedByCurrentUser ? 'LOCKED_BY_YOU' : 'LOCKED_BY_USER')}
				icon={faLock}
				color={isLockedByCurrentUser ? '#37b479' : '#ff4d4f'}
			/>
		</div>
	)
}
