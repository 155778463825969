import {
	faCircle,
	faTimes,
	IconDefinition,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
	EditIcon,
	TabHead,
	TabHeadClose,
	TabHeadText,
} from '@/pages/User/pages/Home/components/TabsDetailView/styles'

interface CustomTabHeadProps {
	editMode: boolean
	handleClick: () => void
	handleCloseClick: (e: React.MouseEvent) => void
	handleDblClick: () => void
	handleMouseDown: (e: React.MouseEvent) => void
	handleMouseUp: (e: React.MouseEvent) => void
	icon: IconDefinition
	isSelected: boolean
	isTemporary: boolean
	isVertical: boolean
	name: string | undefined
}
export const CustomTabHead = ({
	icon,
	name,
	isSelected,
	isTemporary,
	isVertical,
	handleClick,
	handleDblClick,
	handleMouseDown,
	handleMouseUp,
	handleCloseClick,
	editMode,
}: CustomTabHeadProps) => {
	return (
		<TabHead
			role="tab"
			aria-selected={isSelected}
			$isSelected={isSelected}
			$isTemporary={isTemporary}
			$isVertical={isVertical}
			onClick={handleClick}
			onDoubleClick={handleDblClick}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
		>
			<FontAwesomeIcon icon={icon} color="green" />
			<TabHeadText>
				{name}
				{editMode && <EditIcon icon={faCircle} />}
			</TabHeadText>
			<TabHeadClose role="button" onClick={handleCloseClick}>
				<FontAwesomeIcon icon={faTimes} color="#999" />
			</TabHeadClose>
		</TabHead>
	)
}
