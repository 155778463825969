import { createApi } from '@reduxjs/toolkit/query/react'

import { StereotypeDto, StereotypeTypeDto } from '@/endpoints/models'
import { baseQueryWithAuth } from '@/rtkQueries/utils'

interface StereotypesForTemplates {
	type?:
		| 'FOLDER'
		| 'TABLE'
		| 'TABLE_INTERFACE'
		| 'TABLE_HISTORY'
		| 'COLUMN'
		| 'MAPPING'
		| 'MAPPING_TYPE'
		| 'MAPPING_READ'
		| 'MAPPING_WRITE'
		| 'CONSTANT_DOMAIN'
		| 'VIEW'
		| 'WORKFLOW'
		| 'API_NODE'
		| 'API_NODE_ATTRIBUTE'
		| 'API_ROWSET'
		| 'QUEUE'
		| 'TABLE_COLUMN'
}

export const stereoTypeApiSlice = createApi({
	reducerPath: 'stereoTypeApiSlice',
	baseQuery: baseQueryWithAuth,
	endpoints: (builder) => ({
		getStereoTypes: builder.query<StereotypeTypeDto[], void>({
			query: () => 'stereotype-types',
		}),
		getStereotypesForTemplates: builder.query<
			StereotypeDto[],
			StereotypesForTemplates
		>({
			query: ({ type }) => ({
				url: 'stereotypes/templates',
				params: {
					type,
				},
			}),
		}),
	}),
})

export const { useGetStereoTypesQuery, useGetStereotypesForTemplatesQuery } =
	stereoTypeApiSlice
