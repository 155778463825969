import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import { Button } from '@/components'
import { FieldSet, Legend, Row } from '@/components/Layout'
import { useIsFieldHighlighted } from '@/components/UberForm'
import { SelectWithoutForm } from '@/components/UberForm/Input/Select/Select'
import { getObjectSettingsByType } from '@/endpoints'
import { useApi } from '@/endpoints/hooks'
import { ObjectSettingsUpdateDto } from '@/endpoints/models'
import { IdCodeName } from '@/endpoints/schemas'
import { useAppContext } from '@/hooks'
import { FormValue } from '@/types'

// Circular dep issue
// const ItemsContainer = styled(Row)`
// 	padding-bottom: 0.25em;
// 	margin: 0;
// 	flex-direction: column;
// `
//
// const ItemContainer = styled(Row)`
// 	padding: 0.33em 0.5em;
// 	align-items: baseline;
// 	&:hover ${RemoveButton} {
// 		opacity: 1;
// 	}
// `

const ObjectSettingsControl = ({
	name,
	label,
	type,
	data,
	disabled,
	onChange,
}: {
	data?: IdCodeName[]
	disabled?: boolean
	label: string
	name: string
	onChange?: (newValue: IdCodeName[]) => void
	type: ObjectSettingsUpdateDto.StructureTypeEnum
}) => {
	const objectSettings = useApi(getObjectSettingsByType(type))
	const { t } = useAppContext()

	const handleAdd = (value: FormValue) => {
		const os = objectSettings?.data?.find((o) => o.code == value) || undefined

		if (os) {
			onChange?.([...(data || []), { id: os.id, code: os.code, name: os.name }])
		}
	}

	const handleRemove = (osId: number) => {
		onChange?.([...(data || []).filter((os) => os.id != osId)])
	}

	const isHighlighted = useIsFieldHighlighted(name, data)

	return (
		<>
			<FieldSetProject>
				<Legend $isHighlighted={isHighlighted}>{label}</Legend>
				{data && (
					<div>
						{data.map((os) => (
							<div key={os.code}>
								<RemoveButtonContainer>
									{!disabled && (
										<RemoveButton
											size="sm"
											schema="transparent"
											onClick={() => handleRemove(os.id as number)}
										>
											<FontAwesomeIcon
												title={t('REMOVE')}
												color="#d00"
												icon={faTimes}
											/>
										</RemoveButton>
									)}
								</RemoveButtonContainer>

								<Name>{os.name}</Name>
								<Code>{os.code}</Code>
							</div>
						))}
					</div>
				)}
				{!disabled && (
					<SelectWithoutForm
						options={(objectSettings.data || []).filter(
							(os) => !(data || []).some((o) => o.code == os.code),
						)}
						placeholder={t('ADD_OBJECT_SETTINGS')}
						labelKey="name"
						valueKey="code"
						loading={objectSettings.loading}
						onChange={handleAdd}
						isCompact
						allowEmpty
						value={data?.map((d) => d.code ?? '')}
					/>
				)}
			</FieldSetProject>
		</>
	)
}

const FieldSetProject = styled(FieldSet)`
	margin-top: 15px;
`

const RemoveButton = styled(Button)`
	padding: 0;
	opacity: 0;
	display: inline-block;
`

const RemoveButtonContainer = styled.span`
	width: 12px;
	display: inline-block;
`

const Code = styled.span`
	padding: 0 0.5em;
	font-size: 85%;
`

const Name = styled.span`
	padding-left: 0.25em;
`

export default ObjectSettingsControl
