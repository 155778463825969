import { ApiCollectionData } from '@/endpoints/schemas/api-collection'

export interface OpenedApiCollectionData {
	dirty: boolean
	form: ApiCollectionData
	original: ApiCollectionData
	parsedEditMode: boolean
	selected: {
		column: number | null
	}
}

export enum ApiCollectionTab {
	Columns = 'Columns',
	Dependencies = 'Dependencies',
	General = 'General',
	Validation = 'Validation',
	Versions = 'Versions',
}
