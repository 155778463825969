import { memo, useCallback, useMemo } from 'react'

import { TableAndProperties } from '@/components'
import { DomainDto, StructureDto } from '@/endpoints/models'
import { useAppContext } from '@/hooks'
import { createEmptyColumn } from '@/store/modules/apiNode/helpers'
import {
	ApiNodeDataForm,
	OpenedApiNodeData,
} from '@/store/modules/apiNode/types'
import { UpdateDeepPartial } from '@/store/utils'
import { useDomainTechnology } from '@/utils/domain'

import { useColumnsProperties } from './useColumnsProperties'
import { duplication } from './validation'

type ColumnsComponentProps = {
	data: OpenedApiNodeData
	domains: DomainDto[]
	node: StructureDto
	onChange: (data: UpdateDeepPartial<ApiNodeDataForm>) => void
	systemNodeId: number
}

const ColumnsComponent = ({
	node,
	data,
	systemNodeId,
	onChange,
	domains,
}: ColumnsComponentProps) => {
	const { t } = useAppContext()
	const { getDomainData } = useDomainTechnology(systemNodeId)
	const properties = useColumnsProperties({ domains })

	const handleChange = useCallback(
		(v: UpdateDeepPartial<ApiNodeDataForm>) => {
			if (v.columns) {
				Object.values(v.columns).forEach((c: any) => {
					if (c.domainId) {
						const d = domains.find((d) => d.id === c.domainId)
						const domainData = getDomainData(d)
						const colData = data.form.columns?.find((col) => col.id === c.id)

						// don't change domain data, when domain is same
						if (colData && colData.domainId === c.domainId) {
							return
						}

						if (d) {
							c.dataType = domainData?.dataType
						} else {
							c.dataType = undefined
						}
					}
				})
			}

			onChange(v)
		},
		[onChange, domains, getDomainData, data.form.columns],
	)

	const errors = useMemo(
		() => duplication(data.form.columns ?? [], t),
		[data.form.columns, t],
	)

	return (
		<>
			<TableAndProperties
				node={node}
				data={data.form}
				createEmpty={createEmptyColumn}
				itemsKey="columns"
				idCounterKey="columnsLastId"
				onChange={handleChange}
				properties={properties}
				tabKey="column"
				errors={errors}
			/>
		</>
	)
}

export const Columns = memo(ColumnsComponent)
