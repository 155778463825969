import { format, parseISO } from 'date-fns'

import { Table } from '@/components'
import { HistoryDto } from '@/endpoints/models'
import { useTableColumns } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { useGetHistoryListQuery } from '@/rtkQueries'
import { MainContentWrap } from '@/styles'

import { useVersionTableColumns } from './tableColumns'

export const VersionsTab = () => {
	const {
		state: { node },
	} = useDetailTabContext()

	const { data } = useGetHistoryListQuery({ structureId: node.id })

	const dataWithParsedDate = data?.map((item) => {
		const parsedDate = parseISO(item.created)
		return {
			...item,
			created: format(parsedDate, 'dd.MM.yyyy HH:mm'),
		}
	})

	const columns = useTableColumns({
		tableData: [data],
		properties: useVersionTableColumns(),
	})

	return (
		<MainContentWrap>
			<Table<HistoryDto>
				columns={columns}
				data={dataWithParsedDate as HistoryDto[]}
				maxWidth={1200}
				isEditMode={false}
				hasLastRowEdit={false}
				hasLeftAction={false}
				hasDnd={false}
			/>
		</MainContentWrap>
	)
}
