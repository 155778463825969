export const FOLDER_INIT = 'FOLDER_INIT'
export const FOLDER_UPDATE = 'FOLDER_UPDATE'
export const FOLDER_SAVE = 'FOLDER_SAVE'
export const ADD_NEW_DIAGRAM = 'ADD_NEW_DIAGRAM'
export const REMOVE_DIAGRAM = 'REMOVE_DIAGRAM'
export const DIAGRAM_UPDATE = 'DIAGRAM_UPDATE'
export const DIAGRAM_PROP_UPDATE = 'DIAGRAM_PROP_UPDATE'
export const DIAGRAM_NODE_PROP_UPDATE = 'DIAGRAM_NODE_PROP_UPDATE'
export const DIAGRAM_LINK_PROP_UPDATE = 'DIAGRAM_LINK_PROP_UPDATE'
export const ADD_NEW_DIAGRAM_NODE = 'ADD_NEW_DIAGRAM_NODE'
export const REMOVE_DIAGRAM_NODE = 'REMOVE_DIAGRAM_NODE'
