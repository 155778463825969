import {
	RadioGroup,
	RadioLabel,
	RoutingTypeContainer,
	RoutingTypeLabel,
} from '@/components/Diagram/styles'
import { TranslationHelper } from '@/context/Locale'
import { LinkRoutingType } from '@/enums'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'

export const RoutingTypeSelector = ({
	routingType,
	onChange,
	t,
}: {
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
	routingType: string
	t: TranslationHelper
}) => {
	const {
		state: { editMode: isEditMode },
	} = useDetailTabContext()
	return (
		<RoutingTypeContainer>
			<RoutingTypeLabel>{t('DIAGRAM_ROUTING_TYPE')}</RoutingTypeLabel>
			<RadioGroup>
				<RadioLabel>
					<input
						type="radio"
						name="diagram_routing_type"
						value={LinkRoutingType.Direct}
						checked={routingType === LinkRoutingType.Direct}
						onChange={onChange}
						disabled={!isEditMode}
					/>
					{t('DIAGRAM_ROUTING_TYPE_DIRECT')}
				</RadioLabel>
				<RadioLabel>
					<input
						className="mr-2"
						type="radio"
						name="diagram_routing_type"
						value={LinkRoutingType.Orthogonal}
						checked={routingType === LinkRoutingType.Orthogonal}
						onChange={onChange}
						disabled={!isEditMode}
					/>
					{t('DIAGRAM_ROUTING_TYPE_ORTHOGONAL')}
				</RadioLabel>
			</RadioGroup>
		</RoutingTypeContainer>
	)
}
