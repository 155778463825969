import React, { useCallback } from 'react'

import { ContextMenu } from '@/components'
import {
	ContextMenuItem,
	ContextMenuItems,
} from '@/components/ContextMenu/ContextMenu'
import { StructureDto } from '@/endpoints/models'
import { useAppContext, useAppDispatch } from '@/hooks'
import {
	closeCustomTab,
	closeTab,
	openTab,
	selectCustomTab,
	selectTab,
} from '@/store/modules/tab/actions'
import { OpenedTab } from '@/store/modules/tab/types'

import { TabProps } from '../types'
import { TabHeading } from './TabHeading'

export const Tab = ({
	node,
	tab,
	isSelected,
	isTemporary,
	onConfirm,
	onCloseOther,
	onCloseAll,
	isVertical,
}: TabProps) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()

	const closeItem = useCallback((tab: OpenedTab, node: StructureDto) => {
		tab.editMode ? onConfirm([node]) : dispatch(closeTab(node.id))
	}, [])

	const isCustomTab = tab.customTabProps?.customTabName
	const customTabId = isCustomTab && tab.customTabProps?.id

	const handleClick = useCallback(() => {
		if (isCustomTab) {
			return dispatch(selectCustomTab(customTabId as number))
		}

		return node && dispatch(selectTab(node))
	}, [node, tab])

	const handleDblClick = useCallback(() => {
		node && dispatch(openTab(node, false))
	}, [node])

	const handleMouseUp = useCallback(
		(e: React.MouseEvent) => {
			if (e.button === 1) {
				e.stopPropagation()
				node && closeItem(tab, node)
			}
		},
		[node, tab, closeItem],
	)

	const handleCloseClick = useCallback(
		(e: React.MouseEvent) => {
			e.stopPropagation()

			if (isCustomTab) {
				return dispatch(closeCustomTab(customTabId as number))
			}

			node && closeItem(tab, node)
		},
		[node, tab, closeItem],
	)

	const handleCloseOtherClick = useCallback(() => {
		onCloseOther(node as StructureDto)
	}, [onCloseOther, node])

	const contextMenu = useCallback(
		(opened: boolean, x: number, y: number) => (
			<ContextMenu opened={opened} x={x} y={y}>
				<ContextMenuItems $minWidth={150}>
					<ContextMenuItem onClick={handleCloseClick}>
						{t('CLOSE_TAB')}
					</ContextMenuItem>
					<ContextMenuItem onClick={handleCloseOtherClick}>
						{t('CLOSE_OTHER_TABS')}
					</ContextMenuItem>
					<ContextMenuItem onClick={onCloseAll}>
						{t('CLOSE_ALL_TABS')}
					</ContextMenuItem>
				</ContextMenuItems>
			</ContextMenu>
		),
		[handleCloseClick, onCloseAll],
	)

	return (
		<TabHeading
			contextMenu={contextMenu}
			node={node}
			isSelected={isSelected}
			isTemporary={isTemporary}
			isVertical={!!isVertical}
			handleClick={handleClick}
			handleDblClick={handleDblClick}
			handleMouseUp={handleMouseUp}
			tab={tab}
			handleCloseClick={handleCloseClick}
		/>
	)
}
