import { appConfig } from '@/config'

export const getNodeData = async (nodeId: number, token: string | null) => {
	try {
		const response = await fetch(`${appConfig.apiUrl}/structure/${nodeId}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})

		return await response.json()
	} catch (error) {
		console.error(error)
	}
}
