import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import {
	Button,
	Container,
	DialogWrapper,
	LightTable,
	Loader,
	Message,
	MessageModal,
} from '@/components'
import { Flex } from '@/components/Layout'
import { getEnvironmentsWithLastDeploymentPerObjectType } from '@/endpoints'
import { useApi } from '@/endpoints/hooks'
import {
	EnvironmentDeploymentDto,
	ObjectSettingsDto,
	ReleaseDto,
	StructureDto,
	StructureObjectDto,
} from '@/endpoints/models'
import { useAppContext, useAppDispatch, useAppStore } from '@/hooks'
import { findSystemNodeId } from '@/store/modules/node/helpers'
import { selectSystemTab, updateSystem } from '@/store/modules/system/actions'
import { SystemTab } from '@/store/modules/system/types'
import { openTab } from '@/store/modules/tab/actions'
import { LightTableColumn } from '@/types'

import { DdlModal } from './components/DdlModal'

type DeploymentsProps = { editMode: boolean; node: StructureDto }

export const Deployments = ({ node, editMode }: DeploymentsProps) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const nodes = useAppStore((state) => state.node.nodes)

	const environments = useApi(
		getEnvironmentsWithLastDeploymentPerObjectType(node.id),
	)

	const onReleaseRedirect = useCallback(
		async (release: ReleaseDto) => {
			const systemId = findSystemNodeId(node, nodes)
			const systemNode = nodes[systemId] as StructureObjectDto
			dispatch(selectSystemTab(systemNode, SystemTab.Releases))
			dispatch(updateSystem(systemNode, { selectedReleaseId: release.id! }))
			dispatch(openTab(systemNode, false))
		},
		[dispatch, node, nodes],
	)

	const model = useMemo(
		() =>
			[
				{
					field: 'objectType',
					title: t('OBJECT_TYPE'),
					width: 50,
					formatter: (v?: ObjectSettingsDto) => v?.name,
				},
				{
					field: 'name',
					title: t('ENVIRONMENT'),
					width: 50,
				},
				{
					field: 'lastDeploymentStructureHistoryVersion',
					title: t('DEPLOYED_VERSION'),
					width: 50,
					formatter: (v) =>
						v ? t('DEPLOYED_VERSION_INFO', [v as string]) : 'N/A',
				},
				{
					field: 'lastDeploymentAuthorCompositeName',
					title: t('DEPLOYED_BY'),
					width: 50,
					formatter: (v) => (v ? v : 'N/A'),
				},
				{
					field: 'lastRelease',
					title: t('LATEST_RELEASE'),
					width: 50,
					formatter: (v?: ReleaseDto) =>
						v?.name ? (
							<ReleaseRedirect onClick={() => onReleaseRedirect(v)}>
								<FontAwesomeIcon icon={faEdit} />
								&nbsp;
								{v.name}
							</ReleaseRedirect>
						) : (
							'N/A'
						),
				},
				{
					field: null,
					key: 'actions',
					width: 200,
					maxWidth: 200,
					formatter: (_, item) => (
						<Actions>
							<DialogWrapper
								dialog={(opened, onClose) =>
									opened && (
										<>
											<DdlModal
												preview
												environment={item}
												node={node}
												onClose={onClose}
											/>
										</>
									)
								}
							>
								{(onClick) => (
									<Button size="sm" onClick={onClick}>
										{t('PREVIEW_DLL')}
									</Button>
								)}
							</DialogWrapper>
							<DialogWrapper
								dialog={(opened, onClose) =>
									opened && (
										<>
											<DdlModal
												environment={item}
												node={node}
												onClose={onClose}
											/>
											{editMode && (
												<MessageModal
													title={t('DEPLOYED_UNPUSHED_CHANGES_WARN_TITLE')}
													type="warn"
													message={t('DEPLOYED_UNPUSHED_CHANGES_WARN')}
												/>
											)}
										</>
									)
								}
							>
								{(onClick) => (
									<Button size="sm" onClick={onClick}>
										{t('CREATE_DDL')}
									</Button>
								)}
							</DialogWrapper>
						</Actions>
					),
				},
			] as LightTableColumn<EnvironmentDeploymentDto>[],
		[t, onReleaseRedirect, node, editMode],
	)

	return (
		<Container>
			{environments.loading ? (
				<Loader loaded={false} />
			) : (
				<>
					{editMode && (
						<Message
							type="warn"
							message={t('DEPLOYED_UNPUSHED_CHANGES_WARN')}
						/>
					)}

					<LightTable
						keyField="key"
						isLoading={environments.loading}
						data={environments.data || []}
						columns={model}
					/>
				</>
			)}
		</Container>
	)
}

const Actions = styled(Flex)`
	button {
		margin-right: 10px;
	}
`

const ReleaseRedirect = styled(Flex)`
	cursor: pointer;
`
