import go from 'gojs'
import { Dispatch } from 'react'

import { TranslationHelper } from '@/context/Locale'
import { nodeRemoved, openOrCloseAddModal, skipSet } from '@/store'
import { deleteNodeThunk } from '@/store/slices/diagram/thunks'

const $ = go.GraphObject.make

export const createTableNodeContextMenu = (
	dispatch: Dispatch<any>,
	nodeDiagramId: number,
) => {
	return new go.Adornment('Vertical')
		.add(
			$('ContextMenuButton', $(go.TextBlock, 'Remove table'), {
				click: (_e, obj) => {
					const node = obj.part

					if (node) {
						dispatch(
							nodeRemoved({
								removedNodeId: node.data.key,
								nodeDiagramId,
							}),
						)
						dispatch(skipSet({ data: false, nodeId: nodeDiagramId }))
					} else {
						console.error('Node or Node data is not available:', node)
					}
				},
			}),
		)
		.add(
			$('ContextMenuButton', $(go.TextBlock, 'Delete table'), {
				click: (_e, obj) => {
					const node = obj.part

					if (node) {
						dispatch(deleteNodeThunk(node.data.key))
					} else {
						console.error('Node or Node data is not available:', node)
					}
				},
			}),
		)
}

export const createDiagramMenu = (
	goJsDiagram: go.Diagram,
	t: TranslationHelper,
	dispatch: Dispatch<any>,
) => {
	const $ = go.GraphObject.make

	goJsDiagram.contextMenu = $(
		'ContextMenu',
		$(
			'ContextMenuButton',
			{
				click: () => {
					dispatch(openOrCloseAddModal(true))
				},
			},
			$(go.TextBlock, t('DIAGRAM_ADD_TABLE')),
		),
	)
}
