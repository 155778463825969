import { ApiNodeColumn, ApiNodeData } from '@/endpoints/schemas/api-node'

import { MappingPermissionFlat } from '../mapping/types'

export interface OpenedApiNodeData {
	dirty: boolean
	form: ApiNodeDataForm
	graph: ApiNodeGraphData
	original: ApiNodeDataForm
	parsedEditMode: boolean
	selected: {
		column: number | null
	}

	tab: ApiNodeTab
}

export type ApiNodeGraphData = {
	offset: { x: number; y: number }
	selected?: number
	zoom: number
}

export type ApiNodeDataForm = Omit<
	Partial<ApiNodeData>,
	'objectPermissions' | 'columns'
> & {
	columns: ApiNodeColumn[]
	objectPermissions: MappingPermissionFlat[]
}

export type PropertyItemType = 'column' | 'index' | 'key'

export enum ApiNodeTab {
	ApiCollection = 'ApiCollection',
	Columns = 'Columns',
	Dependencies = 'Dependencies',
	Deployments = 'Deployments',
	General = 'General',
	Permissions = 'Permissions',
	Preview = 'Preview',
	ReadMappings = 'ReadMappings',
	Validation = 'Validation',
	Versions = 'Versions',
}
