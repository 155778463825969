import { useMemo } from 'react'

import { Tabs } from '@/components/Tabs/Tabs'
import { useAppContext, useAppDispatch } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { DiagramTab } from '@/pages/User/pages/Home/pages/DiagramDetail/components/enums'
import { selectTabInTab } from '@/store/modules/tab/actions'

import { DiagramGraph } from '../pages/DiagramGraph'

export const DiagramTabs = () => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const {
		state: { node },
	} = useDetailTabContext()

	const tabs = useMemo(
		() => [
			{
				id: DiagramTab.Overview,
				title: t('TAB_DIAGRAM'),
				content: <DiagramGraph />,
			},
		],
		[],
	)

	return (
		<Tabs
			onChange={(tab) => dispatch(selectTabInTab(node.id, tab.id))}
			selectedTabId={DiagramTab.Overview}
			tabs={tabs}
		/>
	)
}
