export enum ItemPropertyType {
	ACE_EDITOR,
	CHECKBOX,
	COLUMNS,
	COLUMNS_WITH_ORDER,
	CUSTOM_JSX,
	FOREIGN_KEY,
	GENERATED_CODE,
	NUMBER,
	OPTION,
	OPTIONS,
	REGEXP_VALIDATED,
	TEXT,
	TEXTAREA,
	SECRET,
}

export enum Position {
	Top,
	Left,
	Bottom,
	BottomLeft,
}

export enum TabName {
	DeploymentPackage = 'DeploymentPackage',
	Diagram = 'Diagram',
}

export enum DiagramNodeRelationship {
	DIRECT = 'DIRECT',
	REFERENCED = 'REFERENCED',
}

export enum LinkRoutingType {
	Direct = 'direct',
	Orthogonal = 'orthogonal',
}
