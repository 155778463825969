import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Diagram } from 'gojs'
import { useRef, useState } from 'react'
import {
	getAllSystemTechnologies,
	getAllWorkflowTechnologies,
	getObjectSettingsByType,
} from 'src/endpoints'

import { useDiagramContext } from '@/components/Diagram/context/DiagramContext'
import { ModalForm } from '@/components/Modal/ModalForm'
import { SelectFormField, TextFormField } from '@/components/UberForm'
import { useApi } from '@/endpoints/hooks'
import { StructureObjectDto } from '@/endpoints/models'
import { useAppContext, useAppDispatch } from '@/hooks'
import {
	getAction,
	getHeader,
} from '@/pages/User/pages/Home/components/AddModal/utils'
import { getInitialApiCollectionData } from '@/store/modules/apiCollection/helpers'
import { getInitialApiNodeData } from '@/store/modules/apiNode/helpers'
import { getInitialMappingData } from '@/store/modules/mapping/helpers'
import { getInitialQueueData } from '@/store/modules/queue/helpers'
import { getInitialReadMappingData } from '@/store/modules/readMapping/helpers'
import { getInitialSystemData } from '@/store/modules/system/helpers'
import { getInitialTableData } from '@/store/modules/table/helpers'
import { getInitialViewData } from '@/store/modules/view/helpers'
import { getInitialWorkflowData } from '@/store/modules/workflow/helpers'
import { getInitialWriteMappingData } from '@/store/modules/writeMapping/helpers'
import { generateCode } from '@/store/utils'
import { uniqueNameValidator } from '@/utils/validators'

import { AddModalProps, FormData, WriteMappingData } from '../../types'
import { AddModalWriteMapping } from '../AddModalWriteMapping'

export const AddModal = ({
	type,
	onClose,
	isDiagramAddModal,
	node,
	isOpen = true,
}: AddModalProps) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const inputRef = useRef(null)
	const systemTechnologies = useApi(getAllSystemTechnologies())
	const workflowTechnologies = useApi(getAllWorkflowTechnologies())
	const objectSettings = useApi(getObjectSettingsByType(type))
	const { diagram } = useDiagramContext()

	const isSystem = type === StructureObjectDto.TypeEnum.SYSTEM
	const isWriteMapping = type === StructureObjectDto.TypeEnum.MAPPING_WRITE

	const [writeMapping, setWriteMapping] = useState<WriteMappingData>({
		targetTableFullPath: '',
	})

	const onChangeWriteMapping = (value: WriteMappingData) =>
		setWriteMapping(value)

	const getDefaultObjectSettings = () => {
		const defaultOs = objectSettings?.data?.find((os) => os.defaultFlag)

		if (defaultOs) {
			return { id: defaultOs.id, code: defaultOs.code, name: defaultOs.name }
		}
	}

	const getNodeData = (name: string, code: string, form: Partial<FormData>) => {
		if (isSystem) {
			return getInitialSystemData(
				name,
				code,
				form.systemTechnologyId,
				form.workflowTechnologyId,
			)
		}

		if (type === StructureObjectDto.TypeEnum.TABLE) {
			const defaultOs = getDefaultObjectSettings()

			return getInitialTableData(name, code, defaultOs)
		}

		if (type === StructureObjectDto.TypeEnum.VIEW) {
			return getInitialViewData(name, code)
		}

		if (type === StructureObjectDto.TypeEnum.MAPPING) {
			const defaultOs = getDefaultObjectSettings()

			return getInitialMappingData(name, code, defaultOs)
		}

		if (type === StructureObjectDto.TypeEnum.MAPPING_READ) {
			return getInitialReadMappingData(name, code)
		}

		if (type === StructureObjectDto.TypeEnum.MAPPING_WRITE) {
			return getInitialWriteMappingData(
				name,
				code,
				form.targetTableId as number,
				writeMapping.targetTableFullPath,
			)
		}

		if (type === StructureObjectDto.TypeEnum.WORKFLOW) {
			return getInitialWorkflowData(name, code)
		}

		if (type === StructureObjectDto.TypeEnum.API_COLLECTION) {
			return getInitialApiCollectionData(name, code)
		}

		if (type === StructureObjectDto.TypeEnum.API_NODE) {
			return getInitialApiNodeData(name, code)
		}

		if (type === StructureObjectDto.TypeEnum.QUEUE) {
			const defaultOs = getDefaultObjectSettings()

			return getInitialQueueData(name, code, defaultOs)
		}

		return { name }
	}

	const handleSubmit = async (form: Partial<FormData>) => {
		const name = form.name as string
		const code = await dispatch(generateCode(name))
		const nodeData = getNodeData(name, code.result, form)

		const data = {
			type,
			data: JSON.stringify(nodeData),
		}

		const action = getAction(data, node, type, {
			diagram: diagram as Diagram,
			isDiagramAddModal,
			node,
		})

		await dispatch(action)
		onClose()
	}

	return (
		<ModalForm<FormData>
			saveTitle={t('CREATE')}
			saveIcon={faPlus}
			cancelTitle={t('CANCEL')}
			onSubmit={handleSubmit}
			open={isOpen}
			onClose={onClose}
			contentStyle={{
				width: '400px',
			}}
			header={getHeader(type, t)}
		>
			<TextFormField
				title={t('NAME')}
				name="name"
				required
				validators={[uniqueNameValidator(dispatch, undefined, node?.id)]}
				autoFocus
				enableAutocomplete={false}
				refInput={inputRef}
			/>

			{isSystem && (
				<>
					<SelectFormField
						title={t('SYSTEM_TECHNOLOGY')}
						name="systemTechnologyId"
						options={systemTechnologies.data || []}
						fieldIsLoading={systemTechnologies.reloading}
						valueKey="id"
						labelKey="name"
						required
						isNumeric
					/>

					<SelectFormField
						title={t('WORKFLOW_TECHNOLOGY')}
						name="workflowTechnologyId"
						options={workflowTechnologies.data || []}
						fieldIsLoading={workflowTechnologies.reloading}
						valueKey="id"
						labelKey="name"
						required
						isNumeric
					/>
				</>
			)}
			{isWriteMapping && (
				<AddModalWriteMapping onChangeWriteMapping={onChangeWriteMapping} />
			)}
		</ModalForm>
	)
}
