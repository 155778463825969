import { createApi } from '@reduxjs/toolkit/query/react'

import { ObjectSettingsComboDto } from '@/endpoints/models'
import { baseQueryWithAuth } from '@/rtkQueries/utils'

export type ObjectSettingsStructure =
	| 'ROOT'
	| 'SYSTEM'
	| 'FOLDER'
	| 'FOLDER_WORKFLOW'
	| 'TABLE'
	| 'MAPPING_DEFAULT'
	| 'MAPPING'
	| 'MAPPING_READ'
	| 'MAPPING_WRITE'
	| 'WORKFLOW'
	| 'VIEW'
	| 'API_NODE'
	| 'API_COLLECTION'
	| 'QUEUE'
	| 'CONSTANT_DOMAIN'
	| 'TABLE_COLUMN'

const url = 'objectsettings/combo'

export const objectSettingsApiSlice = createApi({
	reducerPath: 'objectSettingsApiSlice',
	baseQuery: baseQueryWithAuth,
	endpoints: (builder) => ({
		getObjectSettings: builder.query<
			ObjectSettingsComboDto[],
			ObjectSettingsStructure
		>({
			query: (structureType) => ({
				url,
				params: {
					structureType,
				},
			}),
		}),
	}),
})

export const { useGetObjectSettingsQuery } = objectSettingsApiSlice
