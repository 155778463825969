import { debounce } from 'lodash'
import { ChangeEvent, useMemo } from 'react'

import { updateSpecificLink } from '@/components/Diagram/handlers/linkHandlers'
import { useAppContext, useAppSelector } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { RootState } from '@/store'

import { LinkPropertiesProps } from '../types'
import {
	DiagramPropertiesContent,
	PropertyType,
} from './DiagramPropertiesContent'

export const LinkProperties = ({
	diagramRef,
	propertiesWidth,
	handlePropertiesTitle,
	nodeId,
}: LinkPropertiesProps) => {
	const {
		state: { editMode: isEditMode },
	} = useDetailTabContext()

	const getAllNodesFromTree = useAppSelector(
		(state: RootState) => state?.node?.nodes,
	)

	const selectedLink = useAppSelector(
		(state) =>
			state.diagram.diagrams[nodeId]?.form.graph.inspector.inspectedData,
	)

	const { t } = useAppContext()
	handlePropertiesTitle(t('DIAGRAM_LINK_PROPERTIES'))

	const debouncedUpdateSpecificLink = debounce(updateSpecificLink, 500)

	const onChange = (e: ChangeEvent<HTMLInputElement>, inputType: string) => {
		debouncedUpdateSpecificLink(
			diagramRef,
			inputType,
			selectedLink.key,
			e.target.value,
		)
	}

	const contents = useMemo(
		() => [
			{
				id: 1,
				label: t('DIAGRAM_NODE_ID'),
				contentValue: selectedLink.key,
				type: PropertyType.Info,
			},
			{
				id: 2,
				label: t('DIAGRAM_NODE_TEXT'),
				contentValue: selectedLink.text,
				type: PropertyType.Info,
			},
			{
				id: 3,
				label: t('DIAGRAM_NODE_FROM'),
				contentValue: getAllNodesFromTree[selectedLink.to]?.name,
				type: PropertyType.Info,
			},
			{
				id: 4,
				label: t('DIAGRAM_NODE_TO'),
				contentValue: getAllNodesFromTree[selectedLink.from]?.name,
				type: PropertyType.Info,
			},
			{
				id: 5,
				label: t('DIAGRAM_NODE_TYPE'),
				contentValue: 'Link',
				type: PropertyType.Info,
			},
			{
				id: 6,
				label: t('DIAGRAM_NODE_CONSTRAINT_COLOR'),
				contentValue: (
					<input
						type="color"
						value={selectedLink?.specificLinkColor}
						onChange={(e) => onChange(e, 'SPECIFIC_LINK_COLOR')}
						disabled={!isEditMode}
					/>
				),
				inputType: 'color',
				type: PropertyType.Constraint,
			},
		],
		[],
	)

	return (
		<DiagramPropertiesContent
			contents={contents}
			propertiesWidth={propertiesWidth}
		/>
	)
}
