import { ReactDiagram } from 'gojs-react'
import { useEffect } from 'react'

import { DiagramModals } from '@/components/Diagram'
import { useDiagramContext } from '@/components/Diagram/context/DiagramContext'
import { useAppSelector } from '@/hooks'
import { OnModelChange } from '@/types'

import { DiagramPropertiesPanel } from '../DiagramPropertiesPanel'
import { useInitDiagram, useReactDiagramDrop } from '../hooks'
import { Container, DiagramContainer } from '../styles'
import { DiagramWrapperProps } from '../types'
import { DiagramControls } from './DiagramControls'

export const DiagramWrapper = ({
	linkDataArray,
	skipsDiagramUpdate,
	onDiagramEvent,
	onModelChange,
}: DiagramWrapperProps) => {
	const { diagram, node, isEditMode } = useDiagramContext()

	const thisDiagram = useAppSelector(
		(state) => state.diagram?.diagrams[node?.id as number]?.form.graph,
	)

	const { handleDrop, handleDragOver, handleDragLeave } = useReactDiagramDrop({
		diagram,
		node,
	})

	const onAutoLayout = () => {
		diagram?.makeAutoLayout()
	}

	const { initDiagram } = useInitDiagram()

	const nodeDataArray = thisDiagram?.nodeDataArray || []

	useEffect(() => {
		const goJsDiagram = diagram?.ref?.current?.getDiagram()
		if (goJsDiagram) {
			goJsDiagram?.addDiagramListener('ChangedSelection', onDiagramEvent)

			// Cleanup function to be called on unmount
			return () => {
				goJsDiagram?.removeDiagramListener('ChangedSelection', onDiagramEvent)
			}
		}
	}, [diagram?.ref, onDiagramEvent])

	return (
		<Container>
			<DiagramControls
				onExportPng={diagram?.exportPng}
				onExportSvg={diagram?.exportSvg}
				onAutoLayout={onAutoLayout}
			/>
			<DiagramContainer
				onDrop={isEditMode ? handleDrop : undefined}
				onDragEnter={handleDragOver}
				onDragOver={handleDragOver}
				onDragLeave={handleDragLeave}
				onDragEnd={handleDragLeave}
			>
				<ReactDiagram
					ref={diagram?.ref}
					divClassName="diagram"
					initDiagram={initDiagram}
					nodeDataArray={nodeDataArray}
					linkDataArray={linkDataArray}
					onModelChange={onModelChange as OnModelChange}
					skipsDiagramUpdate={skipsDiagramUpdate}
				/>
				<DiagramPropertiesPanel
					diagramRef={diagram?.ref}
					saveProperties={(properties) => onModelChange(undefined, properties)}
					nodeId={node?.id}
				/>
			</DiagramContainer>
			<DiagramModals />
		</Container>
	)
}
