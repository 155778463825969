import { useCallback, useMemo } from 'react'

import { Loader } from '@/components'
import { TabProps, Tabs } from '@/components/Tabs/Tabs'
import { useTabContext } from '@/context/TabContext/TabContext'
import { QueueData } from '@/endpoints/schemas'
import {
	useAppContext,
	useAppDispatch,
	useAppStore,
	useDebounceCallback,
} from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { VersionsTab } from '@/pages/User/pages/Home/components/VersionsTab/VersionsTab'
import {
	initQueue,
	saveQueue,
	selectQueueTab,
	updateQueue,
} from '@/store/modules/queue/actions'
import { QueueTab } from '@/store/modules/queue/types'
import { UpdateDeepPartial } from '@/store/utils'

import {
	Dependencies,
	Deployments,
	EditableNodeActions,
	Preview,
	Title,
	TitleLeftContent,
	Validation,
} from '../../components'
import { useNodeInit } from '../../hooks/useNodeInit'
import { Overview, PhysicalOptions } from './pages'

export const QueueDetail = () => {
	const { t } = useAppContext()
	const { onSaveError } = useTabContext()
	const dispatch = useAppDispatch()

	const {
		state: { node, systemNodeId, editMode },
	} = useDetailTabContext()

	const queues = useAppStore((state) => state.queue.queues)
	const queue = queues[node.id]
	const objectTypes = queue?.form.objectSettings

	useNodeInit()

	const handleSave = useDebounceCallback(async () => {
		if (!queue || !editMode) {
			return
		}

		try {
			await dispatch(saveQueue(node))
		} catch (e) {
			onSaveError(e)
		}
	}, 1000)

	const handleEdit = async () => {
		await dispatch(initQueue({ nodeId: node.id, editMode: true }))
	}

	const handleCancel = async () => {
		await dispatch(initQueue({ nodeId: node.id, editMode: false }))
	}

	const handleChange = useCallback(
		(data: UpdateDeepPartial<QueueData>) => {
			if (!editMode) {
				return
			}

			dispatch(updateQueue(node, data))
			handleSave()
		},
		[editMode, dispatch, node, handleSave],
	)

	const handleTabChange = (tab: TabProps) => {
		dispatch(selectQueueTab(node, tab.id as QueueTab))
	}

	const tabs = useMemo((): TabProps[] => {
		const { form = null } = queue || {}

		if (!form || !queue) {
			return []
		}

		return [
			{
				id: QueueTab.Overview,
				title: t('TAB_OVERVIEW'),
				content: (
					<Overview
						node={node}
						data={queue}
						systemNodeId={systemNodeId}
						editMode={editMode}
						onChange={handleChange}
						key={QueueTab.Overview}
					/>
				),
			},
			{
				id: QueueTab.PhysicalOptions,
				title: t('TAB_PHYSICAL_OPTIONS'),
				content: (
					<PhysicalOptions
						key={QueueTab.PhysicalOptions}
						editMode={editMode}
						onChange={handleChange}
						data={queue}
					/>
				),
			},
			{
				id: QueueTab.Preview,
				title: t('TAB_PREVIEW'),
				content: (
					<Preview
						key={QueueTab.Preview}
						node={node}
						showObjectTypes
						objectTypes={objectTypes}
					/>
				),
			},
			{
				id: QueueTab.Deployments,
				title: t('TAB_DEPLOYMENTS'),
				content: (
					<Deployments
						node={node}
						key={QueueTab.Deployments}
						editMode={editMode}
					/>
				),
			},
			{
				id: QueueTab.Validation,
				title: t('TAB_VALIDATION'),
				content: <Validation key={QueueTab.Validation} node={node} />,
			},
			{
				id: QueueTab.Dependencies,
				title: t('DEPENDENCIES'),
				content: (
					<Dependencies
						key={QueueTab.Dependencies}
						node={node}
						editMode={editMode}
					/>
				),
			},
			{
				id: QueueTab.Versions,
				title: t('VERSIONS'),
				content: <VersionsTab />,
			},
		]
	}, [queue, t, node, systemNodeId, editMode, handleChange, objectTypes])

	if (!queue) {
		return <Loader loaded={false} />
	}

	return (
		<>
			<Title
				type={node.type}
				title={node.name}
				editMode={editMode}
				leftContent={<TitleLeftContent node={node} />}
				rightContent={
					<EditableNodeActions
						node={node}
						editMode={editMode}
						onEdit={handleEdit}
						onCancel={handleCancel}
						dirty={queue?.dirty}
					/>
				}
			/>

			<Tabs tabs={tabs} onChange={handleTabChange} selectedTabId={queue.tab} />
		</>
	)
}
