import get from 'lodash/get'

import { generateCode, UpdateDeepPartial } from '@/store/utils'
import { Item } from '@/types'
import { getNameCode } from '@/utils'

import {
	AddEmptyRowOptions,
	CreateUpdateOptions,
	HandleRowCompletionOptions,
	UpdateTableAndPropertiesValuesOptions,
} from './types'

export const findOriginalIndex = (
	fieldId: number,
	uuid: string,
	items: Item[],
	itemsKey: string,
): number => {
	if (itemsKey === 'columns') {
		return (items || []).findIndex((item) => {
			return item['uuid'] === uuid
		})
	}

	return (items || []).findIndex((item) => {
		return item['id'] === fieldId
	})
}

// addCompleteRow - an updated helper function
const addNewRow = ({
	createDynamicEmptyRow,
	data,
	idCounterKey,
	idKey,
	items,
	originalIndex,
	update,
	updatedRow,
}: AddEmptyRowOptions) => {
	updatedRow[originalIndex] = {
		...createDynamicEmptyRow(items),
		...updatedRow[originalIndex],
	}

	// Generate new ID
	const newId = data[idCounterKey] + 1

	updatedRow[originalIndex][idKey] = newId

	update[idCounterKey] = newId
}

// handleRowCompletion - an updated helper function
const handleRowCompletion = ({
	createDynamicEmptyRow,
	data,
	idCounterKey,
	idKey,
	items,
	itemsKey,
	originalIndex,
	update,
}: HandleRowCompletionOptions) => {
	const updatedRow = update[itemsKey] as UpdateDeepPartial<Item>
	const updatedItemId = updatedRow[originalIndex]?.id

	const isUpdate = data[itemsKey].find(
		(dataItem: any) => dataItem.id === updatedItemId && updatedItemId !== -1,
	)

	console.log('isUpdate 1', isUpdate)
	console.log('isUpdate 2 with Boolean', Boolean(isUpdate))

	if (isUpdate) {
		console.log('still breaks')
		return
	}

	return addNewRow({
		createDynamicEmptyRow,
		data,
		idCounterKey,
		idKey,
		items,
		update,
		updatedRow,
		originalIndex,
	})
}

// createUpdate - a new helper function
const createUpdate = async ({
	createDynamicEmptyRow,
	data,
	dispatch,
	fieldId,
	fieldName,
	fieldValue,
	idCounterKey,
	idKey,
	items,
	itemsKey,
	originalIndex,
	isCodeLowerCase,
}: CreateUpdateOptions): Promise<UpdateDeepPartial<any>> => {
	const getCode = async () => {
		const lastCodeValue = get(items[originalIndex], 'code')

		if (fieldName === 'name') {
			const response = await dispatch(generateCode(fieldValue))

			return response.result
		}

		if (fieldName === 'code') {
			return fieldValue
		}

		return lastCodeValue
	}

	const code = await getCode()

	const update: UpdateDeepPartial<any> = {
		[itemsKey]: {
			[originalIndex]: {
				[idKey]: fieldId,
				[fieldName]: fieldValue,
				code: getNameCode(code, isCodeLowerCase),
			},
		},
	}

	handleRowCompletion({
		createDynamicEmptyRow,
		data,
		idCounterKey,
		idKey,
		items,
		itemsKey,
		fieldName,
		originalIndex,
		update,
		fieldValue,
		fieldId,
	})

	if (fieldValue?.fieldOptions?.isCustomAttribute) {
		const updateCA: UpdateDeepPartial<any> = {
			[itemsKey]: {
				[originalIndex]: {
					fieldName: fieldValue,
				},
			},
		}

		return updateCA
	}

	return update
}

export const updateTableAndPropertiesValues = async ({
	createDynamicEmptyRow,
	data,
	dispatch,
	idCounterKey,
	idKey,
	items,
	itemsKey,
	fieldName,
	onChange,
	originalIndex,
	setLastEditedCell,
	fieldValue,
	fieldId,
	isCodeLowerCase,
	rowIndex,
}: UpdateTableAndPropertiesValuesOptions) => {
	const update = await createUpdate({
		createDynamicEmptyRow,
		data,
		dispatch,
		idCounterKey,
		idKey,
		items,
		itemsKey,
		fieldId,
		fieldName,
		originalIndex,
		fieldValue,
		isCodeLowerCase,
	})

	setLastEditedCell({ rowId: rowIndex, fieldName })
	onChange(update, fieldName)
}
