import { Row } from '@tanstack/react-table'
import React from 'react'

import { DpStructureSubRowsTable } from '@/pages/User/pages/Home/pages/SystemDetail/pages/DeploymentPackages/components/Structure/DpStructureSubRowsTable'

interface TableSubRowContentProps {
	row: Row<any>
}

export const TableSubRowContent = ({ row }: TableSubRowContentProps) => {
	return <DpStructureSubRowsTable data={row.original.subRows} editMode={true} />
}
