import styled from 'styled-components'

import { TabProps, Tabs } from '@/components/Tabs/Tabs'
import { StructureDto } from '@/endpoints/models'
import { IdCodeName } from '@/endpoints/schemas/general'
import { useAppContext } from '@/hooks'
import { VersionsTab } from '@/pages/User/pages/Home/components/VersionsTab/VersionsTab'
import {
	OpenedViewData,
	ViewDataForm,
	ViewTab,
} from '@/store/modules/view/types'
import { UpdateDeepPartial } from '@/store/utils'

import {
	Dependencies,
	Deployments,
	Preview,
	Validation,
} from '../../../components'
import { Columns, Overview, Permissions, Sources } from '../pages'

type ViewTabsProps = {
	data: OpenedViewData
	editMode: boolean
	node: StructureDto
	objectTypes?: IdCodeName[]
	onChange: (v: UpdateDeepPartial<ViewDataForm>) => void
	onTabChange: (tab: TabProps) => void
	systemNodeId: number
}

export const ViewTabs = ({
	data,
	node,
	editMode,
	systemNodeId,
	objectTypes,
	onTabChange,
	onChange,
}: ViewTabsProps) => {
	const { t } = useAppContext()

	return (
		<GrowTabs
			onChange={onTabChange}
			selectedTabId={data.tab}
			tabs={[
				{
					id: ViewTab.Overview,
					title: t('TAB_OVERVIEW'),
					content: (
						<Overview
							node={node}
							key={ViewTab.Overview}
							onChange={onChange}
							data={data}
							editMode={editMode}
							systemNodeId={systemNodeId}
						/>
					),
				},
				{
					id: ViewTab.Columns,
					title: t('TAB_COLUMNS'),
					content: (
						<Columns
							key={ViewTab.Columns}
							node={node}
							data={data}
							editMode={editMode}
							onChange={onChange}
						/>
					),
				},
				{
					id: ViewTab.Sources,
					title: t('TAB_SOURCES'),
					content: (
						<Sources
							key={ViewTab.Sources}
							data={data}
							editMode={editMode}
							onChange={onChange}
						/>
					),
				},
				{
					id: ViewTab.Permissions,
					title: t('PERMISSIONS'),
					content: (
						<Permissions
							key={ViewTab.Permissions}
							node={node}
							onChange={onChange}
							data={data}
							systemNodeId={systemNodeId}
						/>
					),
				},
				{
					id: ViewTab.Preview,
					title: t('TAB_PREVIEW'),
					content: (
						<Preview
							key={ViewTab.Preview}
							node={node}
							showObjectTypes
							objectTypes={objectTypes}
						/>
					),
				},
				{
					id: ViewTab.Deployments,
					title: t('TAB_DEPLOYMENTS'),
					content: (
						<Deployments
							node={node}
							key={ViewTab.Deployments}
							editMode={editMode}
						/>
					),
				},
				{
					id: ViewTab.Validation,
					title: t('TAB_VALIDATION'),
					content: <Validation key={ViewTab.Validation} node={node} />,
				},
				{
					id: ViewTab.Dependencies,
					title: t('DEPENDENCIES'),
					content: (
						<Dependencies
							key={ViewTab.Dependencies}
							node={node}
							editMode={editMode}
						/>
					),
				},
				{
					id: ViewTab.Versions,
					title: t('VERSIONS'),
					content: <VersionsTab />,
				},
			]}
		/>
	)
}

const GrowTabs = styled(Tabs)`
	flex-grow: 1;
	overflow: auto;
	min-height: 0;
`
