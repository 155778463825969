import { useMemo } from 'react'

import { ItemPropertyType } from '@/enums'
import { useAppContext } from '@/hooks'
import { Item, TableColumnProps } from '@/types'

export const useDpsSubRowsTableColumns = (): TableColumnProps<Item>[] => {
	const { t } = useAppContext()

	return useMemo(() => {
		return [
			{
				field: 'objectTypeCode',
				label: t('OBJECT_TYPE_CODE'),
				type: ItemPropertyType.TEXT,
				width: 200,
				disabled: true,
			},
			{
				field: 'pushedVersion',
				label: t('DEPLOYED_PUSHED_VERSION'),
				type: ItemPropertyType.TEXT,
				width: 200,
				disabled: true,
			},
			{
				field: 'deployedVersion',
				label: t('DEPLOYED_VERSION'),
				type: ItemPropertyType.TEXT,
				width: 200,
				disabled: true,
			},
			{
				field: 'deployedHistoryId',
				label: t('DEPLOYED_HISTORY_ID'),
				type: ItemPropertyType.TEXT,
				width: 200,
				disabled: true,
			},
		]
	}, [t])
}
