import { faPlus, faPuzzlePiece } from '@fortawesome/free-solid-svg-icons'

import {
	Button,
	Container,
	DialogWrapper,
	LightTable,
	Loader,
} from '@/components'
import { getAllTemplates } from '@/endpoints'
import { useApi } from '@/endpoints/hooks'
import { TemplateListDto } from '@/endpoints/models'
import { useAppContext } from '@/hooks'
import { useSettingsPageSaveHandler } from '@/pages/User/pages/Settings/utils'

import { useFilter } from '../../hooks/useFilter'
import {
	TitleActions,
	TitleContainer,
	TitleIcon,
	TitleText,
} from '../../styles'
import { TemplateFilter } from './components/TemplateFilter'
import { TemplateModal } from './components/TemplateModal'
import { TemplateFilterValues } from './components/types'
import { useTemplatesColumns } from './hooks/useTemplatesColumns'
import { useTemplatesFilteredData } from './hooks/useTemplatesFilteredData'

interface TemplatesProps {
	textAreaHeight?: string
}

export const Templates = ({ textAreaHeight }: TemplatesProps) => {
	const { t } = useAppContext()
	const templates = useApi(getAllTemplates())
	const { filter, onChangeFilter } = useFilter<TemplateFilterValues>()

	const handleSave = useSettingsPageSaveHandler(templates)

	const columns = useTemplatesColumns(handleSave)
	const data = useTemplatesFilteredData(filter, templates.data)

	const dialog = (opened: boolean, onClose: () => void) =>
		opened && (
			<TemplateModal
				textAreaHeight={textAreaHeight}
				onSave={handleSave}
				onClose={onClose}
			/>
		)

	const parseTemplateData = () => {
		const parsedData = data.map((item) => ({
			...item,
			technologyName: item.technology?.name,
			stereotypeName: item.stereotype?.name,
			authorName: item.author?.loginName,
			objectSettingsName: item.objectSettings?.name,
		}))

		return parsedData
	}

	return (
		<>
			<TitleContainer>
				<TitleIcon icon={faPuzzlePiece} />
				<TitleText>{t('TEMPLATES')}</TitleText>
				<TitleActions>
					<DialogWrapper dialog={dialog}>
						{(onClick) => (
							<Button size="md" icon={faPlus} onClick={onClick}>
								{t('ADD_NEW')}
							</Button>
						)}
					</DialogWrapper>
				</TitleActions>
			</TitleContainer>

			<Container>
				<TemplateFilter onChangeFilter={onChangeFilter} />
				<Loader loaded={!templates.loading} $absolute />

				<LightTable<TemplateListDto>
					data={parseTemplateData()}
					columns={columns}
					keyField={'id'}
					isLoading={templates.loading}
				/>
			</Container>
		</>
	)
}
