import { faPlus } from '@fortawesome/free-solid-svg-icons'

import { ModalForm } from '@/components/Modal/ModalForm'
import { TextAreaFormField, TextFormField } from '@/components/UberForm'
import { createSystemRelease } from '@/endpoints'
import { useApiRequest } from '@/endpoints/hooks'
import { TabName } from '@/enums'
import { useAppContext, useAppDispatch } from '@/hooks'
import { openTab } from '@/store/modules/tab/actions'

import { AddReleaseProps, FormData } from '../types'

export const AddRelease = ({ node, onClose, editMode }: AddReleaseProps) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()

	const request = useApiRequest()

	const handleSubmit = async (form: Partial<FormData>) => {
		const { data } = await request(
			createSystemRelease(node.id, {
				name: form.name,
				description: form.description,
			}),
		)

		dispatch(
			openTab(node, false, false, {
				id: data?.id,
				customTabName: TabName.DeploymentPackage,
				name: form.name,
				parentNode: node,
				editMode,
			}),
		)

		onClose()
	}

	return (
		<ModalForm<FormData>
			saveTitle={t('CREATE')}
			saveIcon={faPlus}
			cancelTitle={t('CANCEL')}
			onSubmit={handleSubmit}
			open={true}
			onClose={onClose}
			contentStyle={{
				width: '400px',
			}}
			header={t('ADD_NEW_RELEASE')}
		>
			<TextFormField
				title={t('NAME')}
				name="name"
				required
				autoFocus
				enableAutocomplete={false}
			/>

			<TextAreaFormField title={t('DESCRIPTION')} name="description" />
		</ModalForm>
	)
}
