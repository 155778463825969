import omit from 'lodash/omit'

import { updateDataNode } from '@/endpoints'
import { StructureDto } from '@/endpoints/models'
import { DiagramData } from '@/endpoints/schemas/diagram'
import { RootState } from '@/store'
import { apiCallAction, AppDispatch } from '@/store/utils'
import { NewDiagramProps } from '@/types/diagrams'
import { InitDataParams } from '@/utils/structureType/useStructureTypeActions'

import { loadNodeOrHistoryVersion } from '../node/utils'
import {
	DIAGRAM_PROP_UPDATE,
	DIAGRAM_UPDATE,
	FOLDER_INIT,
	FOLDER_SAVE,
	FOLDER_UPDATE,
	REMOVE_DIAGRAM,
	REMOVE_DIAGRAM_NODE,
} from './constants'
import {
	Action,
	DiagramUpdatePayload,
	FolderData,
	FolderInit,
	FolderSave,
	OpenedFolderData,
} from './types'

export const initFolder =
	({ nodeId, editMode = false, version, force, envId }: InitDataParams) =>
	async (dispatch: AppDispatch) => {
		const node = await loadNodeOrHistoryVersion(nodeId, version, envId)

		dispatch({
			type: FOLDER_INIT,
			node,
			editMode,
			force,
		} as FolderInit)
	}

export const updateFolder = (
	node: OpenedFolderData,
	payload: NewDiagramProps | undefined,
) => ({
	type: FOLDER_UPDATE,
	node,
	payload,
})

export const removeDiagramNode = (
	nodeId: number,
	selectedTabDetailId: number,
	removedNodeId: number,
) => ({
	type: REMOVE_DIAGRAM_NODE,
	nodeId,
	selectedTabDetailId,
	removedNodeId,
})

export const removeDiagram = (
	node: StructureDto,
	update: Partial<FolderData>,
): Action => ({
	type: REMOVE_DIAGRAM,
	node,
	update,
})

export const updateDiagramFolder = (
	node: StructureDto,
	payload: DiagramUpdatePayload,
) => ({
	type: DIAGRAM_UPDATE,
	node,
	payload,
})

export const updatePropDiagram = (node: StructureDto, payload: any) => ({
	type: DIAGRAM_PROP_UPDATE,
	node,
	payload,
})

export const saveFolder =
	(node: StructureDto) =>
	async (dispatch: AppDispatch, getState: () => RootState) => {
		const opened = getState().folder.folders[node.id]

		if (!opened) {
			throw new Error(`Saving unopened state ${node.id}`)
		}

		const { name, diagram, diagramSvg, generateCode, generateTableColumnCode } =
			opened.form

		const formData = omit(opened.form, [
			'generateCode',
			'generateTableColumnCode',
		])

		const folderData = {
			...formData,
			name: name as string,
			diagram: diagram as DiagramData,
			diagramSvg: diagramSvg as string,
			namingConfig: {
				generateCode,
				generateTableColumnCode,
			},
		}

		await dispatch(
			apiCallAction<FolderSave>(
				() =>
					updateDataNode(node.id, {
						data: JSON.stringify(folderData),
					}),
				FOLDER_SAVE,
				{ nodeId: node.id },
			),
		)
	}
