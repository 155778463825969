import { ReactDiagram } from 'gojs-react'
import { createContext, useContext } from 'react'

import { StructureDto } from '@/endpoints/models'

interface DiagramContextProps {
	diagram: {
		exportPng: () => void
		exportSvg: () => void
		makeAutoLayout: () => void
		ref: React.RefObject<ReactDiagram>
	} | null
	isEditMode: boolean
	node: StructureDto | null
}

export const DiagramContext = createContext<DiagramContextProps>({
	diagram: null,
	isEditMode: false,
	node: null,
})

// Hook to use the context
export const useDiagramContext = () => {
	const context = useContext(DiagramContext)
	if (!context) {
		throw new Error('useDiagramContext must be used within a DiagramProvider')
	}
	return context
}

DiagramContext.displayName = 'DiagramContext'
