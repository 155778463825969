import { faKey } from '@fortawesome/free-solid-svg-icons'

import { Button, DialogWrapper } from '@/components'
import { StructureDto } from '@/endpoints/models'
import { useAppContext } from '@/hooks'

import { Permissions } from './Permissions'

export const PermissionsButton = ({ node }: { node: StructureDto }) => {
	const { t } = useAppContext()

	return (
		<DialogWrapper
			dialog={(opened, onClose) => (
				<Permissions
					nodeId={node.id}
					isReadOnly={!node.canEdit}
					onClose={onClose}
					opened={opened}
				/>
			)}
		>
			{(open) => (
				<Button onClick={open} icon={faKey}>
					{t('BUTTON_PERMISSIONS')}
				</Button>
			)}
		</DialogWrapper>
	)
}
