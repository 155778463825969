import { StructureDetailDto, StructureObjectDto } from '@/endpoints/models'
import { MappingData, MappingPermission } from '@/endpoints/schemas'
import {
	MAPPING_INIT,
	MAPPING_SAVE,
	MAPPING_SELECT_TAB,
	MAPPING_UPDATE,
} from '@/store/modules/mapping/constants'
import { UpdateDeepPartial } from '@/store/utils'

export interface OpenedMappingData {
	dirty: boolean
	form: MappingDataForm
	original: MappingDataForm
	ownerId?: number
	parsedEditMode: boolean
	responsibleAnalystId: string
	responsiblePersonId: string
	stereotypeId: number
	tab: MappingTab
}

export type MappingPermissionFlat = Omit<MappingPermission, 'user'> & {
	userCode?: string
	userComment?: string
	userDescription?: string
	userId?: number
	userName?: string
}

export type MappingDataForm = Omit<
	Partial<MappingData>,
	'objectPermissions'
> & {
	objectPermissions: MappingPermissionFlat[]
}

export enum MappingTab {
	Columns = 'Columns',
	Dependencies = 'Dependencies',
	Deployments = 'Deployments',
	Others = 'Others',
	Overview = 'Overview',
	Permissions = 'Permissions',
	Preview = 'Preview',
	Sources = 'Sources',
	Validation = 'Validation',
	Versions = 'Versions',
}

export interface InitMapping {
	editMode: boolean
	force: boolean
	node: StructureDetailDto
	type: typeof MAPPING_INIT
}

export interface SaveMapping {
	metadata: {
		node: StructureObjectDto
	}
	payload: void
	type: typeof MAPPING_SAVE
}

export interface UpdateMapping {
	node: StructureObjectDto
	type: typeof MAPPING_UPDATE
	update: UpdateDeepPartial<MappingDataForm>
}

export interface SelectMappingTab {
	node: StructureObjectDto
	tab: MappingTab
	type: typeof MAPPING_SELECT_TAB
}

export type Actions =
	| InitMapping
	| SaveMapping
	| UpdateMapping
	| SelectMappingTab
