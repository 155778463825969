import * as go from 'gojs'

import { useDiagramContext } from '@/components/Diagram/context/DiagramContext'
import { createTableNodeContextMenu } from '@/components/Diagram/Templates/NodeTemplates/contextMenu'
import { createTableNodeTooltip } from '@/components/Diagram/Templates/NodeTemplates/tooltips'
import { useAppDispatch, useAppStore } from '@/hooks'
import { openTab } from '@/store/modules/tab/actions'

import { getNodeData } from './api'

export const useNodeTempSettings = () => {
	const { token } = useAppStore((state) => state.auth)

	const { node } = useDiagramContext()

	const dispatch = useAppDispatch()

	const nodeTempClickHandler = (e: go.InputEvent) => {
		const clickedPart = e.diagram.findPartAt(e.documentPoint)

		// Ensure the clicked part is a Node so we can safely access its specific properties
		if (clickedPart instanceof go.Node) {
			const clickedNode = clickedPart
			console.log('clickedNode', clickedNode)

			const clickedNodeData = clickedNode.data
			console.log('clickedNodeData', clickedNodeData)
		}

		console.error('No node was clicked.')
	}

	const nodeTempDblClickHandler = async (e: go.InputEvent) => {
		const clickedPart = e.diagram.findPartAt(e.documentPoint)

		//write a fetch function to get data
		const data = await getNodeData(clickedPart?.data.key, token)
		dispatch(openTab(data, false))
	}

	return {
		locationSpot: go.Spot.Center,
		movable: true,
		contextMenu: createTableNodeContextMenu(dispatch, node?.id as number),
		toolTip: createTableNodeTooltip(),
		click: (e: go.InputEvent) => nodeTempClickHandler(e),
		doubleClick: (e: go.InputEvent) => nodeTempDblClickHandler(e),
	}
}
