import { createApi } from '@reduxjs/toolkit/query/react'

import { TechnologyDto } from '@/endpoints/models'
import { baseQueryWithAuth } from '@/rtkQueries/utils'

const url = 'technologies'

export const technologiesApiSlice = createApi({
	reducerPath: 'technologiesApiSlice',
	baseQuery: baseQueryWithAuth,
	endpoints: (builder) => ({
		getTechnologies: builder.query<TechnologyDto[], void>({
			query: () => url,
		}),
	}),
})

export const { useGetTechnologiesQuery } = technologiesApiSlice
