import { StructureDto } from '@/endpoints/models'
import { PropertiesErrorProps, TableColumnProps } from '@/types'

export type Predicate<T> = (value: T, listOfValues: T[]) => boolean

interface TableAndPropertiesValues {
	fieldId: number
	fieldName: string
	fieldOptions?: any
	fieldValue: any
	rowIndex: number
	uuid: string
}

export type PropertiesChangeCallback = (props: TableAndPropertiesValues) => void

export type EditingAddress = {
	field: number
	row: number
}

export enum MoveDir {
	Up = 1,
	Down = 2,
	Left = 3,
	Right = 4,
}

export interface PropertiesProps<T> {
	errors?: PropertiesErrorProps
	index?: number
	isHidden?: boolean
	item?: T
	listOfValues: T[]
	onChange: PropertiesChangeCallback
	onHide: () => void
	onSelect?: (index: number) => void
	onShow: () => void
	properties: TableColumnProps<T>[]
	propertiesWidth: number
	readonly?: boolean
}

export type PanelPropertiesProps<Item> = {
	errors?: PropertiesErrorProps
	items: any
	listOfValues: Item[]
	node: StructureDto
	onChange: PropertiesChangeCallback
	panelPropertiesDefaultOpen?: boolean
	panelPropertiesMinWidth?: number
	properties: TableColumnProps<Item>[]
	tabKey: string
}
