import { faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'

import { getAllNodeHistory } from '@/endpoints'
import { useApi } from '@/endpoints/hooks'
import { StructureDto } from '@/endpoints/models'
import { useAppContext, useAppStore } from '@/hooks'
import LastVersionInfo from '@/pages/User/pages/Home/components/LastVersionInfo'
import { isLocked, isLockedByMe } from '@/utils/locking'

import { useDetailTabContext } from './DetailTab'

type TitleLeftContentProps = {
	node: StructureDto
}

export const TitleLeftContent = ({ node }: TitleLeftContentProps) => {
	const { t } = useAppContext()
	const user = useAppStore((state) => state.auth.user)
	const historyList = useApi(getAllNodeHistory(node.id))
	const history = historyList.data

	const {
		state: { version },
	} = useDetailTabContext()

	const lastHistory = version
		? history?.find((history) => history.version === version)
		: history?.[0]

	return (
		<Wrapper>
			{isLocked(node) &&
				(isLockedByMe(node, user) ? (
					<Info $variant="green">
						<LockInfoIcon icon={faLock} />
						{t('LOCKED_BY_YOU', [node.lockUserName as string])}
					</Info>
				) : (
					<Info $variant="red">
						<LockInfoIcon icon={faLock} />
						{t('LOCKED_BY_USER', [node.lockUserName as string])}
					</Info>
				))}
			<Info>
				<LastVersionInfo data={lastHistory} />
			</Info>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	display: flex;
	margin-left: auto;
`

const Info = styled.div<{ $variant?: 'red' | 'green' }>`
	display: flex;
	align-items: center;
	padding: 0 4px;
	${(props) => {
		switch (props.$variant) {
			case 'red':
				return css`
					color: ${props.theme.mainColors.danger};
					> svg {
						color: ${(props) => props.theme.mainColors.danger};
					}
				`
			case 'green':
				return css`
					color: ${props.theme.mainColors.success};
					> svg {
						color: ${(props) => props.theme.mainColors.success};
					}
				`
		}
	}}
`

const LockInfoIcon = styled(FontAwesomeIcon)`
	margin-right: 6px;
`
