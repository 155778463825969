import { useKeycloak } from '@react-keycloak/web'
import React, { Suspense } from 'react'

import { Loader, Tabs } from '@/components'
import { StructureDto } from '@/endpoints/models'
import { useAppContext } from '@/hooks'
import {
	FolderData,
	OpenedFolderData,
	OpenedFolderTab,
} from '@/store/modules/folder/types'

import { Dependencies, Validation, VersionsTab } from '../../../components'
import { Overview } from '../pages/Overview/Overview'

const Diagram = React.lazy(
	() => import(/* webpackChunkName: "diagram" */ '../pages/Diagram/Diagram'),
)

type FolderTabsProps = {
	data: OpenedFolderData
	editMode: boolean
	node: StructureDto
	onChange: (v: Partial<FolderData>) => void
	systemNodeId: number
}

export const FolderTabs = ({
	node,
	onChange,
	editMode,
	data,
	systemNodeId,
}: FolderTabsProps) => {
	const { t } = useAppContext()
	const { keycloak } = useKeycloak()

	const roles = keycloak.tokenParsed?.realm_access?.roles ?? []
	const hasAccessToDiagram = roles.includes('FEAT_DIAGRAM')

	const folderTabs = [
		{
			id: OpenedFolderTab.Overview,
			title: t('TAB_OVERVIEW'),
			content: (
				<Overview
					key={OpenedFolderTab.Overview}
					node={node}
					isEditMode={!editMode}
					onChange={onChange}
					data={data}
					systemNodeId={systemNodeId}
				/>
			),
		},
		{
			id: OpenedFolderTab.Diagram,
			title: t('TAB_DIAGRAM'),
			content: (
				<Suspense
					key={OpenedFolderTab.Diagram}
					fallback={<Loader loaded={false} />}
				>
					<Diagram data={data} nodeId={node.id} onChange={onChange} />
				</Suspense>
			),
		},
		{
			id: OpenedFolderTab.Validation,
			title: t('TAB_VALIDATION'),
			content: <Validation key={OpenedFolderTab.Validation} node={node} />,
		},
		{
			id: OpenedFolderTab.Dependencies,
			title: t('DEPENDENCIES'),
			content: (
				<Dependencies
					key={OpenedFolderTab.Dependencies}
					node={node}
					editMode={editMode}
				/>
			),
		},
		{
			id: OpenedFolderTab.Versions,
			title: t('VERSIONS'),
			content: <VersionsTab />,
		},
	]

	const getTabs = () => {
		if (hasAccessToDiagram) {
			return folderTabs
		}

		return folderTabs.filter((tab) => tab.hasAccessToDiagram !== false)
	}

	return <Tabs tabs={getTabs()} />
}
