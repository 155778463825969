import { Cell } from '@tanstack/react-table'
import get from 'lodash/get'
import maxBy from 'lodash/maxBy'

import { PropertiesChangeCallback } from '@/components/Properties/types'
import { TableColumnProps } from '@/types'

export const getTextWidth = (
	text: string | undefined,
	element: HTMLElement | undefined,
	txtWidthLimit = 180,
	extraWidth = false,
) => {
	const canvasContext = document.createElement('canvas').getContext('2d')

	if (!element || !canvasContext || !text) {
		return undefined
	}

	const styles = getComputedStyle(element)

	const fontStyles = [
		styles.getPropertyValue('font-weight'),
		styles.getPropertyValue('font-style'),
		styles.getPropertyValue('font-size'),
		styles.getPropertyValue('font-family'),
	].join(' ')

	canvasContext.font = fontStyles

	const textWidth =
		canvasContext.measureText(text).width + (extraWidth ? 67 : 20)

	return textWidth > txtWidthLimit ? txtWidthLimit : textWidth
}

export const calculateFieldValues = (acc: any, tableData: any, prev: any) => {
	const fieldValues = tableData?.map((item: any) => get(item, acc.field))
	let maxValue = maxBy(fieldValues, 'length')

	if (acc.field === 'objectCode') {
		const options =
			typeof acc.options === 'function' ? acc.options(tableData?.[0]) : []

		const selectedOption = options.find(
			(option: any) => option.code === get(tableData?.[0], acc.field),
		)

		if (selectedOption) {
			const optionLabelLength = selectedOption?.fullPath
			maxValue = optionLabelLength

			return {
				...prev,
				[acc.field]: maxValue,
			}
		}
	}

	return {
		...prev,
		[acc.field]: maxValue,
	}
}

export const callBackChangeHandler = (
	handleChange: PropertiesChangeCallback,
	cellProps: Cell<any, any>,
	columnModelProps: TableColumnProps<any>,
	value: any,
) => {
	const { id, uuid } = cellProps.row.original
	return handleChange({
		fieldId: id,
		fieldName: columnModelProps.field,
		fieldValue: value,
		rowIndex: cellProps.row.index,
		uuid,
	})
}
