import go from 'gojs'
import { useEffect } from 'react'

import { useDiagramContext } from '@/components/Diagram/context/DiagramContext'
import { toggleGrid } from '@/components/Diagram/handlers'
import {
	createDiagramMenu,
	setupLinkTemplates,
	useSetupNodeTemplates,
} from '@/components/Diagram/Templates'
import { LinkRoutingType } from '@/enums'
import { useAppContext, useAppDispatch, useAppSelector } from '@/hooks'
import colors from '@/styles/diagramColors'

export const useInitDiagram = () => {
	const { t } = useAppContext()
	const { node, diagram } = useDiagramContext()
	const thisDiagram = useAppSelector(
		(state) => state.diagram?.diagrams[node?.id as number]?.form.graph,
	)
	const dispatch = useAppDispatch()

	const routingType = thisDiagram?.properties?.links?.routingType
	const properties = thisDiagram?.properties
	const gridCellSize = thisDiagram?.properties?.grid?.cellSize || 10
	const gridVisible = thisDiagram?.properties?.grid?.isVisible || false

	// set your license key here before creating the diagram: go.Diagram.licenseKey = "...";
	const goJsDiagram = new go.Diagram({
		'undoManager.isEnabled': true, // must be set to allow for model change listening
		model: new go.GraphLinksModel({
			// IMPORTANT! must be defined for merges and data sync when using GraphLinksModel
			nodeKeyProperty: 'key',
			linkKeyProperty: 'key',
		}),
	})

	createDiagramMenu(goJsDiagram, t, dispatch)

	const noteTemplates = useSetupNodeTemplates()

	goJsDiagram.nodeTemplateMap = noteTemplates

	goJsDiagram.linkTemplateMap = setupLinkTemplates(
		routingType || LinkRoutingType.Orthogonal,
	)

	const horizontalLineColor =
		properties?.grid?.lineColors?.horizontal || colors.grid.horizontalLineColor
	const verticalLineColor =
		properties?.grid?.lineColors?.vertical || colors.grid.verticalLineColor
	const intervalHorizontalLineColor =
		properties?.grid?.lineColors?.intervalHorizontal ||
		colors.grid.intervalHorizontalLineColor
	const intervalVerticalLineColor =
		properties?.grid?.lineColors?.intervalVertical ||
		colors.grid.intervalVerticalLineColor

	goJsDiagram.grid = new go.Panel('Grid', {
		gridCellSize: new go.Size(gridCellSize, gridCellSize),
	}).add(
		new go.Shape('LineH', {
			name: 'HorizontalLine',
			stroke: horizontalLineColor,
		}),
		new go.Shape('LineV', {
			name: 'VerticalLine',
			stroke: verticalLineColor,
		}),
		new go.Shape('LineH', {
			name: 'IntervalHorizontalLine',
			stroke: intervalHorizontalLineColor,
			interval: 5,
		}),
		new go.Shape('LineV', {
			name: 'IntervalVerticalLine',
			stroke: intervalVerticalLineColor,
			interval: 5,
		}),
	)

	useEffect(() => {
		if (diagram) {
			toggleGrid(diagram.ref, gridVisible)
		}
	}, [diagram, gridVisible])

	return { initDiagram: () => goJsDiagram }
}
