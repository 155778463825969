import React from 'react'

import { AppContext } from '@/context/AppContext/index'

import { Locale, TranslationHelper } from '../Locale'

export default React.createContext({
	locale: null as Locale | null,
	t: null as TranslationHelper | null,
})

AppContext.displayName = 'App 007 Context'
