import { WorkflowData } from '@/endpoints/schemas'

export interface OpenedWorkflowData {
	dirty: boolean
	form: WorkflowDataForm
	graph: UserGraphData | null
	original: WorkflowDataForm
	parsedEditMode: boolean
	tab: WorkflowTab
}

export interface UserGraphData {
	offset?: {
		x: number
		y: number
	}
	selected?: string
	zoom?: number
}

export type WorkflowDataForm = Partial<WorkflowData>

export enum WorkflowTab {
	Dependencies = 'Dependencies',
	Deployments = 'Deployments',
	Graph = 'Graph',
	Overview = 'Overview',
	Preview = 'Preview',
	Validation = 'Validation',
	Versions = 'Versions',
}
