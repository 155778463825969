import debounce from 'debounce'
import React, { useCallback } from 'react'

import { Loader } from '@/components'
import { useTabContext } from '@/context/TabContext/TabContext'
import { StructureObjectDto } from '@/endpoints/models'
import { useAppDispatch, useAppStore } from '@/hooks'
import {
	initFolder,
	saveFolder,
	updateFolder,
} from '@/store/modules/folder/actions'
import { FolderData } from '@/store/modules/folder/types'

import { useDetailTabContext } from '../../components/DetailTab/context/DetailTabContext'
import { EditableNodeActions } from '../../components/EditableNodeActions/EditableNodeActions'
import { Title } from '../../components/Title'
import { TitleLeftContent } from '../../components/TitleLeftContent'
import { useNodeInit } from '../../hooks/useNodeInit'
import { FolderTabs } from './components/Tabs'

export const FolderDetail = () => {
	const { onSaveError } = useTabContext()

	const {
		state: { node, systemNodeId, editMode },
	} = useDetailTabContext()

	const dispatch = useAppDispatch()
	const data = useAppStore((state) => state.folder.folders[node.id])

	useNodeInit()

	const handleEdit = async () => {
		await dispatch(initFolder({ nodeId: node.id, editMode: true }))
	}

	const handleCancel = async () => {
		await dispatch(initFolder({ nodeId: node.id, editMode: false }))
	}

	const saveDebounced = useCallback(
		debounce(async () => {
			try {
				await dispatch(saveFolder(node))
			} catch (e) {
				onSaveError(e)
			}
		}, 1000),
		[node],
	)

	const handleChange = useCallback(
		(v: Partial<FolderData>) => {
			if (editMode) {
				dispatch(updateFolder(node, v))
				saveDebounced()
			}
		},
		[editMode, dispatch, node, saveDebounced],
	)

	if (!data) {
		return <Loader loaded={false} />
	}

	return (
		<>
			<Title
				type={node.type as StructureObjectDto.TypeEnum}
				title={node.name}
				leftContent={<TitleLeftContent node={node} />}
				rightContent={
					<EditableNodeActions
						node={node}
						editMode={editMode}
						onEdit={handleEdit}
						onCancel={handleCancel}
						dirty={data?.dirty}
					/>
				}
			/>
			<FolderTabs
				node={node}
				onChange={handleChange}
				editMode={editMode}
				data={data}
				systemNodeId={systemNodeId}
			/>
		</>
	)
}
