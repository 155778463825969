import debounce from 'debounce'
import React, { useCallback } from 'react'

import { Loader } from '@/components'
import { useTabContext } from '@/context/TabContext/TabContext'
import { useAppDispatch, useAppStore } from '@/hooks'
import {
	initWorkflow,
	saveWorkflow,
	updateWorkflow,
} from '@/store/modules/workflow/actions'
import { WorkflowDataForm } from '@/store/modules/workflow/types'

import { useDetailTabContext } from '../../components/DetailTab/context/DetailTabContext'
import { EditableNodeActions } from '../../components/EditableNodeActions/EditableNodeActions'
import { Title } from '../../components/Title/Title'
import { TitleLeftContent } from '../../components/TitleLeftContent'
import { useNodeInit } from '../../hooks/useNodeInit'
import { WorkflowTabs } from './components/Tabs'

export const WorkflowDetail = () => {
	const dispatch = useAppDispatch()
	const { onSaveError } = useTabContext()
	const { workflows } = useAppStore((state) => state.workflow)

	const {
		state: { node, systemNodeId, editMode },
	} = useDetailTabContext()

	const data = workflows[node.id]
	const objectTypes = data?.form.objectSettings

	useNodeInit()

	const handleEdit = async () => {
		await dispatch(initWorkflow({ nodeId: node.id, editMode: true }))
	}

	const handleCancel = async () => {
		await dispatch(initWorkflow({ nodeId: node.id, editMode: false }))
	}

	const saveDebounced = useCallback(
		debounce(async () => {
			try {
				await dispatch(saveWorkflow(node))
			} catch (e) {
				onSaveError(e)
			}
		}, 1000),
		[node],
	)

	const handleChange = useCallback(
		(v: Partial<WorkflowDataForm>) => {
			if (editMode) {
				dispatch(updateWorkflow(node, v))
				saveDebounced()
			}
		},
		[editMode, dispatch, node, saveDebounced],
	)

	if (!data) {
		return <Loader loaded={false} />
	}

	return (
		<>
			<Title
				type={node.type}
				title={node.name}
				editMode={editMode}
				leftContent={<TitleLeftContent node={node} />}
				rightContent={
					<EditableNodeActions
						node={node}
						editMode={editMode}
						dirty={data.dirty}
						onEdit={handleEdit}
						onCancel={handleCancel}
					/>
				}
			/>
			<WorkflowTabs
				node={node}
				onChange={handleChange}
				data={data}
				editMode={editMode}
				objectTypes={objectTypes}
				systemNodeId={systemNodeId}
			/>
		</>
	)
}
