import { useEffect } from 'react'
import { getNodeHistoryDetailByVersion } from 'src/endpoints'

import { useApiRequest } from '@/endpoints/hooks'
import { StructureObjectDto } from '@/endpoints/models'
import { TableMode } from '@/enums'
import { useAppDispatch, useAppStore } from '@/hooks'
import { useIsMounted } from '@/hooks/useIsMounted'
import { getFolderDataForm } from '@/store/modules/folder/helpers'
import { updateFormHighlights } from '@/store/modules/formHighlights/actions'
import { getSystemDataForm } from '@/store/modules/system/helpers'

import { useDetailTabContext } from '../components/DetailTab/context/DetailTabContext'
import { getModeForm, getUnsyncedFields } from '../pages/TableDetail/utils'

/** Update redux with current (latest) version element form data */
export const useFormHighlightsData = () => {
	const isMounted = useIsMounted()
	const dispatch = useAppDispatch()
	const request = useApiRequest()
	const abortController = new AbortController()

	useEffect(() => {
		return () => {
			dispatch(updateFormHighlights({ active: false, prevVersionValues: null }))
			abortController.abort()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const {
		state: { node, version, versionActive, versionMax },
	} = useDetailTabContext()

	const mode = useAppStore(
		(state) => state.table.tables[node.id]?.mode ?? TableMode.TABLE,
	)

	useEffect(() => {
		const call = async () => {
			if (
				(version === undefined && !versionActive) ||
				(version ?? 2) - 1 <= 0
			) {
				dispatch(
					updateFormHighlights({ active: false, prevVersionValues: null }),
				)

				return
			}

			const targetVersion = version ? version : versionMax ? versionMax : 1

			const nodeData = await request(
				getNodeHistoryDetailByVersion(node.id, targetVersion - 1),
			)

			let prevVersionValues = JSON.parse(
				nodeData.data ? nodeData.data.data : '{}',
			)

			switch (node.type) {
				case StructureObjectDto.TypeEnum.FOLDER: {
					prevVersionValues = getFolderDataForm(prevVersionValues)
					break
				}

				case StructureObjectDto.TypeEnum.SYSTEM: {
					prevVersionValues = getSystemDataForm(prevVersionValues)
					break
				}

				case StructureObjectDto.TypeEnum.TABLE: {
					const unsyncedFields = getUnsyncedFields(mode, prevVersionValues)
					const modeForm = getModeForm(mode, prevVersionValues, unsyncedFields)
					prevVersionValues = modeForm
					break
				}
			}

			if (abortController.signal.aborted) {
				return
			}

			if (targetVersion && isMounted()) {
				dispatch(updateFormHighlights({ active: true, prevVersionValues }))
			}
		}

		call()

		return () => {
			abortController.abort()
		}
	}, [
		dispatch,
		mode,
		node.id,
		node.type,
		request,
		version,
		versionActive,
		versionMax,
		isMounted,
	])
}
