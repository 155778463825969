import { useMemo } from 'react'

import { Button, DialogWrapper, MessageModal } from '@/components'
import { ReleaseDto, StructureDto } from '@/endpoints/models'
import { ItemPropertyType } from '@/enums'
import { useAppContext } from '@/hooks'
import { DdlModalType, Item, TableColumnProps } from '@/types'

import { DdlModal } from './DdlModal'

export const useEnvTableColumns = (
	deploymentPackage: ReleaseDto,
	editMode: boolean,
	node: StructureDto,
	reloadEnvs: () => void,
): TableColumnProps<Item>[] => {
	const { t } = useAppContext()

	return useMemo(() => {
		return [
			{
				field: 'name',
				label: t('TABLE_COLUMNS_NAME'),
				type: ItemPropertyType.TEXT,
				autoWidth: true,
				disabled: true,
			},
			{
				field: 'code',
				label: t('TABLE_COLUMNS_CODE'),
				type: ItemPropertyType.GENERATED_CODE,
				width: 70,
				disabled: true,
				canGenerateCodeFieldName: true,
			},
			{
				field: 'releaseDate',
				label: 'Release date',
				type: ItemPropertyType.TEXT,
				width: 100,
				disabled: true,
			},
			{
				field: 'releaseTime',
				label: 'Release time',
				type: ItemPropertyType.TEXT,
				width: 100,
				disabled: true,
			},
			{
				field: 'released',
				label: 'Released',
				type: ItemPropertyType.CHECKBOX,
				width: 50,
				disabled: true,
			},
			{
				field: 'actions',
				label: 'Actions',
				width: 120,
				disabled: false,
				type: ItemPropertyType.CUSTOM_JSX,
				Cell: (props) => {
					return (
						<div className="flex gap-3 h-full items-center justify-center">
							<DialogWrapper
								dialog={(opened, onClose) =>
									opened && (
										<>
											<DdlModal
												preview
												env={props.row.original}
												node={node}
												deploymentPackage={deploymentPackage}
												onClose={() => {
													onClose()
												}}
												type={DdlModalType.PushToGit}
											/>
										</>
									)
								}
							>
								{(onClick) => (
									<Button size="sm" onClick={onClick}>
										{t('PUSH_TO_GIT')}
									</Button>
								)}
							</DialogWrapper>
							<DialogWrapper
								dialog={(opened, onClose) =>
									opened && (
										<>
											<DdlModal
												env={props.row.original}
												node={node}
												deploymentPackage={deploymentPackage}
												onClose={() => {
													reloadEnvs()
													onClose()
												}}
												type={DdlModalType.CreateGit}
											/>
											{editMode && (
												<MessageModal
													title={t('DEPLOYED_UNPUSHED_CHANGES_WARN_TITLE')}
													type="warn"
													message={t('DEPLOYED_UNPUSHED_CHANGES_WARN')}
												/>
											)}
										</>
									)
								}
							>
								{(onClick) => (
									<Button size="sm" onClick={onClick}>
										{t('CREATE_DDL')}
									</Button>
								)}
							</DialogWrapper>
						</div>
					)
				},
			},
		]
	}, [t])
}
