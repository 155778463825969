export enum TableTab {
	Columns = 'Columns',
	Constraints = 'Constraints',
	Dependencies = 'Dependencies',
	Deployments = 'Deployments',
	General = 'General',
	Indexes = 'Indexes',
	Mappings = 'Mappings',
	Permissions = 'Permissions',
	PhysicalOptions = 'PhysicalOptions',
	Preview = 'Preview',
	Validation = 'Validation',
	Versions = 'Versions',
}

export enum TemplateTab {
	Form = 'Form',
	Script = 'Script',
	Versions = 'Versions',
}

export enum TableMode {
	HISTORY = 'HISTORY',
	REFERENCE = 'REFERENCE',
	TABLE = 'TABLE',
}
