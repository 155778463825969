import * as go from 'gojs'

import { getCommentAndDescription } from '@/components/Diagram/Templates'
import { TableNodeProps } from '@/components/Diagram/Templates/enums'
import colors from '@/styles/diagramColors'
import diagramColors from '@/styles/diagramColors'

const $ = go.GraphObject.make

const createTextBlockPanel = (
	visibilityBindingName: string,
	textBindingCallback: (data: any) => string,
) => {
	return $(
		go.Panel,
		'Auto',
		{ margin: 0 },
		new go.Binding('background', TableNodeProps.NodeBodyColor),
		$(
			go.TextBlock,
			{ row: 0, column: 0 },
			new go.Binding('text', '', textBindingCallback),
			new go.Binding('visible', visibilityBindingName),
		),
		new go.Binding('background', TableNodeProps.NodeBodyColor),
	)
}

const useCreateTableColumns = () => {
	return $(
		go.Panel,
		'Auto',
		{ margin: 1, minSize: new go.Size(NaN, 70) },
		$(
			go.Panel,
			'Table',
			new go.Binding('itemArray', '', (data) => {
				if (data.tableColumnsVisibility) {
					return data.columns
				}
				return []
			}),
			{
				itemTemplate: $(
					go.Panel,
					'TableRow',
					$(
						go.TextBlock,
						{
							margin: new go.Margin(5, 10, 5, 10),
							textAlign: 'left',
							stretch: go.GraphObject.Horizontal,
						},
						new go.Binding('text', '', (column) => `${column.name}`),
					),
				),
			},
		),
	)
}

export const createTableHeader = () => {
	const tableHeader = new go.Panel('Auto', {
		background: colors.nodes.headerBackground,
		stretch: go.GraphObject.Horizontal,
	})
		.bind(new go.Binding('background', TableNodeProps.NodeHeaderColor))
		.add(
			new go.TextBlock({
				margin: 3,
				font: 'bold 16px sans-serif',
				minSize: new go.Size(140, NaN),
				textAlign: 'center',
				stretch: go.GraphObject.Horizontal,
			}).bind(
				new go.Binding('text', '', (data) => {
					const { tableCodeVisibility, tableNameVisibility, code, text } = data
					if (tableCodeVisibility && tableNameVisibility) {
						return `${text} (${code})`
					} else if (tableCodeVisibility) {
						return `${code}`
					}
					return `${text}`
				}),
			),
		)

	return tableHeader
}

export const useCreateTableBody = () => {
	const createTableColumns = useCreateTableColumns()

	const tableBody = new go.Panel('Table', {
		background: colors.nodes.bodyBackground,
		stretch: go.GraphObject.Horizontal,
	})
		.bind(new go.Binding('background', TableNodeProps.NodeBodyColor))
		.add(createTableColumns)
		.add(
			createTextBlockPanel(
				'commentsVisibility',
				(data) => getCommentAndDescription([], data.key.toString()).comment,
			),
		)
		.add(
			createTextBlockPanel(
				'descriptionsVisibility',
				(data) => getCommentAndDescription([], data.key.toString()).description,
			),
		)

	return tableBody
}

export const useCreateNodeTempLayout = () => {
	const createTableBody = useCreateTableBody()

	return new go.Panel(go.Panel.Auto) // Changed from Panel.Vertical to Node
		.bind(new go.Binding('background', TableNodeProps.NodeBodyColor))
		.add(
			new go.Shape('RoundedRectangle', {
				fill: 'white',
				stroke: diagramColors.nodes.lightBorder,
				strokeWidth: 1,
			}),
		)
		.add(new go.Panel('Vertical').add(createTableHeader()).add(createTableBody))
}
