import { useMemo } from 'react'

import { Tabs } from '@/components'
import { TabProps } from '@/components/Tabs'
import { TableMode, TableTab } from '@/enums'
import { useAppContext } from '@/hooks'
import {
	Dependencies,
	Deployments,
	Preview,
	Validation,
	VersionsTab,
} from '@/pages/User/pages/Home/components'

import {
	Columns,
	Constraints,
	Indexes,
	Mappings,
	Overview,
	Permissions,
	PhysicalOptions,
} from './pages'
import { TableDetailTabsProps } from './types'

export const TableDetailTabs = ({
	domains,
	editMode,
	handleChange,
	handleSync,
	handleTabChange,
	mode,
	modeForm,
	node,
	objectTypes,
	refForm,
	reloadTableData,
	systemNodeId,
	table,
	technicalColumns,
	unsyncedFields,
}: TableDetailTabsProps) => {
	const { t } = useAppContext()

	const tabs = useMemo((): TabProps[] => {
		const { form = null } = table || {}

		if (!form || !table || !modeForm) {
			return []
		}

		return [
			{
				id: TableTab.General,
				title: t('TAB_OVERVIEW'),
				content: (
					<Overview
						node={node}
						data={table}
						systemNodeId={systemNodeId}
						editMode={editMode}
						mode={mode}
						onChange={handleChange}
						key={TableTab.General}
						onSync={handleSync}
						unsyncedFields={unsyncedFields}
						modeForm={modeForm}
						modeFormTable={table.form}
						refForm={refForm}
					/>
				),
			},
			{
				id: TableTab.Columns,
				title: t('TAB_COLUMNS'),
				content: (
					<Columns
						key={TableTab.Columns}
						data={table}
						node={node}
						systemNodeId={systemNodeId}
						editMode={editMode && mode === TableMode.TABLE}
						domains={domains ?? []}
						onChange={handleChange}
						technicalColumns={technicalColumns}
						reloadTableData={reloadTableData}
					/>
				),
			},
			{
				id: TableTab.Indexes,
				title: t('TAB_INDEXES'),
				content: (
					<Indexes
						key={TableTab.Indexes}
						data={table}
						node={node}
						onChange={handleChange}
						technicalColumns={technicalColumns}
					/>
				),
			},
			{
				id: TableTab.Constraints,
				title: t('TAB_CONSTRAINTS'),
				content: (
					<Constraints
						key={TableTab.Constraints}
						data={table}
						node={node}
						systemNodeId={systemNodeId}
						onChange={handleChange}
						technicalColumns={technicalColumns}
					/>
				),
			},
			{
				id: TableTab.PhysicalOptions,
				title: t('TAB_PHYSICAL_OPTIONS'),
				content: (
					<PhysicalOptions
						key={TableTab.PhysicalOptions}
						editMode={editMode}
						onChange={handleChange}
						onSync={handleSync}
						unsyncedFields={unsyncedFields}
						modeForm={modeForm}
					/>
				),
			},
			{
				id: TableTab.Permissions,
				title: t('PERMISSIONS'),
				content: (
					<Permissions
						key={TableTab.Permissions}
						node={node}
						onChange={handleChange}
						data={table}
						systemNodeId={systemNodeId}
					/>
				),
			},
			...(mode === TableMode.TABLE
				? [
						{
							id: TableTab.Mappings,
							title: t('TAB_MAPPINGS'),
							content: <Mappings node={node} key="mappings" />,
						},
					]
				: []),
			{
				id: TableTab.Preview,
				title: t('TAB_PREVIEW'),
				content: (
					<Preview
						key={TableTab.Preview}
						node={node}
						showObjectTypes
						objectTypes={objectTypes}
					/>
				),
			},
			{
				id: TableTab.Deployments,
				title: t('TAB_DEPLOYMENTS'),
				content: (
					<Deployments
						node={node}
						key={TableTab.Deployments}
						editMode={editMode}
					/>
				),
			},
			{
				id: TableTab.Validation,
				title: t('TAB_VALIDATION'),
				content: <Validation key={TableTab.Validation} node={node} />,
			},
			{
				id: TableTab.Dependencies,
				title: t('DEPENDENCIES'),
				content: (
					<Dependencies
						key={TableTab.Dependencies}
						node={node}
						editMode={editMode}
					/>
				),
			},
			{
				id: TableTab.Versions,
				title: t('VERSIONS'),
				content: <VersionsTab />,
			},
		]
	}, [
		table,
		modeForm,
		t,
		node,
		systemNodeId,
		editMode,
		mode,
		handleChange,
		handleSync,
		unsyncedFields,
		domains,
		technicalColumns,
		reloadTableData,
		objectTypes,
		refForm,
	])

	return (
		<Tabs tabs={tabs} onChange={handleTabChange} selectedTabId={table.tab} />
	)
}
