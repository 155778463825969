import { useAppStore } from '@/hooks'
import { useMemo } from 'react'
import { TableColumnProps, PropertiesErrorProps } from '@/types'
import { ItemPropertyType } from '@/enums'
import { CUSTOM_ATTRIBUTE_FORM_FIELD_PREFIX } from '@/constants'

export const valueGet = <T>(prop: TableColumnProps<T>, item: T) => {
	if (prop.value) {
		return prop.value(item)
	}

	if (prop.field) {
		return (item as any)?.[prop.field]
	}

	return undefined
}

export const valueOrLabelGet = <T>(prop: TableColumnProps<T>, item: T) => {
	if (prop.type === ItemPropertyType.OPTION) {
		let options: any[] = []

		if (typeof prop.options === 'function') {
			options = prop.options(item)
		} else {
			options = prop.options ?? []
		}

		const value = valueGet(prop, item)

		const option = options.find(
			(option) => option[prop.optionValue ?? ''] === value,
		)

		return option?.[prop.optionLabel ?? ''] ?? undefined
	}

	return valueGet(prop, item)
}

export const errorGet = <T>(
	properties: TableColumnProps<T>[],
	prop: TableColumnProps<T>,
	item: T | undefined,
	errors?: PropertiesErrorProps,
) => {
	// TODO: Maybe add id property as mandatory to item type to fix any?
	return prop.field && errors?.[(item as any)?.id]?.[prop.field]?.message
}

export const usePropertiesPanel = (nodeId?: number, tabKey?: string) => {
	const openedTabs = useAppStore((state) => state.tab.openedTabs)

	const tab = useMemo(
		() => openedTabs.find((t) => t.nodeId === nodeId),
		[openedTabs, nodeId],
	)

	const propertiesPanel = useMemo(
		() => (tabKey ? tab?.propertiesShown[tabKey] : undefined),
		[tab, tabKey],
	)

	return {
		propertiesHidden: !propertiesPanel?.shown,
		propertiesWidth: propertiesPanel?.width,
	}
}

export const parsePanelPropCA = (customAttributesAll: any, formValues: any) => {
	return Object.keys(formValues)
		.filter((key) => key.includes(CUSTOM_ATTRIBUTE_FORM_FIELD_PREFIX))
		.map((key) => {
			const [_, id] = key.split('.')
			const customAttributeId = parseFloat(id)

			const attribute = customAttributesAll.data?.find(
				(customAttribute: any) => customAttribute.id === customAttributeId,
			)

			return {
				id: customAttributeId,
				name: attribute?.name,
				value: formValues[key],
				version: attribute?.version ?? 1,
			}
		})
}
