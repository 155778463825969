import { StructureDto } from '@/endpoints/models'

export interface DetailTabContextState {
	/** Edit mode is disabled during history view */
	editMode: boolean
	/** Is edit mode selected (history view independent) */
	editModeProp: boolean
	envId?: number
	node: StructureDto
	selectedTab: string
	systemNodeId: number
	version?: number
	versionActive: boolean
	versionMax?: number
}

export interface DetailTabContextType {
	dispatch: React.Dispatch<Actions>
	state: DetailTabContextState
}

export enum ActionTypeEnum {
	DETAIL_TAB_UPDATE,
}

type ActionDetailTabUpdate = {
	type: ActionTypeEnum.DETAIL_TAB_UPDATE
	update: Partial<DetailTabContextState>
}

export type Actions = ActionDetailTabUpdate
