import { faPlus } from '@fortawesome/free-solid-svg-icons'

import { Button, DialogWrapper } from '@/components'
import { StructureObjectDto } from '@/endpoints/models'
import { useAppContext } from '@/hooks'

import {
	AddModal,
	NodeButtons,
	PermissionsButton,
	Title,
	TitleLeftContent,
} from '../../components'
import { useDetailTabContext } from '../../components/DetailTab'
import { WorkflowFolderTabs } from './components/Tabs'

export const WorkflowFolderDetail = () => {
	const { t } = useAppContext()

	const {
		state: { node, selectedTab, editMode },
	} = useDetailTabContext()

	const dialog = (opened: boolean, onClose: () => void) =>
		opened && (
			<AddModal
				node={node}
				type={StructureObjectDto.TypeEnum.WORKFLOW}
				onClose={onClose}
			/>
		)

	return (
		<>
			<Title
				type={node.type as StructureObjectDto.TypeEnum}
				title={node.name}
				leftContent={<TitleLeftContent node={node} />}
				rightContent={
					<NodeButtons
						buttons={
							<DialogWrapper dialog={dialog}>
								{(onClick) => (
									<Button
										disabled={!node.canEdit}
										icon={faPlus}
										onClick={onClick}
										schema="success"
										coloredIcon={true}
									>
										{t('ADD_WORKFLOW')}
									</Button>
								)}
							</DialogWrapper>
						}
						moreButtons={<PermissionsButton node={node} />}
					/>
				}
			/>
			<WorkflowFolderTabs
				node={node}
				selectedTab={selectedTab}
				editMode={editMode}
			/>
		</>
	)
}
