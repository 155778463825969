import { Dispatch } from 'react'

import {
	openOrCloseDeleteModal,
	RootState,
	setNodeIdToRemove,
	setNodeToDelete,
} from '@/store'

export const deleteNodeThunk =
	(removedNodeId: number) =>
	(dispatch: Dispatch<any>, getState: () => RootState) => {
		const state = getState()
		const nodeToDelete = state.node.nodes[removedNodeId]

		if (!nodeToDelete) {
			throw new Error('Delete node thunk: node to delete not found')
		}
		dispatch(openOrCloseDeleteModal(true))
		dispatch(setNodeIdToRemove(removedNodeId))
		dispatch(setNodeToDelete(nodeToDelete))
	}
