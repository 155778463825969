import has from 'lodash/has'

import { TranslationHelper } from '@/context/Locale'
import { HistoryTableData } from '@/endpoints/schemas'
import { TableMode } from '@/enums'
import {
	HistoryTableFormData,
	OpenedTableData,
	ReferenceTableFormData,
	TableDataForm,
} from '@/store/modules/table/types'
import { NamingDtoTypeEnum, SqlTypeEnum } from '@/types'

import {
	TABLE_MODE_DISABLED_FIELDS,
	TABLE_MODE_DISABLED_FIELDS_GENERATED,
} from './constants'

export const listValue = <T extends object, K extends keyof T>(
	list: T[],
	valueKey: K,
	labelKey: keyof T,
	value: T[K] | undefined | null,
	unknown = '',
	getItem = false,
) => {
	const item = list.find((item) => item[valueKey] == value)

	return item ? (getItem ? item : item[labelKey]) : unknown
}

export const listValues = <T extends object, K extends keyof T>(
	list: T[],
	valueKey: K,
	labelKey: keyof T,
	values: T[K][] | undefined | null,
) =>
	values
		? values.map((item) => listValue(list, valueKey, labelKey, item)).join(', ')
		: ''

export const getUnsyncedFields = (mode: TableMode, form?: TableDataForm) => {
	switch (mode) {
		case TableMode.HISTORY:
			return form?.HISTORY?.nonSyncFields ?? []

		case TableMode.REFERENCE:
			return form?.referenceTable?.nonSyncFields ?? []

		default:
			return []
	}
}

/** Adds unsynced fields when they are missing from original object to properly overwrite all table.form values */
const getOverwritedFields = (
	initForm?: ReferenceTableFormData | HistoryTableFormData,
	unsyncedFields?: (keyof HistoryTableData)[],
) =>
	unsyncedFields?.reduce((fields, field) => {
		if (has(fields, field)) {
			return fields
		}

		return {
			...fields,
			[field]: undefined,
		}
	}, initForm) ?? {}

/**
 * Get form data for currently selected table mode
 */
export const getModeForm = (
	mode: TableMode,
	form?: TableDataForm,
	unsyncedFields?: (keyof HistoryTableData)[],
) => {
	if (form) {
		switch (mode) {
			case TableMode.HISTORY:
				const historyForm = getOverwritedFields(
					form.historyTable,
					unsyncedFields,
				)

				return {
					...form,
					...historyForm,
				}
			case TableMode.REFERENCE:
				const referenceForm = getOverwritedFields(
					form.referenceTable,
					unsyncedFields,
				)

				return {
					...form,
					...referenceForm,
				}
		}
	}

	return form
}

export const getIsFieldDisabled = (
	fieldName: keyof TableDataForm,
	mode: TableMode,
) => TABLE_MODE_DISABLED_FIELDS.includes(fieldName) && mode !== TableMode.TABLE

export const getIsFieldDisabledGenerated = (
	fieldName: keyof TableDataForm,
	mode: TableMode,
	canGenerateNaming?: boolean,
) =>
	TABLE_MODE_DISABLED_FIELDS_GENERATED.includes(fieldName) &&
	mode !== TableMode.TABLE &&
	canGenerateNaming

export const getSqlType = (tableMode: TableMode) => {
	switch (tableMode) {
		case TableMode.TABLE:
			return SqlTypeEnum.TABLE
		case TableMode.HISTORY:
			return SqlTypeEnum.TABLE_HISTORY
		case TableMode.REFERENCE:
			return SqlTypeEnum.TABLE_REFERENCE
	}
}

export const getNamingTypeCode = (tableMode: TableMode) => {
	switch (tableMode) {
		case TableMode.TABLE:
			return NamingDtoTypeEnum.TABLE_CODE
		case TableMode.HISTORY:
			return NamingDtoTypeEnum.HISTORICAL_TABLE_CODE
		case TableMode.REFERENCE:
			return NamingDtoTypeEnum.REFERENCE_TABLE_CODE
	}
}

export const getTableName = (mode: TableMode, table: OpenedTableData) => {
	if (mode === TableMode.HISTORY && table.form.historyTable) {
		return table.form.historyTable.name
	}

	if (mode === TableMode.REFERENCE && table.form.referenceTable) {
		return table.form.referenceTable.name
	}

	return table.form.name
}

export const getTableMode = (
	mode: TableMode,
	hasHistoryTable: boolean,
	t: TranslationHelper,
) => {
	if (mode === TableMode.HISTORY || mode === TableMode.REFERENCE) {
		return t('SWITCH_TO_ORIGINAL_TABLE')
	}

	if (hasHistoryTable) {
		return t('SWITCH_TO_HISTORY_TABLE')
	}

	return t('SWITCH_TO_TRANSLATION_TABLE')
}
