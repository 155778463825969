import {
	faCircle,
	faProjectDiagram,
	faRocket,
	faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { ContextMenuWrapper } from '@/components/ContextMenu/ContextMenuWrapper'
import { TabName } from '@/enums'
import { useAppStore } from '@/hooks'
import { EntityTypeIcon } from '@/pages/User/pages/Home/components/EntityTypeIcon'
import { CustomTabHead } from '@/pages/User/pages/Home/components/TabsDetailView/components/CustomTabHead'
import {
	EditIcon,
	TabHead,
	TabHeadClose,
	TabHeadText,
} from '@/pages/User/pages/Home/components/TabsDetailView/styles'
import { getDpDetailEditMode } from '@/pages/User/pages/Home/components/TabsDetailView/utils'

import { TabHeadingProps } from '../types'

const handleMouseDown = (event: React.MouseEvent) => {
	if (event.nativeEvent.detail > 1) {
		event.stopPropagation()
		event.preventDefault()
	}
}

export const TabHeading = ({
	contextMenu,
	node,
	isSelected,
	isTemporary,
	isVertical,
	handleClick,
	handleDblClick,
	handleMouseUp,
	tab,
	handleCloseClick,
}: TabHeadingProps) => {
	const openedTabs = useAppStore((state) => state.tab.openedTabs)
	const selectedTab = useAppStore((state) => state.tab.selectedTab)

	const currentTab =
		selectedTab !== undefined ? openedTabs[selectedTab] : undefined

	const commonProps = {
		isSelected,
		isTemporary,
		isVertical,
		handleClick,
		handleDblClick,
		handleMouseDown,
		handleMouseUp,
		handleCloseClick,
	}

	if (tab.customTabProps?.customTabName === TabName.DeploymentPackage) {
		return (
			<CustomTabHead
				editMode={getDpDetailEditMode(openedTabs, currentTab)}
				icon={faRocket}
				name={tab.customTabProps.name}
				{...commonProps}
			/>
		)
	}

	if (tab.customTabProps?.customTabName === TabName.Diagram) {
		return (
			<CustomTabHead
				editMode={getDpDetailEditMode(openedTabs, currentTab)}
				icon={faProjectDiagram}
				name={tab.customTabProps.name}
				{...commonProps}
			/>
		)
	}

	if (node) {
		return (
			<ContextMenuWrapper menu={contextMenu}>
				<TabHead
					role="tab"
					aria-selected={isSelected}
					$isSelected={isSelected}
					$isTemporary={isTemporary}
					$isVertical={isVertical}
					key={`${node.id}`}
					onClick={handleClick}
					onDoubleClick={handleDblClick}
					onMouseDown={handleMouseDown}
					onMouseUp={handleMouseUp}
					title={node.name}
				>
					<EntityTypeIcon type={node.type} />
					<TabHeadText>
						{node.name || '(unnamed)'}
						{tab.editMode && <EditIcon icon={faCircle} />}
					</TabHeadText>
					<TabHeadClose role="button" onClick={handleCloseClick}>
						<FontAwesomeIcon icon={faTimes} color="#999" />
					</TabHeadClose>
				</TabHead>
			</ContextMenuWrapper>
		)
	}

	return (
		<TabHead
			role="tab"
			$isSelected={isSelected}
			$isTemporary={isTemporary}
			$isVertical={isVertical}
		>
			...
		</TabHead>
	)
}
